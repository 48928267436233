const localStorageKeys = {
  TOKEN: 'TOKEN',
  USER_INFO: 'USER_INFO',
};

const test = 'test';

export {
  localStorageKeys,
  test,
};
