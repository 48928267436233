import { get } from './utils'

const apiLogin = (userName, data) => get(`/login/${userName}`, data)

const apiGetSignature = (data) => get('/myServer/api/v1/getSignature', data)

export {
  apiLogin,
  apiGetSignature
}
