<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <Button @click="openDoc">to OpenDoc</Button>
    <div @click="BA_testClick">埋点</div>
    <Button type="primary" @click="openSchema">打开网页</Button>
    <Button @click="getUserInfo">获取用户信息</Button>
    <Button @click="getCode">getCode</Button>
    <Button @click="showToast">showToast</Button>
    <Button @click="showModal">showModal</Button>
    <Button @click="getLocation">getLocation</Button>
    <Button @click="openLocation">openLocation</Button>
    <Button @click="test">test</Button>
    <Button @click="auth">鉴权</Button>
    <Button @click="authen">authen</Button>
    <Button @click="getSystemInfo">获取设备信息</Button>
    <Button @click="requestAuthCode">requestAuthCode</Button>
    <Tabbar />
  </div>
</template>

<script>
// @ is an alias to /src
import test from '@/common/test'
import sha1 from 'sha1'
import axios from 'axios'
import MD5 from 'crypto-js/md5'
import { apiLogin } from '@/apis/common'
import { localStorageKeys } from '@/common/constants'
import { apiCheckUser, apiGetMahjongList } from '@/apis/mahjong'
import Tabbar from '@/components/common/Tabbar';
import { apiGetSignature } from '@/apis/common'

export default {
  name: 'Home',
  components: {
    Tabbar,
  },
  data () {
    return {
      appId: 'cli_a2c4c330217ad00d',
      code: ''
    }
  },
  async mounted() {

    // this.auth()
    let _this = this
    window.h5sdk.ready(function() {
      console.log('ready-----------------', tt.requestAuthCode)
      // tt.requestAuthCode({
      //   appId: 'cli_a2c4c330217ad00d',
      //   success: (info) => {
      //     // _this.code = info.code
      //     alert(info.code)
      //     console.log('code码：：：++++++++++++++++++++++++', info.code)
      //   },
      //   fail: (error) => {
      //     console.error('error----------', error)
      //   }
      // })
      // _this.getCode()
    })

    const data = await apiLogin('guang', { password: MD5('427815').toString(), uuid: '123456' })
    localStorage.setItem(localStorageKeys.TOKEN, `Bearer ${data.data.token}`)
    // console.log('?????????????????', data)
    apiCheckUser('mangoguang', { name: 'guang' })
    localStorage.setItem(localStorageKeys.TOKEN, `Bearer ${data.data.token}1`)
    const res = await apiGetMahjongList({ limit1: 100, page1: 1 }, { isLoading: true })
    console.log('返回信息', res)
  },
  methods: {
    openDoc () {
      this.$router.push({
        path: '/openDoc'
      })
    },
    async test () {
      // console.log(await getSignature())
    },
    getCode () {
      let _this = this
      console.log('getcode-------------', _this.appId)
      // tt.requestAuthCode({
      //   appId: _this.appId,
      //   success: (info) => {
      //     // _this.code = info.code
      //     console.log('code码：：：++++++++++++++++++++++++', info.code)
      //   },
      //   fail: (error) => {
      //     console.error('error----------', error)
      //   }
      // })
    },
    BA_testClick() {
      console.log('success!!!', this.$ba);
      this.$ba.trackEvent('test', '新增', '收入', 100);
    },
    login () {
      apiLogin()
    },
    openSchema () {
      window.h5sdk.biz.util.openLink({
        url: "https://open.feishu.cn",
        title: "test"
      })
    },
    getUserInfo () {
      tt.getUserInfo({
        withCredentials: true,
        success(res) {
          alert(JSON.stringify(res.userInfo))
          console.log('userInfo::::', res);
        },
        fail(res) {
          console.log(`getUserInfo fail: ${JSON.stringify(res)}`);
        }
      });
    },
    async auth () {
      const { nonceStr, timeStamp, signature } = await apiGetSignature({ appName: 'guangH5' })
      console.log('auth----------------------', { nonceStr, timeStamp, signature })
      // window.h5sdk.ready(function() {
        
      // })
      window.h5sdk.config({
        appId: 'cli_a2c4c330217ad00d',         // 必填，应用ID
        timestamp: timeStamp,     // 必填，生成签名的时间戳，毫秒级
        nonceStr: nonceStr,      // 必填，生成签名的随机串
        signature: signature,     // 必填，签名
        jsApiList : [
          'getUserInfo',
          'openLocation'
          // 当前开发文档中的网页应用API 均无需填写该字段
        ], 
        onSuccess: function(result) {
          alert('鉴权成功')
          // alert('success')
          // 成功回调，可以在成功之后使用 tt.xx jsapi
        },
        onFail: function(error) {
          alert(JSON.stringify(error))
          // 失败回调
        }
      });
    },
    async authen () {
      location.href = 'https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=http://127.0.0.1:8080&app_id=cli_a2c4c330217ad00d&state=123'
    },
    showToast () {
      tt.showToast({
        "title": "添加购物车成功",
        "duration": 3000,
        "icon": "success",
        "mask": false,
        success(res) {
          console.log(JSON.stringify(res));
        },
        fail(res) {
          console.log(`showToast fail: ${JSON.stringify(res)}`);
        }
      });
    },
    showModal () {
      tt.showModal({
        "title": "请求获得定位权限",
        "content": "获得你的地理位置能够更好的为你推荐本地信息",
        "confirmText": "授予权限",
        "cancelText": "取消",
        "showCancel": true,
        success(res) {
          console.log(JSON.stringify(res));
        },
        fail(res) {
          console.log(`showModal fail: ${JSON.stringify(res)}`);
        }
      });
    },
    getLocation () {
      tt.getLocation({
          "type": "gcj02",
          "timeout": 5,
          "cacheTimeout": 30,
          "accuracy": "high",
          success(res) {
            console.log('location-----------', JSON.stringify(res));
            alert(JSON.stringify(res))
          },
          fail(res) {
            console.log(`getLocation fail: ${JSON.stringify(res)}`);
          }
      });
    },
    openLocation () {
      tt.openLocation({
        "latitude": 30.492121,
        "longitude": 114.410324,
        "scale": 12,
        "name": "保利国际中心",
        "address": "武汉市洪山区关山大道",
        success(res) {
          console.log('openLocation-------------', JSON.stringify(res));
          alert(JSON.stringify(res))
        },
        fail(res) {
          console.log(`openLocation fail: ${JSON.stringify(res)}`);
        }
      })
    },
    getSystemInfo () {
      tt.getSystemInfo({
        success(res) {
          alert('设备信息:' + JSON.stringify(res));
          console.log('设备信息', JSON.stringify(res));
        },
        fail(res) {
          console.log(`getSystemInfo fail: ${JSON.stringify(res)}`);
        }
      });
    },
    requestAuthCode () {
      console.log('this.appId--------', this.appId)
      tt.requestAuthCode({
        appId: this.appId,
        success: (info) => {
          alert('requestAuthCode:' + JSON.stringify(info));
          console.log('requestAuthCode====================', info.code)
        },
        fail: (error) => {
          alert('requestAuthCode fail:' + JSON.stringify(error));
          console.error('requestAuthCode fail------', error)
        }
      })
    }
  },
};
</script>
