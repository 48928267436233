! function (e) {
  "function" == typeof define && define.amd ? define(e) : e()
}((function () {
  "use strict";
  window.__JSSDK_VERSION__ = {
    SDKVersion: "3.1.1",
    SDKCDNVersion: "1.5.15",
    CommitHash: ""
  };
  var e = "undefined" != typeof globalThis ? globalThis : "undefined" != typeof window ?
    window : "undefined" != typeof global ? global : "undefined" != typeof self ? self : {};

  function t(e) {
    return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default :
      e
  }

  function n(e, t, n) {
    return e(n = {
      path: t,
      exports: {},
      require: function (e, t) {
        return function () {
          throw new Error(
            "Dynamic requires are not currently supported by @rollup/plugin-commonjs")
        }(null == t && n.path)
      }
    }, n.exports), n.exports
  }
  var i, r, o, a = t(n((function (e) {
      function t(e, t, n, i, r, o, a) {
        try {
          var s = e[o](a),
            c = s.value
        } catch (e) {
          return void n(e)
        }
        s.done ? t(c) : Promise.resolve(c).then(i, r)
      }
      e.exports = function (e) {
        return function () {
          var n = this,
            i = arguments;
          return new Promise((function (r, o) {
            var a = e.apply(n, i);

            function s(e) {
              t(a, r, o, s, c, "next", e)
            }

            function c(e) {
              t(a, r, o, s, c, "throw", e)
            }
            s(void 0)
          }))
        }
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    }))),
    s = n((function (e) {
      var t = function (e) {
        var t, n = Object.prototype,
          i = n.hasOwnProperty,
          r = "function" == typeof Symbol ? Symbol : {},
          o = r.iterator || "@@iterator",
          a = r.asyncIterator || "@@asyncIterator",
          s = r.toStringTag || "@@toStringTag";

        function c(e, t, n) {
          return Object.defineProperty(e, t, {
            value: n,
            enumerable: !0,
            configurable: !0,
            writable: !0
          }), e[t]
        }
        try {
          c({}, "")
        } catch (e) {
          c = function (e, t, n) {
            return e[t] = n
          }
        }

        function u(e, t, n, i) {
          var r = t && t.prototype instanceof g ? t : g,
            o = Object.create(r.prototype),
            a = new I(i || []);
          return o._invoke = function (e, t, n) {
            var i = f;
            return function (r, o) {
              if (i === p) throw new Error("Generator is already running");
              if (i === h) {
                if ("throw" === r) throw o;
                return x()
              }
              for (n.method = r, n.arg = o;;) {
                var a = n.delegate;
                if (a) {
                  var s = E(a, n);
                  if (s) {
                    if (s === v) continue;
                    return s
                  }
                }
                if ("next" === n.method) n.sent = n._sent = n.arg;
                else if ("throw" === n.method) {
                  if (i === f) throw i = h, n.arg;
                  n.dispatchException(n.arg)
                } else "return" === n.method && n.abrupt("return", n.arg);
                i = p;
                var c = l(e, t, n);
                if ("normal" === c.type) {
                  if (i = n.done ? h : d, c.arg === v) continue;
                  return {
                    value: c.arg,
                    done: n.done
                  }
                }
                "throw" === c.type && (i = h, n.method = "throw", n.arg = c.arg)
              }
            }
          }(e, n, a), o
        }

        function l(e, t, n) {
          try {
            return {
              type: "normal",
              arg: e.call(t, n)
            }
          } catch (e) {
            return {
              type: "throw",
              arg: e
            }
          }
        }
        e.wrap = u;
        var f = "suspendedStart",
          d = "suspendedYield",
          p = "executing",
          h = "completed",
          v = {};

        function g() {}

        function m() {}

        function _() {}
        var y = {};
        y[o] = function () {
          return this
        };
        var w = Object.getPrototypeOf,
          b = w && w(w(O([])));
        b && b !== n && i.call(b, o) && (y = b);
        var k = _.prototype = g.prototype = Object.create(y);

        function S(e) {
          ["next", "throw", "return"].forEach((function (t) {
            c(e, t, (function (e) {
              return this._invoke(t, e)
            }))
          }))
        }

        function C(e, t) {
          function n(r, o, a, s) {
            var c = l(e[r], e, o);
            if ("throw" !== c.type) {
              var u = c.arg,
                f = u.value;
              return f && "object" == typeof f && i.call(f, "__await") ? t.resolve(f
                .__await).then((function (e) {
                n("next", e, a, s)
              }), (function (e) {
                n("throw", e, a, s)
              })) : t.resolve(f).then((function (e) {
                u.value = e, a(u)
              }), (function (e) {
                return n("throw", e, a, s)
              }))
            }
            s(c.arg)
          }
          var r;
          this._invoke = function (e, i) {
            function o() {
              return new t((function (t, r) {
                n(e, i, t, r)
              }))
            }
            return r = r ? r.then(o, o) : o()
          }
        }

        function E(e, n) {
          var i = e.iterator[n.method];
          if (i === t) {
            if (n.delegate = null, "throw" === n.method) {
              if (e.iterator.return && (n.method = "return", n.arg = t, E(e, n),
                  "throw" === n.method)) return v;
              n.method = "throw", n.arg = new TypeError(
                "The iterator does not provide a 'throw' method")
            }
            return v
          }
          var r = l(i, e.iterator, n.arg);
          if ("throw" === r.type) return n.method = "throw", n.arg = r.arg, n.delegate =
            null, v;
          var o = r.arg;
          return o ? o.done ? (n[e.resultName] = o.value, n.next = e.nextLoc, "return" !==
            n.method && (n.method = "next", n.arg = t), n.delegate = null, v) : o : (n
            .method = "throw", n.arg = new TypeError(
            "iterator result is not an object"), n.delegate = null, v)
        }

        function z(e) {
          var t = {
            tryLoc: e[0]
          };
          1 in e && (t.catchLoc = e[1]), 2 in e && (t.finallyLoc = e[2], t.afterLoc = e[
            3]), this.tryEntries.push(t)
        }

        function T(e) {
          var t = e.completion || {};
          t.type = "normal", delete t.arg, e.completion = t
        }

        function I(e) {
          this.tryEntries = [{
            tryLoc: "root"
          }], e.forEach(z, this), this.reset(!0)
        }

        function O(e) {
          if (e) {
            var n = e[o];
            if (n) return n.call(e);
            if ("function" == typeof e.next) return e;
            if (!isNaN(e.length)) {
              var r = -1,
                a = function n() {
                  for (; ++r < e.length;)
                    if (i.call(e, r)) return n.value = e[r], n.done = !1, n;
                  return n.value = t, n.done = !0, n
                };
              return a.next = a
            }
          }
          return {
            next: x
          }
        }

        function x() {
          return {
            value: t,
            done: !0
          }
        }
        return m.prototype = k.constructor = _, _.constructor = m, m.displayName = c(_, s,
          "GeneratorFunction"), e.isGeneratorFunction = function (e) {
          var t = "function" == typeof e && e.constructor;
          return !!t && (t === m || "GeneratorFunction" === (t.displayName || t.name))
        }, e.mark = function (e) {
          return Object.setPrototypeOf ? Object.setPrototypeOf(e, _) : (e.__proto__ = _,
            c(e, s, "GeneratorFunction")), e.prototype = Object.create(k), e
        }, e.awrap = function (e) {
          return {
            __await: e
          }
        }, S(C.prototype), C.prototype[a] = function () {
          return this
        }, e.AsyncIterator = C, e.async = function (t, n, i, r, o) {
          void 0 === o && (o = Promise);
          var a = new C(u(t, n, i, r), o);
          return e.isGeneratorFunction(n) ? a : a.next().then((function (e) {
            return e.done ? e.value : a.next()
          }))
        }, S(k), c(k, s, "Generator"), k[o] = function () {
          return this
        }, k.toString = function () {
          return "[object Generator]"
        }, e.keys = function (e) {
          var t = [];
          for (var n in e) t.push(n);
          return t.reverse(),
            function n() {
              for (; t.length;) {
                var i = t.pop();
                if (i in e) return n.value = i, n.done = !1, n
              }
              return n.done = !0, n
            }
        }, e.values = O, I.prototype = {
          constructor: I,
          reset: function (e) {
            if (this.prev = 0, this.next = 0, this.sent = this._sent = t, this
              .done = !1, this.delegate = null, this.method = "next", this.arg = t,
              this.tryEntries.forEach(T), !e)
              for (var n in this) "t" === n.charAt(0) && i.call(this, n) && !isNaN(+n
                .slice(1)) && (this[n] = t)
          },
          stop: function () {
            this.done = !0;
            var e = this.tryEntries[0].completion;
            if ("throw" === e.type) throw e.arg;
            return this.rval
          },
          dispatchException: function (e) {
            if (this.done) throw e;
            var n = this;

            function r(i, r) {
              return s.type = "throw", s.arg = e, n.next = i, r && (n.method = "next",
                n.arg = t), !!r
            }
            for (var o = this.tryEntries.length - 1; o >= 0; --o) {
              var a = this.tryEntries[o],
                s = a.completion;
              if ("root" === a.tryLoc) return r("end");
              if (a.tryLoc <= this.prev) {
                var c = i.call(a, "catchLoc"),
                  u = i.call(a, "finallyLoc");
                if (c && u) {
                  if (this.prev < a.catchLoc) return r(a.catchLoc, !0);
                  if (this.prev < a.finallyLoc) return r(a.finallyLoc)
                } else if (c) {
                  if (this.prev < a.catchLoc) return r(a.catchLoc, !0)
                } else {
                  if (!u) throw new Error("try statement without catch or finally");
                  if (this.prev < a.finallyLoc) return r(a.finallyLoc)
                }
              }
            }
          },
          abrupt: function (e, t) {
            for (var n = this.tryEntries.length - 1; n >= 0; --n) {
              var r = this.tryEntries[n];
              if (r.tryLoc <= this.prev && i.call(r, "finallyLoc") && this.prev < r
                .finallyLoc) {
                var o = r;
                break
              }
            }
            o && ("break" === e || "continue" === e) && o.tryLoc <= t && t <= o
              .finallyLoc && (o = null);
            var a = o ? o.completion : {};
            return a.type = e, a.arg = t, o ? (this.method = "next", this.next = o
              .finallyLoc, v) : this.complete(a)
          },
          complete: function (e, t) {
            if ("throw" === e.type) throw e.arg;
            return "break" === e.type || "continue" === e.type ? this.next = e.arg :
              "return" === e.type ? (this.rval = this.arg = e.arg, this.method =
                "return", this.next = "end") : "normal" === e.type && t && (this
                .next = t), v
          },
          finish: function (e) {
            for (var t = this.tryEntries.length - 1; t >= 0; --t) {
              var n = this.tryEntries[t];
              if (n.finallyLoc === e) return this.complete(n.completion, n.afterLoc),
                T(n), v
            }
          },
          catch: function (e) {
            for (var t = this.tryEntries.length - 1; t >= 0; --t) {
              var n = this.tryEntries[t];
              if (n.tryLoc === e) {
                var i = n.completion;
                if ("throw" === i.type) {
                  var r = i.arg;
                  T(n)
                }
                return r
              }
            }
            throw new Error("illegal catch attempt")
          },
          delegateYield: function (e, n, i) {
            return this.delegate = {
              iterator: O(e),
              resultName: n,
              nextLoc: i
            }, "next" === this.method && (this.arg = t), v
          }
        }, e
      }(e.exports);
      try {
        regeneratorRuntime = t
      } catch (e) {
        Function("r", "regeneratorRuntime = r")(t)
      }
    })),
    c = n((function (e) {
      e.exports = function (e, t) {
        (null == t || t > e.length) && (t = e.length);
        for (var n = 0, i = new Array(t); n < t; n++) i[n] = e[n];
        return i
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    u = n((function (e) {
      e.exports = function (e, t) {
        if (e) {
          if ("string" == typeof e) return c(e, t);
          var n = Object.prototype.toString.call(e).slice(8, -1);
          return "Object" === n && e.constructor && (n = e.constructor.name), "Map" ===
            n || "Set" === n ? Array.from(e) : "Arguments" === n ||
            /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? c(e, t) : void 0
        }
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    l = t(n((function (e) {
      e.exports = function (e, t) {
        var n = "undefined" != typeof Symbol && e[Symbol.iterator] || e["@@iterator"];
        if (n) return (n = n.call(e)).next.bind(n);
        if (Array.isArray(e) || (n = u(e)) || t && e && "number" == typeof e.length) {
          n && (e = n);
          var i = 0;
          return function () {
            return i >= e.length ? {
              done: !0
            } : {
              done: !1,
              value: e[i++]
            }
          }
        }
        throw new TypeError(
          "Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
          )
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    }))),
    f = t(n((function (e) {
      e.exports = function (e, t) {
        if (null == e) return {};
        var n, i, r = {},
          o = Object.keys(e);
        for (i = 0; i < o.length; i++) n = o[i], t.indexOf(n) >= 0 || (r[n] = e[n]);
        return r
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })));
  ! function (e) {
    e.userCaptureScreenObserved = "userCaptureScreenObserved", e.onWatermarkChangeObserved =
      "onWatermarkChange", e.onDownloadTaskStateChange = "onDownloadTaskStateChange", e
      .nfcFoundDevice = "nfcFoundDevice"
  }(i || (i = {})),
  function (e) {
    e.IOS = "ios", e.ANDROID = "android", e.MAC = "mac", e.WINDOWS = "windows"
  }(r || (r = {})),
  function (e) {
    e.CDN = "cdn", e.NPM = "npm", e.DEV = "dev"
  }(o || (o = {}));
  var d = function (e) {
      var t;
      if (e.includes("Lark") || e.includes("Feishu")) return null == (t = e.match(
        /(lark|feishu|lark-staging|feishu-staging|lark-prerelease|feishu-prerelease|lark-oversea)\/([\d.]+)/i
        )) ? void 0 : t[2]
    },
    p = {
      versions: function () {
        var e = navigator.userAgent,
          t = navigator.platform,
          n = /(Android|iPhone|iPad|iPod|iOS)/i.test(e),
          i = d(e),
          r = e.includes("Lark") || e.includes("Feishu"),
          o = i && m(i, "3.46.0") < 0,
          a = i && m(i, "3.46.0") >= 0 && e.includes("WebApp");
        return {
          trident: e.includes("Trident"),
          presto: e.includes("Presto"),
          webKit: e.includes("AppleWebKit"),
          gecko: e.includes("Gecko") && !e.includes("KHTML"),
          mobile: !!e.match(/AppleWebKit.*Mobile.*/) || !!e.match(/AppleWebKit/),
          ios: !!e.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
          android: e.includes("Android") || e.includes("Linux"),
          iPhone: e.includes("iPhone") || e.includes("Mac"),
          iPad: e.includes("iPad"),
          webApp: !e.includes("Safari"),
          PCFeishu: !n && r && (o || a),
          mobileFeishu: n && r,
          larkVersion: d(e),
          mac: t.includes("Mac"),
          win: t.includes("Win")
        }
      }()
    };

  function h() {
    return new Promise((function (e) {
      var t = 0;
      ! function n() {
        var i, r;
        (null == (i = window.webkit) || null == (r = i.messageHandlers) ? void 0 : r
          .invoke) || window.WebViewJavascriptBridge || t > 100 ? e(0) : (setTimeout(n,
          10 + t), t++)
      }()
    }))
  }

  function v(e) {
    return Object.prototype.toString.call(e).slice(8, -1)
  }

  function g(e, t) {
    t ? console.warn("銆怘5-JS-SDK銆�: " + e) : console.error("銆怘5-JS-SDK銆�: " + e)
  }

  function m(e, t) {
    for (var n = e.replace(/-\d*$/, "").split(".").map((function (e) {
        return +e
      })), i = t.replace(/-\d*$/, "").split(".").map((function (e) {
        return +e
      })), r = 0; r < n.length; r++) {
      if (n[r] > i[r]) return 1;
      if (n[r] < i[r]) return -1
    }
    return 0
  }

  function _(e) {
    return {
      name: (e = e || {}).name,
      code: void 0 !== e.code ? e.code : e.errorCode,
      message: e.message || e.errorMessage || e.errorMsg,
      fileName: e.fileName,
      stack: e.stack,
      lineNumber: e.lineNumber,
      columnNumber: e.columnNumber
    }
  }
  var y = "";

  function w() {
    return y
  }
  var b = function (e) {
      var t = e.versions,
        n = t.ios,
        i = t.android,
        o = t.mac,
        a = t.win;
      return n ? r.IOS : i ? r.ANDROID : o ? r.MAC : a ? r.WINDOWS : null
    },
    k = n((function (e) {
      function t(n, i) {
        return e.exports = t = Object.setPrototypeOf || function (e, t) {
          return e.__proto__ = t, e
        }, e.exports.default = e.exports, e.exports.__esModule = !0, t(n, i)
      }
      e.exports = t, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    S = t(n((function (e) {
      e.exports = function (e, t) {
        e.prototype = Object.create(t.prototype), e.prototype.constructor = e, k(e, t)
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    }))),
    C = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    E = /^(?:[A-Za-z\d+/]{4})*?(?:[A-Za-z\d+/]{2}(?:==)?|[A-Za-z\d+/]{3}=?)?$/,
    z = window,
    T = z.btoa || function (e) {
      for (var t, n, i, r, o = "", a = 0, s = (e = String(e)).length % 3; a < e.length;) {
        if ((n = e.charCodeAt(a++)) > 255 || (i = e.charCodeAt(a++)) > 255 || (r = e.charCodeAt(
            a++)) > 255) throw new TypeError(
          'Failed to execute "btoa" on "Window": The string ' + e +
          " to be encoded contains characters outside of the Latin1 range.");
        o += C.charAt((t = n << 16 | i << 8 | r) >> 18 & 63) + C.charAt(t >> 12 & 63) + C
          .charAt(t >> 6 & 63) + C.charAt(63 & t)
      }
      return s ? o.slice(0, s - 3) + "===".substring(s) : o
    },
    I = z.atob || function (e) {
      if (e = String(e).replace(/[\t\n\f\r ]+/g, ""), !E.test(e)) throw new TypeError(
        'Failed to execute "atob" on "Window": The string ' + e +
        " to be decoded is not correctly encoded.");
      var t;
      e += "==".slice(2 - (3 & e.length));
      for (var n, i, r = "", o = 0; o < e.length;) t = C.indexOf(e.charAt(o++)) << 18 | C
        .indexOf(e.charAt(o++)) << 12 | (n = C.indexOf(e.charAt(o++))) << 6 | (i = C.indexOf(e
          .charAt(o++))), r += 64 === n ? String.fromCharCode(t >> 16 & 255) : 64 === i ? String
        .fromCharCode(t >> 16 & 255, t >> 8 & 255) : String.fromCharCode(t >> 16 & 255, t >> 8 &
          255, 255 & t);
      return r
    };

  function O(e) {
    for (var t = "", n = new Uint8Array(e), i = n.byteLength, r = 0; r < i; r++) t += String
      .fromCharCode(n[r]);
    return T(t)
  }

  function x(e, t, n) {
    return void 0 === n && (n = !0), n ? {
      key: e,
      value: t
    } : {
      key: e,
      base64: O(t)
    }
  }

  function A(e) {
    if (null != e) {
      if ("base64" in e) return function (e) {
        for (var t = I(e), n = t.length, i = new Uint8Array(n), r = 0; r < n; r++) i[r] = t
          .charCodeAt(r);
        return i.buffer
      }(e.base64);
      if ("value" in e) return e.value
    }
  }

  function L(e) {
    return function (t) {
      return Object.prototype.toString.call(t).toLowerCase() === ("[object " + e + "]")
        .toLowerCase()
    }
  }
  var j, P = function (e, t) {
      if (void 0 === t && (t = !0), !L("Object")(e)) return e;
      var n = [];
      for (var i in e)
        if (Object.prototype.hasOwnProperty.call(e, i)) {
          var r = e[i];
          void 0 !== r && r instanceof ArrayBuffer && void 0 !== r.byteLength && n.push(x(i, r,
            t))
        } if (n.length > 0) {
        for (var o = 0; o < n.length; o++) {
          delete e[n[o].key]
        }
        e.__nativeBuffers__ = n
      }
      return e
    },
    D = function (e) {
      if (!L("Object")(e) || !e.__nativeBuffers__) return e;
      var t = e.__nativeBuffers__;
      delete e.__nativeBuffers__;
      for (var n, i = l(t); !(n = i()).done;) {
        var r = n.value;
        if (r) {
          var o = A(r);
          void 0 !== o && o instanceof ArrayBuffer && (e[r.key] = o)
        }
      }
      return e
    };
  ! function (e) {
    e[e.unknown = 0] = "unknown", e[e.android = 1] = "android", e[e.iOS = 2] = "iOS"
  }(j || (j = {}));
  var N = 0;
  var M, B, F, R, V, U = ["success", "fail", "cancel", "complete", "progress", "eventName",
      "isListener"
    ],
    W = function (e) {
      function t() {
        return e.apply(this, arguments) || this
      }
      return S(t, e), t.prototype.invoke = function (t, n, i) {
        var r = n.params,
          o = void 0 === r ? {} : r;
        if (n.__v2__) {
          var a = o.success,
            s = o.fail,
            c = o.cancel,
            u = o.complete,
            l = o.progress,
            d = o.eventName,
            p = o.isListener,
            h = void 0 !== p && p,
            v = f(o, U),
            g = {
              method: t,
              params: Object.assign({}, v, {
                __v2__: !0
              }),
              callbackConfig: {
                onSuccess: a,
                onFail: s,
                onProgress: l,
                onCancel: c,
                onComplete: u
              },
              eventName: d,
              isListener: h,
              isTT: !0
            };
          e.prototype.invokeNative.call(this, g)
        } else {
          var m = i.eventName,
            _ = i.keep,
            y = void 0 !== _ && _,
            w = i.onProgress,
            b = i.origialCallback;
          w && (i.onProgress = function (e) {
            e && w(e.data)
          });
          var k = {
            method: t,
            params: n,
            callbackConfig: i,
            eventName: m || t,
            isListener: y,
            isTT: !1,
            origialCallback: b
          };
          e.prototype.invokeNative.call(this, k)
        }
      }, t
    }(function () {
      function e() {
        var e = this;
        this.callbackMapObj = {}, this.callbackMap = new WeakMap, this._invokeQueue = [], this
          ._onBridgeReady = function () {
            var t = e.callbackMapObj;
            window.LarkWebViewJavaScriptBridge = {
              nativeCallBack: function (e) {
                var n;
                null == (n = t[e.callbackID]) || n[0](e)
              }
            }, e._invokeQueue.forEach((function (e) {
              e()
            }))
          }, this._invokeNative = function (t) {
            var n = t.method,
              i = t.params,
              r = t.callbackConfig,
              o = t.eventName,
              a = t.isListener,
              s = t.isTT,
              c = t.origialCallback,
              u = r.onSuccess,
              l = r.onFail,
              f = r.onProgress,
              d = r.onCancel,
              p = r.onComplete,
              h = a ? o : "" + N++,
              v = function (t) {
                var n = t.callbackType,
                  i = t.data;
                if (i && "string" == typeof i) try {
                  i = JSON.parse(i)
                } catch (e) {}
                i = D(i), "cancel" === n ? (null == d || d(i), null == p || p(i)) :
                  "failure" === n ? (null == l || l(i), null == p || p(i)) : "continued" ===
                  n ? null == f || f(i) : (null == u || u(i), null == p || p(i)), a ||
                  "continued" === n || delete e.callbackMapObj[h]
              };
            if (a && e.callbackMapObj[h] ? e.callbackMapObj[h].push(v) : e.callbackMapObj[
              h] = [v], a && (f || c)) {
              var g = c || f;
              e.callbackMap.set(g, e.callbackMapObj[h])
            }
            var m = {
              apiName: n,
              data: P(i, !1),
              callbackID: h
            };
            if (!a || !s) {
              var _ = e._getSystemType();
              if (_ === j.android) try {
                window.Lark_Bridge.invokeNative(JSON.stringify(m) || "")
              } catch (e) {}
              _ === j.iOS && window.webkit.messageHandlers.invokeNative.postMessage(m)
            }
          }, window.addEventListener("WebViewJSBridgeReady", this._onBridgeReady.bind(this), !
            1)
      }
      var t = e.prototype;
      return t._getSystemType = function () {
        var e = navigator.userAgent;
        return /android/i.test(e) ? j.android : /iPad|iPhone|iPod/.test(e) ? j.iOS : j
          .unknown
      }, t._stashTask = function (e) {
        this._invokeQueue.push(e)
      }, t.invokeNative = function (e) {
        var t = this;
        this._isNativeReady() ? this._invokeNative(e) : this._stashTask((function () {
          return t._invokeNative(e)
        }))
      }, t.removeListener = function (e, t) {
        var n = this.callbackMapObj[e];
        if (n && Array.isArray(n) && !(n.length <= 0))
          if (t)
            for (var i = this.callbackMap.get(t), r = 0; r < i.length; r++) {
              var o = i[r],
                a = n.indexOf(o);
              if (!~a) return;
              n.splice(a, 1), 0 === n.length && delete this.callbackMapObj[e]
            } else delete this.callbackMapObj[e]
      }, t._isNativeReady = function () {
        var e, t, n, i, r, o, a = this._getSystemType(),
          s = !1;
        a === j.android && (s = !(null == (e = window) || null == (t = e.Lark_Bridge) || !t
          .invokeNative));
        a === j.iOS && (s = !(null == (n = window) || null == (i = n.webkit) || null == (r =
          i.messageHandlers) || null == (o = r.invokeNative) || !o.postMessage));
        return s
      }, e
    }()),
    q = t(n((function (e) {
      function t(e, t) {
        for (var n = 0; n < t.length; n++) {
          var i = t[n];
          i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i
            .writable = !0), Object.defineProperty(e, i.key, i)
        }
      }
      e.exports = function (e, n, i) {
        return n && t(e.prototype, n), i && t(e, i), e
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })));
  ! function (e) {
    e[e.trace = 1] = "trace", e[e.normal = 2] = "normal", e[e.warn = 3] = "warn", e[e.error =
      4] = "error", e[e.fatal = 5] = "fatal"
  }(M || (M = {})),
  function (e) {
    e.traceId = "trace_id", e.errorCode = "error_code", e.errorMsg = "error_msg", e.time =
      "time", e.duration = "duration", e.monitorLevel = "monitor_level", e.monitorDomain =
      "monitor_domain", e.monitorCode = "monitor_code", e.monitorID = "monitor_id", e
      .monitorMessage = "monitor_message", e.monitorTags = "monitor_tags", e.monitorFile =
      "monitor_file", e.monitorFunction = "monitor_function", e.monitorLine = "monitor_line", e
      .monitorColumn = "monitor_column", e.monitorStack = "monitor_stack", e.resultType =
      "result_type"
  }(B || (B = {})),
  function (e) {
    e.BATCH = "BATCH"
  }(F || (F = {})),
  function (e) {
    e.success = "success", e.fail = "fail", e.cancel = "cancel", e.timeout = "timeout"
  }(R || (R = {})),
  function (e) {
    e[e.debug = 1] = "debug", e[e.info = 2] = "info", e[e.warn = 3] = "warn", e[e.error = 4] =
      "error", e[e.fatal = 5] = "fatal"
  }(V || (V = {}));
  var J, H, K = ",",
    Q = "op_monitor_event",
    G = "op_monitor",
    $ = "event_name";
  ! function (e) {
    e.warn = "warn", e.error = "error"
  }(J || (J = {})),
  function (e) {
    e.sampleRate = "sample_rate", e.traceSampleRate = "trace_sample_rate", e.normalSampleRate =
      "normal_sample_rate", e.warnSampleRate = "warn_sample_rate", e.errorSampleRate =
      "error_sample_rate", e.fatalSampleRate = "fatal_sample_rate", e.rules = "rules", e
      .priority = "priority", e.filters = "filters", e.key = "key", e.values = "values"
  }(H || (H = {}));
  var X = function () {};

  function Y(e) {
    return void 0 === e
  }
  var Z, ee = {
    string: function (e) {
      return "string" == typeof e || "String" === function (e) {
        return Object.prototype.toString.call(e).slice(8, -1)
      }(e)
    },
    undefined: Y,
    void: function (e) {
      return Y(e) || null === e
    },
    object: function (e) {
      return null !== e && "object" == typeof e
    },
    number: function (e) {
      return "number" == typeof e && !Number.isNaN(e)
    },
    function: function (e) {
      return "function" == typeof e
    }
  };

  function te(e, t) {
    return Object.entries(e).filter((function (e) {
      var n = e[0],
        i = e[1];
      return !t(i, n)
    })).reduce((function (e, t) {
      var n, i = t[0],
        r = t[1];
      return Object.assign(e, ((n = {})[i] = r, n))
    }), {})
  }

  function ne(e, t, n) {
    Object.defineProperty(e, t, {
      get: function () {
        return n
      },
      set: function () {}
    })
  }

  function ie() {
    return Z || (Z = function () {
      if ("undefined" != typeof self) return self;
      if ("undefined" != typeof window) return window;
      if ("undefined" != typeof global) return global;
      throw new Error("unable to locate global object")
    }())
  }
  var re = function () {
      function e(e) {
        var t = e.priority,
          n = e.sampleRate,
          i = e.filters;
        this.priority = 0, this.filters = [], this.priority = t, this.sampleRate = n, this
          .filters = i
      }
      return e.prototype.match = function (e) {
        return this.filters.every((function (t) {
          return t.match(e)
        }))
      }, e
    }(),
    oe = function () {
      function e(e) {
        var t = e.key,
          n = e.values;
        this.key = "", this.values = [], this.key = t, this.values = n
      }
      return e.prototype.match = function (e) {
        var t = "" + e[this.key];
        return this.values.includes(t)
      }, e
    }(),
    ae = function () {
      function e() {
        this._defaultSampleRate = 1, this._traceSampleRate = 0, this._normalSampleRate = 1, this
          ._warnSampleRate = 1, this._errorSampleRate = 1, this._fatalSampleRate = 1, this
          ._orderedRules = []
      }
      var t = e.prototype;
      return t.buildConfig = function (e) {
        void 0 === e && (e = {});
        var t = e,
          n = t[H.sampleRate],
          i = t[H.traceSampleRate],
          r = t[H.normalSampleRate],
          o = t[H.warnSampleRate],
          a = t[H.errorSampleRate],
          s = t[H.fatalSampleRate],
          c = t[H.rules];
        ee.number(n) && (this._defaultSampleRate = n), ee.number(i) && (this
            ._traceSampleRate = i), ee.number(r) && (this._normalSampleRate = r), ee.number(
          o) && (this._warnSampleRate = o), ee.number(a) && (this._errorSampleRate = a), ee
          .number(s) && (this._fatalSampleRate = s);
        var u = this._parseRules(c);
        this._orderedRules = u.sort((function (e, t) {
          return t.priority - e.priority
        }))
      }, t.getSampleRate = function (e) {
        var t = this._getMatchedRuleInOrderedRules(e),
          n = this._defaultSampleRate;
        switch (e[B.monitorLevel]) {
          case M.trace:
            n = this._traceSampleRate;
            break;
          case M.normal:
            n = this._normalSampleRate;
            break;
          case M.warn:
            n = this._warnSampleRate;
            break;
          case M.error:
            n = this._errorSampleRate;
            break;
          case M.fatal:
            n = this._fatalSampleRate
        }
        return t && !ee.undefined(t.sampleRate) && (n = t.sampleRate), n >= 1 ? 1 : 0
      }, t._getMatchedRuleInOrderedRules = function (e) {
        return this._orderedRules.find((function (t) {
          return t.match(e)
        }))
      }, t._parseRules = function (e) {
        return Array.isArray(e) ? e = e.map((function (e) {
          if (ee.object(e)) {
            var t = e[H.priority],
              n = e[H.sampleRate],
              i = e[H.filters];
            return ee.number(t) || (t = 0), ee.number(n) || (n = void 0), i = Array
              .isArray(i) ? i.map((function (e) {
                if (ee.object(e)) {
                  var t = e,
                    n = t[H.key],
                    i = t[H.values];
                  return ee.string(n) && Array.isArray(i) ? e = new oe({
                    key: n,
                    values: i
                  }) : void 0
                }
              })).filter((function (e) {
                return e
              })) : [], new re({
                priority: t,
                sampleRate: n,
                filters: i
              })
          }
        })).filter((function (e) {
          return e
        })) : []
      }, e
    }(),
    se = function () {
      function e(t) {
        var n = this;
        if (this._domainToNameRegistry = new Map, this._domainToParamInjectorRegistry = new Map,
          this._domainToNameCache = new Map, t instanceof e) return t;
        var i = t || {},
          r = i.defaultName,
          o = void 0 === r ? Q : r,
          a = i.log,
          s = i.report,
          c = void 0 === s ? X : s,
          u = i.commonMetrics,
          l = void 0 === u ? {} : u,
          f = i.commonCategories,
          d = void 0 === f ? {} : f,
          p = i.commonTags,
          h = void 0 === p ? [] : p,
          v = i.defaultPlatform,
          g = void 0 === v ? "Slardar" : v,
          m = i.domainToNameMap,
          _ = i.domainToParamInjectorMap;
        this.defaultName = String(o), this.defaultPlatform = g, this._log = a, this._report = c,
          this.commonMetrics = l, this.commonCategories = d, this.commonTags = new Set(h), m &&
          Object.entries(m).forEach((function (e) {
            var t = e[0],
              i = e[1];
            n.registerEvent(i, t)
          })), _ && Object.entries(_).forEach((function (e) {
            var t = e[0],
              i = e[1];
            n.registerParamInjector(t, i)
          }))
      }
      var t = e.prototype;
      return t.setRemoteConfig = function (e) {
        this._remoteConfig = e
      }, t.getRemoteConfig = function () {
        return this._remoteConfig ? this._remoteConfig : this.constructor.globalRemoteConfig
      }, t.log = function (e) {
        ee.function(this._log) && this._log(e)
      }, t.report = function (e) {
        var t, n = this.getRemoteConfig(),
          i = e.name,
          r = e.metrics,
          o = e.categories,
          a = Object.assign(Object.assign(((t = {})[$] = i, t), r), o);
        n.getSampleRate(a) < 1 || this._report(e)
      }, t.registerEvent = function (e, t) {
        if (this._domainToNameRegistry.get(t) !== e) {
          this._domainToNameRegistry.set(t, e);
          for (var n, i = l(this._domainToNameCache.keys()); !(n = i()).done;) {
            var r = n.value;
            r.startsWith(t) && this._domainToNameCache.delete(r)
          }
        }
      }, t.getNameByDomain = function (e) {
        return this._findName(e) || this.defaultName
      }, t.registerParamInjector = function (e, t) {
        this._domainToParamInjectorRegistry.set(e, t)
      }, t.applyParamInjectorByDomain = function (e, t) {
        for (var n = e.split("."), i = n.length, r = 1; r <= i; r++) {
          var o = n.slice(0, r).join("."),
            a = this._domainToParamInjectorRegistry.get(o);
          ee.function(a) && a(t)
        }
      }, t._findName = function (e) {
        if (this._domainToNameCache.has(e)) return this._domainToNameCache.get(e);
        for (var t, n = e.split("."), i = n.length, r = 0; r < i; r++) {
          var o = n.slice(0, i - r).join(".");
          if (t = this._domainToNameRegistry.get(o)) return this._domainToNameCache.set(o, t),
            t
        }
        return this.defaultName
      }, e
    }();
  se.globalRemoteConfig = new ae;
  var ce = function () {
    function e(t) {
      if (this.cacheList = [], this.waitingForReport = !1, t instanceof e) return t;
      this._setConfig(t)
    }
    e.setup = function (e) {
      this.default._setConfig(e)
    };
    var t = e.prototype;
    return t.getConfig = function () {
      return this.config
    }, t.log = function (e) {
      if (ee.function(this.config.log)) {
        var t = V.info;
        switch (e.getLevel()) {
          case M.trace:
          case M.normal:
            t = V.info;
            break;
          case M.warn:
            t = V.warn;
            break;
          case M.error:
            t = V.error;
            break;
          case M.fatal:
            t = V.fatal;
            break;
          default:
            t = V.info
        }
        var n = G,
          i = e.getValue(B.monitorFile),
          r = e.getValue(B.monitorFunction),
          o = e.getValue(B.monitorLine),
          a = e.getData(),
          s = "monitor_event : " + e.getName() + " " + JSON.stringify(a);
        this.invokeLog(te({
          level: t,
          tag: n,
          file: i,
          functionName: r,
          line: o,
          content: s
        }, Y))
      }
    }, t.reportTo = function (e) {
      var t = e.getValue(B.monitorDomain);
      t && this.config.applyParamInjectorByDomain(String(t), e);
      var n = this._getName(e),
        i = e.getMetrics(),
        r = e.getCategories(),
        o = e.getInternalData(),
        a = this._getPlatform(e);
      ee.function(this.config.report) && this.invokeReport(te({
        name: n,
        metrics: i,
        categories: r,
        internalData: o,
        platform: a
      }, Y))
    }, t.flush = function (e) {
      var t = e.getInternalData(),
        n = t.logEnabled,
        i = void 0 === n || n,
        r = t.reportEnabled,
        o = void 0 === r || r,
        a = e.getValue(B.monitorDomain);
      a && this.config.applyParamInjectorByDomain(String(a), e);
      var s = this._getName(e),
        c = e.getMetrics(),
        u = e.getCategories(),
        l = e.getInternalData(),
        f = this._getPlatform(e);
      o && ee.function(this.config.report) && this.invokeReport(te({
        name: s,
        metrics: c,
        categories: u,
        internalData: l,
        platform: f
      }, Y)), i && this.log(e)
    }, t.flushWithThrottle = function (e) {
      var t = this,
        n = this.getConfig();
      if (ee.function(this.config.report)) {
        var i = e.getValue(B.monitorDomain);
        i && this.config.applyParamInjectorByDomain(String(i), e);
        var r, o = this._getPlatform(e),
          a = this._getName(e),
          s = function () {
            t.cacheList.forEach((function (e) {
              return n.report(Object.assign(Object.assign({}, e), {
                platform: o
              }))
            })), t.cacheList.length = 0, t.waitingForReport = !1, ie().clearTimeout(r)
          };
        this.cacheList.push({
          name: a,
          metrics: e.getMetrics(),
          categories: e.getCategories(),
          internalData: e.getInternalData()
        });
        var c = e.getValue(B.monitorLevel);
        this.cacheList.length >= 20 || c && c >= M.error ? s() : this.waitingForReport || (
          this.waitingForReport = !0, r = ie().setTimeout((function () {
            s()
          }), 2e3))
      }
    }, t.invokeLog = function (e) {
      ee.function(this.config.log) && this.config.log(e)
    }, t.invokeReport = function (e) {
      ee.function(this.config.report) && this.config.report(e)
    }, t._setConfig = function (e) {
      this.config = new se(e)
    }, t._getName = function (e) {
      var t = e.getValue(B.monitorDomain);
      return e.getName() || t && this.config.getNameByDomain(String(t)) || this.config
        .defaultName
    }, t._getPlatform = function (e) {
      return e.platform || this.config.defaultPlatform
    }, e
  }();
  ce.default = new ce;
  var ue, le, fe = function () {
      function e(t) {
        if (t instanceof e) return t;
        var n = t || {},
          i = n.domain,
          r = n.code,
          o = n.level,
          a = n.message;
        ne(this, "version", 1), ne(this, "domain", String(i)), ne(this, "code", Number(r)), ne(
          this, "ID", String(this.generateID())), ne(this, "level", o), ne(this, "message",
          String(a))
      }
      var t = e.prototype;
      return t.equals = function (t) {
        return t instanceof e && t.ID === this.ID
      }, t.toJSON = function () {
        return {
          domain: this.domain,
          code: this.code,
          level: this.level,
          message: this.message
        }
      }, t.generateID = function () {
        return this.version + "-" + this.domain + "-" + this.code
      }, e
    }(),
    de = function () {
      function e(e) {
        this.metrics = {}, this.categories = {}, this.internalData = {}, this.tags = new Set,
          this._time = 0, this._startTime = 0, this._endTime = 0, this._flushed = !1;
        var t = e || {},
          n = t.service,
          i = t.name,
          r = void 0 === i ? Q : i,
          o = t.code,
          a = t.platform,
          s = t.metrics,
          c = t.categories,
          u = t.internalData;
        this.service = n ? new ce(n) : ce.default, this.name = r, this._platform = a, o && (this
          .code = new fe(o)), this.categories = Object.assign(Object.assign({}, this.service
          .getConfig().commonCategories), c), this.metrics = Object.assign(Object.assign({},
          this.service.getConfig().commonMetrics), s), this.tags = new Set(this.service
          .getConfig().commonTags), this.internalData = Object.assign({}, u)
      }
      var t = e.prototype;
      return t.addMetricValue = function (e, t) {
        return ee.undefined(t) || (this.metrics[e] = t), this
      }, t.addCategoryValue = function (e, t) {
        return ee.undefined(t) || (this.categories[e] = t), this
      }, t.addTag = function (e) {
        return ee.undefined(e) || this.tags.add(e), this
      }, t.addMap = function (e) {
        var t = this;
        return void 0 === e && (e = {}), Object.entries(e).forEach((function (e) {
          var n = e[0],
            i = e[1];
          ee.undefined(i) || (ee.number(i) ? t.metrics[n] = i : t.categories[n] = i)
        })), this
      }, t.tracing = function (e) {
        return ee.undefined(e) || this.addCategoryValue(B.traceId, e), this
      }, t.flush = function (e) {
        if (!this._flushed) {
          this._flushed = !0;
          var t = this.getInternalData(),
            n = t.logEnabled,
            i = void 0 === n || n,
            r = t.reportEnabled,
            o = void 0 === r || r,
            a = this.code || (this.hasErrorInfo() ? this.codeIfError : void 0);
          ee.undefined(this.level) && a && a.level && this.setLevel(a.level), a && (this
              .addCategoryValue(B.monitorDomain, a.domain), this.addMetricValue(B.monitorCode,
                a.code), this.addCategoryValue(B.monitorMessage, a.message)), this.tags &&
            this.addCategoryValue(B.monitorTags, this.tagsInline()), this.time <= 0 && this
            .setTime(Date.now()), o && (e || this.service).reportTo(this), i && this.service
            .log(this)
        }
      }, t.flushWithThrottle = function () {
        this._flushed || (this._flushed = !0, this.service.flushWithThrottle(this))
      }, t.setMonitorCode = function (e) {
        return ee.undefined(e) || (this.code = new fe(e)), this
      }, t.setMonitorCodeIfError = function (e) {
        return ee.undefined(e) || (this.codeIfError = new fe(e)), this
      }, t.setLevel = function (e) {
        return ee.undefined(e) || (this.level = e, this.addMetricValue(B.monitorLevel, e)),
          this
      }, t.setErrorCode = function (e) {
        return ee.undefined(e) || this.addCategoryValue(B.errorCode, String(e)), this
      }, t.setErrorMessage = function (e) {
        return ee.undefined(e) || this.addCategoryValue(B.errorMsg, String(e)), this
      }, t.setError = function (e) {
        if (ee.undefined(e)) return this;
        var t = e.message,
          n = e.fileName,
          i = e.lineNumber,
          r = e.columnNumber,
          o = e.stack,
          a = e.code,
          s = e.monitorCode;
        return ee.string(t) && this.addCategoryValue(B.errorMsg, t), ee.string(n) && this
          .addCategoryValue(B.monitorFile, n), ee.string(o) && this.addCategoryValue(B
            .monitorStack, o), ee.number(a) && this.addMetricValue(B.errorCode, a), ee.number(
            i) && this.addMetricValue(B.monitorLine, i), ee.number(r) && this.addMetricValue(B
            .monitorColumn, r), s && this.setMonitorCode(s), this
      }, t.setTime = function (e) {
        return ee.undefined(e) || (this._time = e, this.addMetricValue(B.time, e)), this
      }, t.setPlatform = function (e) {
        return e && (this._platform = e), this
      }, t.timing = function (e) {
        var t = Date.now();
        return this._startTime > 0 ? (this._endTime = t, this.addMetricValue(e || B.duration,
          this._endTime - this._startTime)) : this._startTime = t, this
      }, t.setDuration = function (e) {
        return ee.undefined(e) || this.addMetricValue(B.duration, e), this
      }, t.getCode = function () {
        return this.code
      }, t.getName = function () {
        return this.name
      }, t.getMetrics = function () {
        return Object.assign({}, this.metrics)
      }, t.getCategories = function () {
        return Object.assign({}, this.categories)
      }, t.getInternalData = function () {
        return this.internalData
      }, t.getData = function () {
        return Object.assign(Object.assign({}, this.metrics), this.categories)
      }, t.getValue = function (e) {
        return this.metrics[e] || this.categories[e]
      }, t.setResultType = function (e) {
        return this.addCategoryValue(B.resultType, e), this
      }, t.setResultTypeSuccess = function () {
        return this.setResultType(R.success), this
      }, t.setResultTypeFail = function () {
        return this.setResultType(R.fail), this
      }, t.setResultTypeCancel = function () {
        return this.setResultType(R.cancel), this
      }, t.setResultTypeTimeout = function () {
        return this.setResultType(R.timeout), this
      }, t.getLevel = function () {
        return this.level
      }, t.getMonitorId = function () {
        return this.code && this.code.ID
      }, t.getMonitorDomain = function () {
        return this.code && this.code.domain
      }, t.toJSON = function () {
        return {
          name: this.name,
          categories: this.getCategories(),
          metrics: this.getMetrics()
        }
      }, t.addInternalData = function (e) {
        return Object.assign(this.internalData, e), this
      }, t.hasErrorInfo = function () {
        return !ee.undefined(this.getValue(B.errorCode)) || !ee.undefined(this.getValue(B
          .errorMsg))
      }, t.tagsInline = function () {
        return 0 === this.tags.size ? "" : Array.from(this.tags).reduce((function (e, t) {
          return "" + e + (t ? K + t : "")
        }))
      }, q(e, [{
        key: "time",
        get: function () {
          return this._time
        }
      }, {
        key: "platform",
        get: function () {
          return this._platform
        }
      }]), e
    }();
  ! function (e) {
    e.getSdkConfigTimeout = "h5jssdk_get_sdk_config_timeout", e.getSdkConfigError =
      "h5jssdk_get_sdk_config_error", e.authenticationFailure = "h5jssdk_authentication_failure"
  }(ue || (ue = {})),
  function (e) {
    e.success = "success", e.fail = "fail", e.cancel = "cancel", e.timeout = "timeout"
  }(le || (le = {}));
  var pe, he = "js.open_platform.web",
    ve = ((pe = {})[ue.getSdkConfigTimeout] = {
      domain: he + ".op_web_js_script_error",
      code: 10001,
      level: M.error,
      message: "h5jssdk_get_sdk_config_timeout"
    }, pe[ue.getSdkConfigError] = {
      domain: he + ".op_web_js_script_error",
      code: 10002,
      level: M.error,
      message: "h5jssdk_get_sdk_config_error"
    }, pe[ue.authenticationFailure] = {
      domain: he + ".op_web_js_script_error",
      code: 10003,
      level: M.error,
      message: "h5jssdk_authentication_failure"
    }, pe[le.success] = {
      domain: he + ".api",
      code: 1e4,
      level: M.normal,
      message: "api_success"
    }, pe[le.fail] = {
      domain: he + ".api",
      code: 10002,
      level: M.error,
      message: "api_fail"
    }, pe[le.cancel] = {
      domain: he + ".api",
      code: 10001,
      level: M.warn,
      message: "api_cancel"
    }, pe[le.timeout] = {
      domain: he + ".api",
      code: 10003,
      level: M.error,
      message: "api_timeout"
    }, pe.DEFAULT = {
      domain: he,
      code: 1e4,
      level: M.warn,
      message: "undefined_default_code"
    }, pe),
    ge = "op_web_js_script_error",
    me = "op_web_api_invoke_result",
    _e = [],
    ye = function (e, t) {
      return void 0 === e && (e = ""), void 0 === t && (t = ve.DEFAULT), new de(e ? {
        name: e,
        code: new fe(t)
      } : {
        name: "undefined_default_code",
        code: new fe(ve.DEFAULT)
      })
    },
    we = function () {
      return (we = Object.assign || function (e) {
        for (var t, n = 1, i = arguments.length; n < i; n++)
          for (var r in t = arguments[n]) Object.prototype.hasOwnProperty.call(t, r) && (e[
            r] = t[r]);
        return e
      }).apply(this, arguments)
    };

  function be(e, t) {
    var n = "function" == typeof Symbol && e[Symbol.iterator];
    if (!n) return e;
    var i, r, o = n.call(e),
      a = [];
    try {
      for (;
        (void 0 === t || t-- > 0) && !(i = o.next()).done;) a.push(i.value)
    } catch (e) {
      r = {
        error: e
      }
    } finally {
      try {
        i && !i.done && (n = o.return) && n.call(o)
      } finally {
        if (r) throw r.error
      }
    }
    return a
  }

  function ke() {
    for (var e = [], t = 0; t < arguments.length; t++) e = e.concat(be(arguments[t]));
    return e
  }
  var Se, Ce = (function (e, t) {
      var n;
      n = function () {
        function e() {
          for (var e = 0, t = {}; e < arguments.length; e++) {
            var n = arguments[e];
            for (var i in n) t[i] = n[i]
          }
          return t
        }

        function t(e) {
          return e.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent)
        }
        return function n(i) {
          function r() {}

          function o(t, n, o) {
            if ("undefined" != typeof document) {
              "number" == typeof (o = e({
                path: "/"
              }, r.defaults, o)).expires && (o.expires = new Date(1 * new Date + 864e5 * o
                .expires)), o.expires = o.expires ? o.expires.toUTCString() : "";
              try {
                var a = JSON.stringify(n);
                /^[\{\[]/.test(a) && (n = a)
              } catch (e) {}
              n = i.write ? i.write(n, t) : encodeURIComponent(String(n)).replace(
                /%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,
                decodeURIComponent), t = encodeURIComponent(String(t)).replace(
                /%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent).replace(/[\(\)]/g,
                escape);
              var s = "";
              for (var c in o) o[c] && (s += "; " + c, !0 !== o[c] && (s += "=" + o[c]
                .split(";")[0]));
              return document.cookie = t + "=" + n + s
            }
          }

          function a(e, n) {
            if ("undefined" != typeof document) {
              for (var r = {}, o = document.cookie ? document.cookie.split("; ") : [], a =
                  0; a < o.length; a++) {
                var s = o[a].split("="),
                  c = s.slice(1).join("=");
                n || '"' !== c.charAt(0) || (c = c.slice(1, -1));
                try {
                  var u = t(s[0]);
                  if (c = (i.read || i)(c, u) || t(c), n) try {
                    c = JSON.parse(c)
                  } catch (e) {}
                  if (r[u] = c, e === u) break
                } catch (e) {}
              }
              return e ? r[e] : r
            }
          }
          return r.set = o, r.get = function (e) {
            return a(e, !1)
          }, r.getJSON = function (e) {
            return a(e, !0)
          }, r.remove = function (t, n) {
            o(t, "", e(n, {
              expires: -1
            }))
          }, r.defaults = {}, r.withConverter = n, r
        }((function () {}))
      }, e.exports = n()
    }(Se = {
      exports: {}
    }), Se.exports),
    Ee = function () {
      function e() {
        this.cache = {}
      }
      return e.prototype.setItem = function (e, t) {
        this.cache[e] = t
      }, e.prototype.getItem = function (e) {
        return this.cache[e]
      }, e.prototype.removeItem = function (e) {
        this.cache[e] = void 0
      }, e
    }(),
    ze = {
      getItem: function (e) {
        try {
          var t = localStorage.getItem(e),
            n = t;
          try {
            t && "string" == typeof t && (n = JSON.parse(t))
          } catch (e) {}
          return n || void 0
        } catch (e) {}
      },
      setItem: function (e, t) {
        try {
          var n = "string" == typeof t ? t : JSON.stringify(t);
          localStorage.setItem(e, n)
        } catch (e) {}
      },
      removeItem: function (e) {
        try {
          localStorage.removeItem(e)
        } catch (e) {}
      },
      getCookie: function (e, t) {
        try {
          return Ce.get(e, {
            domain: t || document.domain
          })
        } catch (e) {
          return ""
        }
      },
      setCookie: function (e, t, n, i) {
        try {
          var r = i || document.domain,
            o = +new Date + n;
          Ce.set(e, t, {
            expires: new Date(o),
            path: "/",
            domain: r
          })
        } catch (e) {}
      },
      isSupportLS: function () {
        try {
          return localStorage.setItem("_ranger-test-key", "hi"), localStorage.getItem(
            "_ranger-test-key"), localStorage.removeItem("_ranger-test-key"), !0
        } catch (e) {
          return !1
        }
      }()
    },
    Te = {
      getItem: function (e) {
        try {
          var t = sessionStorage.getItem(e),
            n = t;
          try {
            t && "string" == typeof t && (n = JSON.parse(t))
          } catch (e) {}
          return n || void 0
        } catch (e) {}
      },
      setItem: function (e, t) {
        try {
          var n = "string" == typeof t ? t : JSON.stringify(t);
          sessionStorage.setItem(e, n)
        } catch (e) {}
      },
      removeItem: function (e) {
        try {
          sessionStorage.removeItem(e)
        } catch (e) {}
      },
      isSupportSession: function () {
        try {
          return sessionStorage.setItem("_ranger-test-key", "hi"), sessionStorage.getItem(
            "_ranger-test-key"), sessionStorage.removeItem("_ranger-test-key"), !0
        } catch (e) {
          return !1
        }
      }()
    },
    Ie = function () {
      function e(e, t) {
        this._storage = t && "session" === t ? Te : !e && ze.isSupportLS ? ze : new Ee
      }
      return e.prototype.getItem = function (e) {
        return this._storage.getItem(e)
      }, e.prototype.setItem = function (e, t) {
        this._storage.setItem(e, t)
      }, e.prototype.getCookie = function (e, t) {
        return this._storage.getCookie(e, t)
      }, e.prototype.setCookie = function (e, t, n, i) {
        this._storage.setCookie(e, t, n, i)
      }, e.prototype.removeItem = function (e) {
        this._storage.removeItem(e)
      }, e
    }(),
    Oe = {
      cn: "1fz22z22z1nz21z4mz4bz4bz1kz1az21z4az21z1lz21z21z1bz1iz4az1az1mz1k",
      sg: "1fz22z22z1nz21z4mz4bz4bz21z1ez18z1jz1gz49z1kz1az21z4az19z27z22z1cz1mz24z1cz20z21z1cz18z4az1az1mz1k",
      va: "1fz22z22z1nz21z4mz4bz4bz1kz18z1jz1gz24z18z49z1kz1az21z4az19z27z22z1cz1mz24z1cz20z21z1cz18z4az1az1mz1k"
    },
    xe = "/v1/user/webid",
    Ae = "/v1/list",
    Le = "4.1.54",
    je = 4001,
    Pe = 4e3,
    De = 4002,
    Ne = 4003,
    Me = 500,
    Be = 5005,
    Fe = function (e, t) {
      return t ? "__tea_cache_tokens_" + e : "__tea_cache_events_" + e
    },
    Re = {
      cn: "1fz22z22z1nz21z4mz4bz4bz22z1mz19z1jz1mz1ez4az1az22z1mz19z21z1lz21z21z1bz1iz4az1az1mz1k",
      va: "1fz22z22z1nz21z4mz4bz4bz22z1mz19z1jz1mz1ez4az1gz22z1mz19z21z1lz21z21z1bz1iz4az1az1mz1k",
      sg: "1fz22z22z1nz21z4mz4bz4bz22z1mz19z1jz1mz1ez4az22z1mz19z21z1lz21z21z1bz1iz4az1az1mz1k"
    },
    Ve = "__rangers_ab_style__";

  function Ue(e, t, n, i, r, o, a) {
    try {
      var s = new XMLHttpRequest,
        c = a || "POST";
      s.open(c, "" + e, !0), s.setRequestHeader("Content-Type",
          "application/json; charset=utf-8"), r && s.setRequestHeader("X-MCS-AppKey", "" + r), s
        .onload = function () {
          if (n) {
            var e = null;
            if (s.responseText) {
              try {
                e = JSON.parse(s.responseText)
              } catch (t) {
                e = {}
              }
              n(e, t)
            }
          }
        };
      try {
        o && (s.timeout = o), o && (s.ontimeout = function () {
          i && i(t, Be)
        }), s.onerror = function () {
          s.abort(), i && i(t, Me)
        }, s.send(JSON.stringify(t))
      } catch (e) {}
    } catch (e) {}
  }
  var We = function (e, t, n, i) {
      try {
        var r, o = e.match(/\/v\d\//);
        r = o ? o[0] : -1 !== e.indexOf("/v1/") ? "/v1/" : "/v2/";
        var a = e.split(r)[0];
        if (!a) return void i(e, t, je);
        t.forEach((function (r) {
          var o = function (e) {
              var t = "";
              for (var n in e) e.hasOwnProperty(n) && void 0 !== e[n] && (t += "&" + n +
                "=" + encodeURIComponent(JSON.stringify(e[n])));
              return "&" === t[0] ? t.slice(1) : t
            }(r),
            s = new Image(1, 1);
          s.onload = function () {
            s = null, n && n()
          }, s.onerror = function () {
            s = null, i && i(e, t, Pe)
          }, s.src = a + "/v1/gif?" + o
        }))
      } catch (n) {
        i && i(e, t, De, n.message)
      }
    },
    qe = function (e, t, n, i, r, o, a, s, c) {
      var u = window.navigator.userAgent,
        l = -1 !== window.navigator.appName.indexOf("Microsoft Internet Explorer") && (-1 !== u
          .indexOf("MSIE 8.0") || -1 !== u.indexOf("MSIE 9.0")),
        f = !!i;
      if (!f && l) We(e, n, r, o);
      else if (!f && s) return window.navigator && window.navigator.sendBeacon ? (c && c(),
          void(window.navigator.sendBeacon(e, JSON.stringify(n)) ? r() : o(e, n, Ne))) :
        void We(e, n, r, o);
      a && delete n.app_key, Ue(e, n, r, o, i, t)
    };

  function Je(e) {
    return e ? (e ^ 16 * Math.random() >> e / 4).toString(10) : ([1e7] + -1e3 + -4e3 + -8e3 + -
      1e11).replace(/[018]/g, Je)
  }
  var He = function () {
      return Je().replace(/-/g, "").slice(0, 19)
    },
    Ke = function (e) {
      return function (e, t, n) {
        if ("string" == typeof e && "number" == typeof n) {
          var i, r = [];
          n = n <= 25 ? n : n % 25;
          var o = String.fromCharCode(n + 97);
          i = e.split(o);
          for (var a = 0; a < i.length; a++) {
            var s = parseInt(i[a], n);
            s = 1 * s ^ 64;
            var c = String.fromCharCode(s);
            r.push(c)
          }
          return r.join("")
        }
      }(e, 0, 25)
    },
    Qe = function () {
      function e(e, t, n, i) {
        this.config = e, this.cfg = t, this.storage = new Ie(!1), this.appInfo = e.app_key || e
          .app_id, this.isNoWebid = e.disable_webid, this.isNoSsid = e.disable_webid || e
          .disable_ssid, this.domain = e.channel_domain || Ke(Oe[e.channel]), this
          .tokenReady = !1, this.checkExp = e.checkExp || !1, this.enableCookie = e
          .cross_subdomain || !1, this.expiresTime = this.enableCookie ? 94608e6 : 0, this
          .fetchUrl = "" + this.domain + xe, this.uuidQueue = [], this.sendQueue = [], this
          .hook = n, this.cookieDomain = e.cookie_domain || "", this.session = i
      }
      return e.prototype._getToken = function () {
        this.tokensKey = Fe(this.appInfo, !0), this.cookieKey = "__tea_cookie_tokens_" + this
          .appInfo;
        try {
          var e = this.storage.getItem(this.tokensKey);
          if (this.enableCookie) {
            var t = this.storage.getCookie(this.cookieKey, this.cookieDomain);
            if (t) {
              t = decodeURIComponent(t);
              var n = (t = JSON.parse(t)).user_unique_id,
                i = t.web_id,
                r = t.timestamp,
                o = t.ssid;
              this._setToken(i, n, o, r)
            } else {
              if (!e) return void this._requestWebId();
              this.storage.setCookie(this.cookieKey, encodeURIComponent(JSON.stringify(e)),
                this.expiresTime, this.cookieDomain), this._checkLocal(e)
            }
          } else {
            if (!e) return void this._requestWebId();
            this._checkLocal(e)
          }
        } catch (e) {}
      }, e.prototype._checkLocal = function (e) {
        if (!e.web_id) {
          var t = this.storage.getCookie("tt_webid"),
            n = this.storage.getCookie("__tea_sdk__ssid"),
            i = this.storage.getCookie("__tea_sdk__user_unique_id");
          if (t && n && i) {
            var r = {
              web_id: t,
              ssid: n,
              user_unique_id: i,
              timestamp: Date.now()
            };
            this.storage.setItem(this.tokensKey, r)
          }
        }
        var o = this.storage.getItem(this.tokensKey),
          a = (i = o.user_unique_id, t = o.web_id, o.timestamp);
        n = o.ssid, t && i ? this._setToken(t, i, n, a) : this._requestWebId()
      }, e.prototype._setToken = function (e, t, n, i) {
        if (this.uuid = t, this.web_id = e, this.ssid = n, this.checkExp) {
          var r = Date.now() - parseFloat(i);
          if (r > 7344e6) return void this._requestWebId();
          if (r > 432e7) return void this._updateWebId(e)
        }
        this._complete(e, t, n)
      }, e.prototype._complete = function (e, t, n) {
        this.cfg.envInfo.user.ssid = n, this.cfg.envInfo.user.web_id = e, this.cfg.envInfo
          .user.user_unique_id = t, this.tokenReady = !0
      }, e.prototype._requestWebId = function () {
        this.isNoWebid ? this._setTokenId(He(), "", "", !0) : this._fetchWebId(this.fetchUrl,
          !1)
      }, e.prototype._updateWebId = function (e) {
        var t = "" + this.domain + xe + "/" + e + "/update";
        this._fetchWebId(t, !0)
      }, e.prototype._fetchWebId = function (e, t) {
        var n = this;
        this.isGetWebId = !0, qe(e, 5e3, {
          app_key: this.config.app_key,
          app_id: this.config.app_id,
          url: location.href,
          user_agent: window.navigator.userAgent,
          referer: document.referrer,
          user_unique_id: ""
        }, this.config.app_key, (function (e) {
          n.isGetWebId = !1, e && 0 === e.e ? (n.web_id = e.web_id, n.uuidQueue.length ?
            n._requestSsId(n.uuidQueue[0]) : n._setTokenId(e.web_id, "", t ? n.ssid :
              e.ssid, !0)) : (n.hook.emit("token-error"), console.warn("[]appid: " + n
            .config.app_id + ", get webid error, init error~"))
        }), (function () {
          n.isGetWebId = !1, n.hook.emit("token-error"), console.warn("[]appid: " + n
            .config.app_id + ", get webid error, init error~")
        }), !0)
      }, e.prototype._setTokenId = function (e, t, n, i) {
        var r = this.cfg.envInfo.user.web_id || e,
          o = {
            web_id: r,
            ssid: n,
            user_unique_id: t || r,
            timestamp: Date.now()
          };
        this.enableCookie && this.storage.setCookie(this.cookieKey, encodeURIComponent(JSON
            .stringify(o)), this.expiresTime, this.cookieDomain), this.storage.setItem(this
            .tokensKey, o), this.cfg.envInfo.user.ssid = n, this.cfg.envInfo.user.web_id = r,
          this.cfg.envInfo.user.user_unique_id = t || r, this.uuid = t || r, this.web_id = r,
          this.ssid = n, i && (this.tokenReady = !0, this.hook.emit("token-ready"))
      }, e.prototype._getSsid = function (e) {
        e && -1 === ["0", "Null", "None", "", "undefined"].indexOf(e) && this.uuid !== e && (
          this.uuidQueue.push(e), this.uuid = e, this.cfg.envInfo.user.user_unique_id = e, -
          1 === this.uuidQueue.indexOf(e) && this.uuidQueue.push(e), this.isNoSsid || this
          .isGetWebId || (this.tokenReady = !1, this._requestSsId(e), this.sendQueue.push(
            e)), this.session._resetSessionId())
      }, e.prototype._requestSsId = function (e) {
        var t = this;
        if (!this.sendQueue.length) {
          var n = this.domain + "/v1/user/ssid";
          qe(n, 5e3, {
            app_key: this.config.app_key,
            app_id: this.config.app_id,
            web_id: this.web_id,
            user_unique_id: e
          }, this.config.app_key, (function (n) {
            t.sendQueue = [], t.uuidQueue.length && t.uuidQueue.splice(t.uuidQueue
              .indexOf(e), 1), n && 0 === n.e ? (t._setTokenId(t.web_id, e, n.ssid,
              0 === t.uuidQueue.length), t.uuidQueue.length && t._requestSsId(t
              .uuidQueue[0])) : (t.tokenReady = !0, t.hook.emit("token-ready"),
              console.warn("[]appid: " + t.config.app_id + ", get ssid error"))
          }), (function () {
            t.tokenReady = !0, t.hook.emit("token-ready")
          }), !0)
        }
      }, e.prototype.isTokenReady = function () {
        return this.tokenReady
      }, e
    }(),
    Ge = function (e) {
      navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ? window.addEventListener(
        "pagehide", e, !1) : window.addEventListener("beforeunload", e, !1)
    },
    $e = function (e, t, n) {
      void 0 === e && (e = []), void 0 === t && (t = function (e) {
        return e
      }), void 0 === n && (n = 20);
      var i, r = [],
        o = 0;
      return e.forEach((function (e) {
        var a = t(e);
        void 0 === i ? i = a : (a !== i || r[o].length >= n) && (o += 1, i = a), r[o] = r[
          o] || [], r[o].push(e)
      })), r
    },
    Xe = function () {
      function e(e, t, n, i, r, o, a) {
        this.collect = e, this.cfg = n, this.config = t, this._token = i, this.appInfo = t
          .app_id || t.app_key, this.debugMode = !!t.log, this.evtDataKey = Fe(this.appInfo, !
          1);
        var s = t.channel_domain || Ke(Oe[t.channel]);
        if (this.reportUrl = t.report_url ? t.report_url : "" + s + Ae, this.storage = new Ie(!
            0), this.EventStorage = new Ie(!1), this.maxStorage = t.max_storage_num || -1, this
          .maxReport = t.max_report || 10, this.reportTime = t.reportTime || 30, this.timeout =
          t.timeout || 1e5, this.closeStorage = !0, this.plugin = r, this.session = o, this
          .filter = a, this.plugin) {
          var c = t.enable_storage,
            u = t.disable_storage;
          (c || !1 === u) && (this.closeStorage = !1)
        }
        this.addListener()
      }
      return e.prototype.addListener = function () {
        var e = this;
        window.addEventListener("unload", (function () {
          e.report(!0)
        }), !1), Ge((function () {
          e.report(!0)
        })), document.addEventListener("visibilitychange", (function () {
          "hidden" === document.visibilityState && e.report(!0)
        }), !1)
      }, e.prototype.setReady = function () {
        this.isReady = !0, this.closeStorage || this.checkStorageEvent(), this.report()
      }, e.prototype.event = function (e) {
        var t = this;
        void 0 === e && (e = []);
        try {
          var n = ke(e, this.storage.getItem(this.evtDataKey) || []);
          this.storage.setItem(this.evtDataKey, n), this.reportTimer && clearTimeout(this
              .reportTimer), n.length >= this.maxReport ? this.report(!1) : this.reportTimer =
            setTimeout((function () {
              t.report(!1), t.reportTimer = null
            }), this.reportTime)
        } catch (e) {}
      }, e.prototype.beconEvent = function (e) {
        void 0 === e && (e = []);
        var t = this._mergeEvents(e);
        this._dealData(t, !0)
      }, e.prototype.report = function (e) {
        if (void 0 === e && (e = !1), !this.collect.destroyInstance && this._token
          .isTokenReady() && this.isReady) {
          var t = this.storage.getItem(this.evtDataKey) || [],
            n = this._mergeEvents(t);
          this.storage.removeItem(this.evtDataKey), this._dealData(n, e)
        }
      }, e.prototype._mergeEvents = function (e) {
        var t = this;
        if (!e.length) return e;
        var n = this.cfg.get();
        return n.header.custom = JSON.stringify(n.header.custom), $e(e, (function (e) {
          return !t.closeStorage && !!e.params.__disable_storage__
        }), this.maxReport).map((function (e) {
          return we({
            events: e.map((function (e) {
              try {
                if (e.event && "applog_trace" !== e.event) {
                  var n = we({}, t.cfg.get("evtParams"), e.params);
                  delete n.__disable_storage__;
                  var i = [];
                  return t.plugin && t.plugin.ab && t.plugin.ab.versions && t
                    .plugin.ab.extVersions && (i = [], i = t.config
                      .enable_multilink || -1 !== window.location.href
                      .indexOf("multilink=true") ? t.plugin.ab
                      .mulilinkVersions.concat(t.plugin.ab.extVersions) : t
                      .plugin.ab.versions.concat(t.plugin.ab.extVersions)),
                    we({}, e, {
                      params: JSON.stringify(n),
                      ab_sdk_version: i.join(","),
                      session_id: t.session._getSessionId()
                    })
                }
                return we({}, e, {
                  params: JSON.stringify(e.params)
                })
              } catch (t) {
                return we({}, e, {
                  params: JSON.stringify(e.params)
                })
              }
            })),
            user: n.user,
            header: n.header
          }, t.closeStorage ? {} : {
            __disable_storage__: e[0].params.__disable_storage__
          }, {
            verbose: t.debugMode ? 1 : void 0,
            local_time: parseInt("" + (new Date).getTime() / 1e3)
          })
        }))
      }, e.prototype._dealData = function (e, t) {
        var n = this;
        if (!e.length) return e;
        var i = [];
        i = $e(e, (function (e) {
            return !!e.__disable_storage__
          }), this.maxReport), !this.closeStorage && this.plugin.maxStorage && this.plugin
          .maxStorage(i, this.maxStorage, this.evtDataKey, this.storage);
        var r = {};
        i.forEach((function (e) {
          var i = He(),
            o = e;
          try {
            n.filter && (o = n.filter(e))
          } catch (e) {}
          if (!n.closeStorage && !e[0].__disable_storage__) {
            var a = JSON.parse(JSON.stringify(e));
            a && a[0] && (a[0].header.__storage_index__ = Date.now()), r[i] = a, n
              .EventStorage.setItem(n.evtDataKey, r)
          }
          n._send(i, o, t)
        }))
      }, e.prototype._send = function (e, t, n) {
        var i = this;
        this.isSending = !0;
        var r = function () {
          i.isSending = !1
        };
        if (!this.closeStorage) try {
          t && t[0] && t[0].header.__storage_index__ && delete(t = JSON.parse(JSON
            .stringify(t)))[0].header.__storage_index__
        } catch (e) {}
        this.plugin && this.plugin.et_test && this.plugin.et_test.send(t), qe(this.reportUrl,
            this.timeout, t, this.config.app_key, (function (t, n) {
              if (r(), i.plugin && !i.closeStorage) {
                var o = i.EventStorage.getItem(i.evtDataKey) || {};
                Object.keys(o).length ? (delete o[e], i.EventStorage.setItem(i.evtDataKey,
                  o)) : i.EventStorage.removeItem(i.evtDataKey)
              }
              t && 0 !== t.e && i.collect && i.collect.tracer && 1 !== i.cfg.staging && i
                .collect.tracer.addErrorCount(n, "f_data", t.e, t)
            }), (function (e, t) {
              r(), i.cfg.get("reportErrorCallback")(e, t), i.collect && i.collect.tracer &&
                1 !== i.cfg.staging && i.collect.tracer.addErrorCount(e, "f_net", t), i
                .plugin && i.plugin.monitor && i.plugin.monitor.sdkError(i.config.app_key, i
                  .reportUrl, e, t)
            }), !1, n, r), this.plugin && this.plugin.monitor && this.plugin.monitor
          .sdkOnload(this.config.app_key, this.reportUrl, t)
      }, e.prototype.checkStorageEvent = function () {
        var e = this;
        try {
          var t = this.EventStorage.getItem(this.evtDataKey) || {},
            n = Object.keys(t);
          n.length > 0 && setTimeout((function i() {
            for (var r = [], o = 0; o < e.maxReport; o++) n.length > 0 && r.push(n
              .shift());
            r.length > 0 && r.forEach((function (n) {
              e._send(n, t[n], !1)
            })), setTimeout(i, 5)
          }), 5)
        } catch (e) {}
      }, e
    }(),
    Ye = void 0,
    Ze = (new Date).getTimezoneOffset(),
    et = parseInt("" + -Ze / 60, 10),
    tt = 60 * Ze,
    nt = function () {
      function e(e, t, n) {
        this.cookieDomain = t.cookie_domain || "", this.initConfig = t;
        var i = function (e, t) {
          var n, i, r = function (e) {
              var t = document.createElement("a");
              return t.href = e, t
            },
            o = window.screen.width,
            a = window.screen.height,
            s = window.navigator.appVersion,
            c = window.navigator.userAgent,
            u = window.navigator.language,
            l = document.referrer,
            f = l ? r(l).hostname : "",
            d = function (e) {
              var t = r(e).search;
              t = t.slice(1);
              var n = {};
              return t.split("&").forEach((function (e) {
                var t, i, r = e.split("=");
                r.length && (t = r[0], i = r[1]);
                try {
                  n[t] = decodeURIComponent(void 0 === i ? "" : i)
                } catch (e) {
                  n[t] = i
                }
              })), n
            }(window.location.href),
            p = "",
            h = "",
            v = "",
            g = "" + parseFloat(s); - 1 !== (n = c.indexOf("Opera")) && (v = "Opera", g = c
              .substring(n + 6), -1 !== (n = c.indexOf("Version")) && (g = c.substring(n + 8))
              ), -1 !== (n = c.indexOf("Edge")) ? (v = "Microsoft Edge", g = c.substring(n +
              5)) : -1 !== (n = c.indexOf("MSIE")) ? (v = "Microsoft Internet Explorer", g = c
              .substring(n + 5)) : -1 !== (n = c.indexOf("Lark")) ? (v = "Lark", g = c
              .substring(n + 5, n + 11)) : -1 !== c.indexOf("Chrome") ? -1 !== (n = c.indexOf(
              "MicroMessenger")) ? (v = "weixin", g = c.substring(n + 15, n + 20)) : -1 !== (
              n = c.indexOf("MQQBrowser")) ? (v = "qqbrowser", g = c.substring(n + 11, n +
              15)) : -1 !== (n = c.indexOf("360")) ? (v = "360browser", g = c.substring(c
              .indexOf("Chrome") + 7)) : -1 !== c.indexOf("baidubrowser") || -1 !== c.indexOf(
              "BIDUBrowser") ? (-1 !== c.indexOf("baidubrowser") ? (n = c.indexOf(
              "baidubrowser"), g = c.substring(n + 13, n + 16)) : -1 !== c.indexOf(
              "BIDUBrowser") && (n = c.indexOf("BIDUBrowser"), g = c.substring(n + 12, n +
              15)), v = "baidubrowser") : -1 !== (n = c.indexOf("xiaomi")) ? -1 !== c.indexOf(
              "openlanguagexiaomi") ? (v = "openlanguage xiaomi", g = c.substring(n + 7, n +
              13)) : (v = "xiaomi", g = c.substring(n - 7, n - 1)) : -1 !== (n = c.indexOf(
              "TTWebView")) ? (v = "TTWebView", g = c.substring(n + 10, n + 23)) : -1 !== (n =
              c.indexOf("Chrome")) && (v = "Chrome", g = c.substring(n + 7)) : -1 !== c
            .indexOf("Safari") ? -1 !== (n = c.indexOf("QQ")) ? (v = "qqbrowser", g = c
              .substring(n + 10, n + 16)) : -1 !== (n = c.indexOf("Safari")) && (v = "Safari",
              g = c.substring(n + 7), -1 !== (n = c.indexOf("Version")) && (g = c.substring(
                n + 8))) : -1 !== (n = c.indexOf("Firefox")) ? (v = "Firefox", g = c
              .substring(n + 8)) : -1 !== (n = c.indexOf("MicroMessenger")) ? (v = "weixin",
              g = c.substring(n + 15, n + 20)) : -1 !== (n = c.indexOf("QQ")) && (v =
              "qqbrowser", g = c.substring(n + 3, n + 8)), -1 !== (i = g.indexOf(";")) && (g =
              g.substring(0, i)), -1 !== (i = g.indexOf(" ")) && (g = g.substring(0, i)), -
            1 !== (i = g.indexOf(")")) && (g = g.substring(0, i));
          for (var m, _, y = /Mobile|htc|mini|Android|iP(ad|od|hone)/.test(s) ? "wap" : "web",
              w = [{
                s: "Windows 10",
                r: /(Windows 10.0|Windows NT 10.0|Windows NT 10.1)/
              }, {
                s: "Windows 8.1",
                r: /(Windows 8.1|Windows NT 6.3)/
              }, {
                s: "Windows 8",
                r: /(Windows 8|Windows NT 6.2)/
              }, {
                s: "Windows 7",
                r: /(Windows 7|Windows NT 6.1)/
              }, {
                s: "Android",
                r: /Android/
              }, {
                s: "Sun OS",
                r: /SunOS/
              }, {
                s: "Linux",
                r: /(Linux|X11)/
              }, {
                s: "iOS",
                r: /(iPhone|iPad|iPod)/
              }, {
                s: "Mac OS X",
                r: /Mac OS X/
              }, {
                s: "Mac OS",
                r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
              }], b = 0; b < w.length; b++) {
            var k = w[b];
            if (k.r.test(c)) {
              p = k.s;
              break
            }
          }

          function S(e, t) {
            var n = e.exec(t);
            return n && n[1] ? n[1] : ""
          }
          switch (/Windows/.test(p) && (h = S(/Windows (.*)/, p), p = "windows"), p) {
            case "Mac OS X":
              m = c, h = (_ = RegExp(
                "(?:^|[^A-Z0-9-_]|[^A-Z0-9-]_|sprd-)(?:Mac[ +]OS[ +]X(?:[ /](?:Version )?(\\d+(?:[_\\.]\\d+)+))?)",
                "i").exec(m)) ? _.slice(1)[0] : "", p = "mac";
              break;
            case "Android":
              h = function (e) {
                var t = S(/Android ([\.\_\d]+)/, e);
                return t || (t = S(/Android\/([\.\_\d]+)/, e)), t
              }(c), p = "android";
              break;
            case "iOS":
              h = (h = /OS (\d+)_(\d+)_?(\d+)?/.exec(s)) ? h[1] + "." + h[2] + "." + (0 | h[
                3]) : "", p = "ios"
          }
          var C = function (e, t, n) {
            var i = new Ie(!1),
              r = new Ie(!1, "session"),
              o = e ? "_tea_utm_cache_" + e : "_tea_utm_cache",
              a = e ? "_$utm_from_url_" + e : "_$utm_from_url",
              s = {},
              c = ["tr_shareuser", "tr_admaster", "tr_param1", "tr_param2", "tr_param3",
                "tr_param4", "$utm_from_url"
              ],
              u = {
                utm_source: t.utm_source,
                utm_medium: t.utm_medium,
                utm_campaign: t.utm_campaign,
                utm_term: t.utm_term,
                utm_content: t.utm_content,
                tr_shareuser: t.tr_shareuser,
                tr_admaster: t.tr_admaster,
                tr_param1: t.tr_param1,
                tr_param2: t.tr_param2,
                tr_param3: t.tr_param3,
                tr_param4: t.tr_param4
              };
            try {
              var l = !1;
              for (var f in u) u[f] && (-1 !== c.indexOf(f) ? (s.hasOwnProperty(
                  "tracer_data") || (s.tracer_data = {}), s.tracer_data[f] = u[f]) : s[
                f] = u[f], l = !0);
              if (l) r.setItem(a, "1"), i.setCookie(o, JSON.stringify(s), 7776e6, n);
              else {
                var d = i.getCookie(o, n);
                d && (s = JSON.parse(d))
              }
              r.getItem(a) && (s.hasOwnProperty("tracer_data") || (s.tracer_data = {}), s
                .tracer_data.$utm_from_url = 1)
            } catch (e) {
              return u
            }
            return s
          }(e, d, t);
          return {
            browser: v,
            browser_version: g,
            platform: y,
            os_name: p,
            os_version: h,
            userAgent: c,
            screen_width: o,
            screen_height: a,
            device_model: function (e) {
              var t = "";
              try {
                if ("android" === e) navigator.userAgent.split(";").forEach((function (e) {
                  e.indexOf("Build/") > -1 && (t = e.slice(0, e.indexOf("Build/")))
                }));
                else if ("ios" === e || "mac" === e || "windows" === e) {
                  var n = navigator.userAgent.replace("Mozilla/5.0 (", ""),
                    i = n.indexOf(";");
                  t = n.slice(0, i)
                }
              } catch (e) {}
              return t.trim()
            }(p),
            language: u,
            referrer: l,
            referrer_host: f,
            utm_source: C.utm_source,
            utm_medium: C.utm_medium,
            utm_campaign: C.utm_campaign,
            utm_term: C.utm_term,
            utm_content: C.utm_content,
            tracer_data: C.tracer_data
          }
        }(e, this.cookieDomain);
        this.configKey = function (e) {
            return "__tea_cache_config_" + e
          }("" + e), this.sessionStorage = new Ie(!1, "session"), this.localStorage = new Ie(!1,
            "local"), n && (this.storage = 1 === n ? this.sessionStorage : this.localStorage),
          this.envInfo = {
            user: {
              user_unique_id: Ye,
              user_type: Ye,
              user_id: Ye,
              user_is_auth: Ye,
              user_is_login: Ye,
              device_id: Ye,
              web_id: Ye,
              ip_addr_id: Ye,
              ssid: Ye
            },
            header: {
              app_id: Ye,
              app_name: Ye,
              app_install_id: Ye,
              install_id: Ye,
              app_package: Ye,
              app_channel: Ye,
              app_version: Ye,
              os_name: i.os_name,
              os_version: i.os_version,
              device_model: i.device_model,
              ab_client: Ye,
              ab_version: Ye,
              ab_sdk_version: Ye,
              traffic_type: Ye,
              client_ip: Ye,
              device_brand: Ye,
              os_api: Ye,
              access: Ye,
              language: i.language,
              region: Ye,
              app_language: Ye,
              app_region: Ye,
              creative_id: Ye,
              ad_id: Ye,
              campaign_id: Ye,
              log_type: Ye,
              rnd: Ye,
              platform: i.platform,
              sdk_version: Le,
              sdk_lib: "js",
              province: Ye,
              city: Ye,
              timezone: et,
              tz_offset: tt,
              tz_name: Ye,
              sim_region: Ye,
              carrier: Ye,
              resolution: i.screen_width + "x" + i.screen_height,
              browser: i.browser,
              browser_version: i.browser_version,
              referrer: i.referrer,
              referrer_host: i.referrer_host,
              width: i.screen_width,
              height: i.screen_height,
              screen_width: i.screen_width,
              screen_height: i.screen_height,
              utm_term: i.utm_term,
              utm_content: i.utm_content,
              utm_source: i.utm_source,
              utm_medium: i.utm_medium,
              utm_campaign: i.utm_campaign,
              tracer_data: JSON.stringify(i.tracer_data),
              custom: {}
            }
          }, this.evtParams = {}, this.reportErrorCallback = function () {}
      }
      return e.prototype.set = function (e, t) {
        var n = this;
        if (null == t && (this.delete(e), t = void 0), "evtParams" === e ||
          "_staging_flag" === e) {
          var i;
          i = "evtParams" === e ? t : {
            _staging_flag: Number(t)
          };
          var r = we({}, i);
          Object.keys(r).forEach((function (e) {
            n.evtParams[e] = r[e]
          }))
        } else if ("reportErrorCallback" === e && "function" == typeof t) this
          .reportErrorCallback = t;
        else {
          var o = "";
          if (e.indexOf(".") > -1) {
            var a = e.split(".");
            o = a[0], e = a[1]
          }
          if ("user_unique_id" === e) {
            if (!t) return;
            if (-1 !== ["0", "Null", "None", "", "undefined"].indexOf(t)) return
          }
          if ("os_version" === e && (e = "" + t), "web_id" === e) {
            if (!t) return;
            (!this.envInfo.user.user_unique_id || this.envInfo.user.user_unique_id && this
              .envInfo.user.user_unique_id === this.envInfo.user.web_id) && (this.envInfo.user
              .user_unique_id = t)
          }
          o ? "user" === o || "header" === o ? this.envInfo[o][e] = t : this.envInfo.header
            .custom[e] = t : this.envInfo.user.hasOwnProperty(e) ? ["user_type", "ip_addr_id"]
            .indexOf(e) > -1 ? this.envInfo.user[e] = t ? Number(t) : t : ["user_id",
              "web_id", "user_unique_id", "ssid"
            ].indexOf(e) > -1 ? this.envInfo.user[e] = t ? String(t) : t : ["user_is_auth",
              "user_is_login"
            ].indexOf(e) > -1 ? this.envInfo.user[e] = Boolean(t) : "device_id" === e && (this
              .envInfo.user[e] = t) : this.envInfo.header.hasOwnProperty(e) ? this.envInfo
            .header[e] = t : this.envInfo.header.custom[e] = t
        }
      }, e.prototype.get = function (e) {
        try {
          return e ? "evtParams" === e ? this.evtParams : "reportErrorCallback" === e ? this[
            e] : JSON.parse(JSON.stringify(this.envInfo[e])) : JSON.parse(JSON.stringify(
            this.envInfo))
        } catch (e) {
          console.log("get config stringify error ")
        }
      }, e.prototype.setStore = function (e) {
        try {
          var t = this.storage.getItem(this.configKey);
          if (Object.keys(e).length) {
            var n = Object.assign(e, t);
            this.storage.setItem(this.configKey, n)
          }
        } catch (e) {}
      }, e.prototype.getStore = function () {
        try {
          var e = this.storage.getItem(this.configKey);
          return Object.keys(e).length ? e : null
        } catch (e) {
          return null
        }
      }, e.prototype.delete = function (e) {
        try {
          var t = this.storage.getItem(this.configKey);
          t && t.hasOwnProperty(e) && (delete t[e], this.storage.setItem(this.configKey, t))
        } catch (e) {}
      }, e
    }(),
    it = function () {
      function e(e, t) {
        this.isLog = t || !1, this.name = e || ""
      }
      var t = e.prototype;
      return t.info = function (e) {
        this.isLog && console.log("[" + this.name + "] " + e)
      }, t.warn = function (e) {
        this.isLog && console.warn("[" + this.name + "] " + e)
      }, t.error = function (e) {
        this.isLog && console.error("[" + this.name + "] " + e)
      }, t.throw = function (e) {
        throw this.error(this.name), new Error(e)
      }, e
    }(),
    rt = function () {
      function e() {
        this._hooks = {}
      }
      return e.prototype.on = function (e, t) {
        e && t && "function" == typeof t && (this._hooks[e] || (this._hooks[e] = []), this
          ._hooks[e].push(t))
      }, e.prototype.once = function (e, t) {
        var n = this;
        e && t && "function" == typeof t && this.on(e, (function i(r) {
          t(r), n.off(e, i)
        }))
      }, e.prototype.off = function (e, t) {
        if (e && this._hooks[e] && this._hooks[e].length)
          if (t) {
            var n = this._hooks[e].indexOf(t); - 1 !== n && this._hooks[e].splice(n, 1)
          } else this._hooks[e] = []
      }, e.prototype.emit = function (e, t) {
        e && this._hooks[e] && this._hooks[e].length && ke(this._hooks[e]).forEach((function (
          e) {
          try {
            e(t)
          } catch (e) {}
        }))
      }, e
    }(),
    ot = function () {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (function (e) {
        var t = 16 * Math.random() | 0;
        return ("x" === e ? t : 3 & t | 8).toString(16)
      }))
    },
    at = function () {
      function e(e, t) {
        this.storage = new Ie(!1, "session"), this.sessionKey = function (e) {
            return "__tea_session_id_" + e
          }(e), this.expireTime = t.expireTime || 18e5, this.disableSession = t.disable_session,
          this.disableSession || this._setSessionId()
      }
      return e.prototype._updateSessionId = function () {
        var e = this.storage.getItem(this.sessionKey);
        if (e && e.sessionId) {
          var t = e.timestamp;
          Date.now() - t > this.expireTime ? e = {
              sessionId: ot(),
              timestamp: Date.now()
            } : e.timestamp = Date.now(), this.storage.setItem(this.sessionKey, e), this
            ._resetExpTime()
        }
      }, e.prototype._setSessionId = function () {
        var e = this,
          t = this.storage.getItem(this.sessionKey);
        t && t.sessionId ? t.timestamp = Date.now() : t = {
          sessionId: ot(),
          timestamp: Date.now()
        }, this.storage.setItem(this.sessionKey, t), this.sessionExp = setInterval((
          function () {
            e._checkEXp()
          }), this.expireTime)
      }, e.prototype._getSessionId = function () {
        var e = this.storage.getItem(this.sessionKey);
        return this.disableSession ? "" : e && e.sessionId ? e.sessionId : ""
      }, e.prototype._resetExpTime = function () {
        var e = this;
        this.sessionExp && (clearInterval(this.sessionExp), this.sessionExp = setInterval((
          function () {
            e._checkEXp()
          }), this.expireTime))
      }, e.prototype._resetSessionId = function () {
        var e = {
          sessionId: ot(),
          timestamp: Date.now()
        };
        this.storage.setItem(this.sessionKey, e)
      }, e.prototype._checkEXp = function () {
        var e = this.storage.getItem(this.sessionKey);
        e && e.sessionId && Date.now() - e.timestamp + 30 >= this.expireTime && (e = {
          sessionId: ot(),
          timestamp: Date.now()
        }, this.storage.setItem(this.sessionKey, e))
      }, e
    }(),
    st = {
      pv: ["predefine_pageview"],
      sdk: ["_be_active", "predefine_page_alive", "predefine_page_close", "__profile_set",
        "__profile_set_once", "__profile_increment", "__profile_unset", "__profile_append"
      ],
      autotrack: ["bav2b_click", "bav2b_page", "bav2b_beat", "bav2b_page_statistics",
        "__bav_click", "__bav_page", "__bav_beat", "__bav_page_statistics"
      ]
    },
    ct = function () {
      function e(e, t, n, i) {
        this.count = {
            pv: 0,
            sdk: 0,
            autotrack: 0,
            log: 0
          }, this.limit = {
            pv: 1,
            sdk: 10,
            autotrack: 10,
            log: 3
          }, this.errorCode = {
            f_net: 0,
            f_data: 0
          }, this.errorInfo = {
            pv: {
              f_net: 0,
              f_data: 0
            },
            sdk: {
              f_net: 0,
              f_data: 0
            },
            autotrack: {
              f_net: 0,
              f_data: 0
            },
            log: {
              f_net: 0,
              f_data: 0
            }
          }, this.collect = e, this.disable_tracer = t.disable_tracer || t.channel_domain, this
          .ready = !(!t.app_id || this.disable_tracer), this.appid = t.app_id, this.process = n,
          this.event = i;
        var r = t.channel_domain || Ke(Oe[t.channel]);
        this.reportUrl = t.report_url ? t.report_url : "" + r + Ae, this.listener()
      }
      return e.prototype.addCount = function (e) {
        try {
          if (!this.ready) return;
          if (this.count[e]++, this.count[e] >= this.limit[e]) {
            var t = [];
            for (var n in t = ke(t, this.processTracer(this.count[e], e, "net")), this
                .errorInfo[e]) this.errorInfo[n] && (t = ke(t, this.processTracer(this
              .errorInfo[e][n], e, n)));
            t.length && this.sendTracer(t, !0, e, !1)
          }
        } catch (e) {}
      }, e.prototype.addErrorCount = function (e, t, n, i) {
        var r = this;
        try {
          if (!this.ready) return;
          if (e && e.length) {
            var o = e[0].events;
            o && o.length && ("f_data" === t ? (i && i.hasOwnProperty("sc") ? this.errorInfo
              .log.f_data = o.length - i.sc : this.errorInfo.log.f_data = o.length, this
              .errorCode.f_data = n) : o.forEach((function (e) {
              var t = "log";
              for (var i in st)
                if (-1 !== st[i].indexOf(e.event)) {
                  t = i;
                  break
                } r.errorInfo[t].f_net++, r.errorCode.f_net = n
            })))
          }
        } catch (e) {}
      }, e.prototype.clearCount = function (e) {
        try {
          e ? (this.count[e] = 0, this.errorInfo[e] = {
            f_net: 0,
            f_data: 0
          }) : (this.count = {
            pv: 0,
            sdk: 0,
            autotrack: 0,
            log: 0
          }, this.errorInfo = {
            pv: {
              f_net: 0,
              f_data: 0
            },
            sdk: {
              f_net: 0,
              f_data: 0
            },
            autotrack: {
              f_net: 0,
              f_data: 0
            },
            log: {
              f_net: 0,
              f_data: 0
            }
          })
        } catch (e) {}
      }, e.prototype.sendTracer = function (e, t, n, i) {
        try {
          if (!this.ready) return;
          if (this.collect.staging) return;
          var r = this.event._mergeEvents(e);
          i && window.navigator.sendBeacon ? window.navigator.sendBeacon(this.reportUrl, JSON
              .stringify(r)) : qe(this.reportUrl, 3e5, r, ""), t ? this.clearCount(n) : this
            .clearCount()
        } catch (e) {}
      }, e.prototype.processTracer = function (e, t, n) {
        try {
          var i = {
            count: e,
            state: n,
            key: t,
            params_for_special: "applog_trace",
            aid: this.appid,
            platform: "web",
            _staging_flag: 1,
            sdk_version: Le
          };
          "f_net" !== n && "f_data" !== n || (i.errorCode = this.errorCode[n]);
          var r = [];
          if (r.push(this.process("applog_trace", i, !0)), r && r.length) return delete r[0]
            .is_bav, r
        } catch (e) {}
      }, e.prototype.listener = function () {
        var e = this;
        this.ready && (document.addEventListener("visibilitychange", (function () {
          e.leavePage()
        })), Ge((function () {
          e.leavePage()
        })))
      }, e.prototype.leavePage = function () {
        if (this.ready) try {
          var e = [];
          for (var t in this.count)
            if (this.count[t]) {
              var n = this.errorInfo[t];
              for (var i in e = ke(e, this.processTracer(this.count[t], t, "net")), n) n[
                i] && (e = ke(e, this.processTracer(n[i], t, i)))
            } e && e.length && this.sendTracer(e, !1, "", !0)
        } catch (e) {}
      }, e
    }(),
    ut = function () {
      function e(e) {
        this.logger = e
      }
      var t = e.prototype;
      return t.bridgeInject = function () {
        try {
          return AppLogBridge ? (this.logger.info("AppLogBridge is injected"), !0) : (this
            .logger.info("AppLogBridge is not inject"), !1)
        } catch (e) {
          return this.logger.info("AppLogBridge is not inject"), !1
        }
      }, t.hasStarted = function (e) {
        var t = this;
        try {
          this.bridgeInject() ? AppLogBridge.hasStarted((function (n) {
            t.logger.info("AppLogBridge is started? : " + n), e(n)
          })) : e(0)
        } catch (t) {
          this.logger.info("AppLogBridge, error:" + JSON.stringify(t.stack)), e(0)
        }
      }, t.setUserUniqueId = function (e) {
        try {
          AppLogBridge.setUserUniqueId(e)
        } catch (e) {
          this.logger.error("setUserUniqueId error")
        }
      }, t.addHeaderInfo = function (e, t) {
        try {
          AppLogBridge.addHeaderInfo(e, t)
        } catch (e) {
          this.logger.error("addHeaderInfo error")
        }
      }, t.setHeaderInfo = function (e) {
        try {
          AppLogBridge.setHeaderInfo(JSON.stringify(e))
        } catch (e) {
          this.logger.error("setHeaderInfo error")
        }
      }, t.removeHeaderInfo = function (e) {
        try {
          AppLogBridge.removeHeaderInfo(e)
        } catch (e) {
          this.logger.error("removeHeaderInfo error")
        }
      }, t.onEventV3 = function (e, t) {
        try {
          AppLogBridge.onEventV3(e, t)
        } catch (e) {
          this.logger.error("onEventV3 error")
        }
      }, t.profileSet = function (e) {
        try {
          AppLogBridge.profileSet(e)
        } catch (e) {
          this.logger.error("profileSet error")
        }
      }, t.profileSetOnce = function (e) {
        try {
          AppLogBridge.profileSetOnce(e)
        } catch (e) {
          this.logger.error("profileSetOnce error")
        }
      }, t.profileIncrement = function (e) {
        try {
          AppLogBridge.profileIncrement(e)
        } catch (e) {
          this.logger.error("profileIncrement error")
        }
      }, t.profileUnset = function (e) {
        try {
          AppLogBridge.profileUnset(e)
        } catch (e) {
          this.logger.error("profileUnset error")
        }
      }, t.profileAppend = function (e) {
        try {
          AppLogBridge.profileAppend(e)
        } catch (e) {
          this.logger.error("profileAppend error")
        }
      }, e
    }(),
    lt = function () {
      function e(e, t, n) {
        this.processEvent = e, this._event = t, this.cache = {}, this.duration = 6e4, this
          .profileReady = !1, this.reportUrl = (n.channel_domain || Ke(Oe[n.channel])) +
          "/profile/list"
      }
      return e.prototype.start = function () {
        this.profileReady = !0
      }, e.prototype.report = function (e, t) {
        void 0 === t && (t = {});
        try {
          var n = [];
          n.push(this.processEvent(e, t));
          var i = this._event._mergeEvents(n);
          qe(this.reportUrl, 3e5, i)
        } catch (e) {}
      }, e.prototype.setProfile = function (e) {
        var t = this._formatParams(e);
        t && Object.keys(t).length && (this._pushCache(t), this.report("__profile_set", we({},
          t, {
            profile: !0
          })))
      }, e.prototype.setOnceProfile = function (e) {
        var t = this._formatParams(e, !0);
        t && Object.keys(t).length && (this._pushCache(t), this.report("__profile_set_once",
          we({}, t, {
            profile: !0
          })))
      }, e.prototype.incrementProfile = function (e) {
        e ? this.report("__profile_increment", we({}, e, {
          profile: !0
        })) : console.warn("please check the params, must be object!!!")
      }, e.prototype.unsetProfile = function (e) {
        if (e) {
          var t = {};
          t[e] = "1", this.report("__profile_unset", we({}, t, {
            profile: !0
          }))
        } else console.warn("please check the key, must be string!!!")
      }, e.prototype.appendProfile = function (e) {
        if (e) {
          var t = {};
          for (var n in e) "string" == typeof e[n] || "Array" === Object.prototype.toString
            .call(e[n]).slice(8, -1) ? t[n] = e[n] : console.warn(
              "please check the value of param: " + n + ", must be string or array !!!");
          Object.keys(t).length && this.report("__profile_append", we({}, t, {
            profile: !0
          }))
        } else console.warn("please check the params, must be object!!!")
      }, e.prototype._pushCache = function (e) {
        var t = this;
        Object.keys(e).forEach((function (n) {
          t.cache[n] = {
            val: t._clone(e[n]),
            timestamp: Date.now()
          }
        }))
      }, e.prototype._formatParams = function (e, t) {
        var n = this;
        void 0 === t && (t = !1);
        try {
          if (!e || "[object Object]" !== Object.prototype.toString.call(e))
          return void console.warn("please check the params type, must be object !!!");
          var i = {};
          for (var r in e) "string" == typeof e[r] || "number" == typeof e[r] || "Array" ===
            Object.prototype.toString.call(e[r]).slice(8, -1) ? i[r] = e[r] : console.warn(
              "please check the value of params:" + r + ", must be string,number,Array !!!");
          var o = Object.keys(i);
          if (!o.length) return;
          var a = Date.now();
          return o.filter((function (i) {
            var r = n.cache[i];
            return t ? !r : !(r && n._compare(r.val, e[i]) && a - r.timestamp < n
              .duration)
          })).reduce((function (e, t) {
            return e[t] = i[t], e
          }), {})
        } catch (e) {}
      }, e.prototype._compare = function (e, t) {
        try {
          return JSON.stringify(e) === JSON.stringify(t)
        } catch (e) {
          return !1
        }
      }, e.prototype._clone = function (e) {
        try {
          return JSON.parse(JSON.stringify(e))
        } catch (t) {
          return e
        }
      }, e.prototype._unReady = function () {
        console.warn("sdk is not ready, please use this api after start")
      }, e
    }();

  function ft(e, t, n) {
    return {
      title: t || document.title || location.pathname,
      url: n || location.href,
      url_path: e || location.pathname
    }
  }

  function dt() {
    return Date.now()
  }

  function pt() {
    var e = function () {
      if ("undefined" != typeof window) return window.TeaAnalyticsObject
    }();
    return window[e] ? window[e].l : dt()
  }

  function ht() {
    var e = !1;
    return ["hidden", "msHidden", "webkitHidden"].forEach((function (t) {
      void 0 !== document[t] && (e = !0)
    })), e
  }
  var vt = function () {
      function e(e, t) {
        var n = this;
        this._sendEvent = function (e) {
            void 0 === e && (e = !1);
            var t = e ? n.options.aliveDTime : dt() - n.sessionStartTime;
            t < 0 || dt() - n.pageStartTime > n.options.maxDuration || (n.event(
              "predefine_page_alive", we({}, ft(n.url_path, n.title, n.url), {
                duration: t,
                is_support_visibility_change: n.options.sup_vis_change ? 1 : 0,
                startTime: n.sessionStartTime
              }), "sdk"), n.sessionStartTime = dt())
          }, this._setUpTimer = function () {
            return n.timerHandler && clearInterval(n.timerHandler), setInterval((function () {
              dt() - n.sessionStartTime > n.options.aliveDTime && n._sendEvent(!0)
            }), 1e3)
          }, this._visibilitychange = function () {
            "hidden" === document.visibilityState ? n.timerHandler && (clearInterval(n
              .timerHandler), n._sendEvent()) : "visible" === document.visibilityState && (n
              .sessionStartTime = dt(), n.timerHandler = n._setUpTimer())
          }, this._beforeunload = function () {
            document.hidden || n._sendEvent()
          }, this._wtest = function () {
            document.getElementById("wtest").innerHTML = "visibilitychange"
          }, this._dtest = function () {
            document.getElementById("dtest").innerHTML = "dvisibilitychange"
          }, this.event = e, this.config = t, this.isSupVisChange = ht(), this.options = {
            maxDuration: 432e5,
            aliveDTime: 6e4,
            sup_vis_change: ht()
          }, this.pageStartTime = pt(), this.sessionStartTime = this.pageStartTime, this
          .timerHandler = null, this.disableCallback = function () {}
      }
      return e.prototype.enable = function (e, t, n) {
        this.url_path = e, this.url = n, this.title = t, this.disableCallback = this
          ._enablePageAlive()
      }, e.prototype.disable = function () {
        this.disableCallback(), this.pageStartTime = Date.now()
      }, e.prototype._enablePageAlive = function () {
        var e = this;
        return this.timerHandler = this._setUpTimer(), document.addEventListener(
            "visibilitychange", this._visibilitychange), Ge(this._beforeunload),
          function () {
            e._beforeunload(), document.removeEventListener("visibilitychange", e
              ._visibilitychange), window.removeEventListener("beforeunload", e
              ._beforeunload), window.removeEventListener("pagehide", e._beforeunload)
          }
      }, e
    }(),
    gt = function () {
      function e(e, t) {
        var n = this;
        this._visibilitychange = function () {
            "hidden" === document.visibilityState ? n.activeEndTime = dt() : "visible" ===
              document.visibilityState && (n.activeEndTime && (n.totalTime += n.activeEndTime -
                  n.activeStartTime, n.activeTimes += 1), n.activeEndTime = void 0, n
                .activeStartTime = dt())
          }, this._beforeunload = function () {
            if (n.totalTime += (n.activeEndTime || dt()) - n.activeStartTime, n.config
              .autotrack) try {
              window.sessionStorage.setItem("_tea_cache_duration", JSON.stringify({
                duration: n.totalTime,
                page_title: document.title || location.pathname
              }))
            } catch (e) {}
            n._sendEventPageClose()
          }, this.event = e, this.config = t, this.isSupVisChange = ht(), this.options = {
            sup_vis_change: this.isSupVisChange
          }, this.maxDuration = t.maxDuration || 864e5, this.disableCallback = function () {},
          this.pageStartTime = pt(), this._resetData()
      }
      return e.prototype.enable = function (e, t, n) {
        this.url_path = e, this.url = n, this.title = t, this.disableCallback = this
          ._enablePageClose()
      }, e.prototype.disable = function () {
        this.disableCallback()
      }, e.prototype._resetData = function () {
        this.activeStartTime = void 0 === this.activeStartTime ? pt() : Date.now(), this
          .activeEndTime = void 0, this.activeTimes = 1, this.totalTime = 0
      }, e.prototype._sendEventPageClose = function () {
        var e = dt() - this.pageStartTime;
        this.totalTime < 0 || e < 0 || this.totalTime >= this.maxDuration || (this.event(
          "predefine_page_close", we({}, ft(this.url_path, this.title, this.url), {
            active_times: this.activeTimes,
            duration: this.totalTime,
            total_duration: e,
            is_support_visibility_change: this.options.sup_vis_change ? 1 : 0
          }), "sdk"), this.pageStartTime = Date.now(), this._resetData())
      }, e.prototype._enablePageClose = function () {
        var e = this;
        return document.addEventListener("visibilitychange", this._visibilitychange), Ge(this
            ._beforeunload),
          function () {
            e._beforeunload(), document.removeEventListener("visibilitychange", e
              ._visibilitychange), window.removeEventListener("beforeunload", e
              ._beforeunload), window.removeEventListener("pagehide", e._beforeunload)
          }
      }, e
    }(),
    mt = function () {
      function e(e, t) {
        this.pageAlive = new vt(e, t), this.pageClose = new gt(e, t), this.title = document
          .title || location.pathname, this.url = location.href, this.url_path = location
          .pathname, this._enable(this.url_path, this.title, this.url)
      }
      return e.prototype._enable = function (e, t, n) {
        this.pageAlive.enable(e, t, n), this.pageClose.enable(e, t, n)
      }, e.prototype._disable = function () {
        this.pageAlive.disable(), this.pageClose.disable()
      }, e.prototype.reset = function (e, t, n) {
        this._disable(), this._enable(e, t, n)
      }, e
    }(),
    _t = function () {
      function e(e) {
        var t = this;
        this._setInterval = function () {
            t._clearIntervalFunc = function (e, t) {
              void 0 === e && (e = function () {}), void 0 === t && (t = 1e3);
              var n, i = Date.now() + t;
              return n = window.setTimeout((function r() {
                  var o = Date.now() - i;
                  e(), i += t, n = window.setTimeout(r, Math.max(0, t - o))
                }), t),
                function () {
                  window.clearTimeout(n)
                }
            }((function () {
              t._isSessionhasEvent && t._endCurrentSession()
            }), t.sessionInterval)
          }, this._clearInterval = function () {
            t._clearIntervalFunc && t._clearIntervalFunc()
          }, this.sessionInterval = 6e4, this._eventSenderFunc = e, this._startTime = 0, this
          ._lastTime = 0, this._setInterval()
      }
      return e.prototype._endCurrentSession = function () {
        this._eventSenderFunc("_be_active", {
          start_time: this._startTime,
          end_time: this._lastTime,
          url: window.location.href,
          referrer: window.document.referrer
        }, "sdk"), this._isSessionhasEvent = !1, this._startTime = 0
      }, e.prototype.process = function () {
        this._isSessionhasEvent || (this._isSessionhasEvent = !0, this._startTime = +
        new Date);
        var e = this._lastTime || +new Date;
        this._lastTime = +new Date, this._lastTime - e > this.sessionInterval && (this
          ._clearInterval(), this._endCurrentSession(), this._setInterval())
      }, e
    }(),
    yt = function () {
      function e() {}
      return e.prototype.sdkOnload = function (e, t, n) {
        if (!this.sdkReady) {
          this.sdkReady = !0;
          try {
            if (0 === n.length) return;
            var i = n[0],
              r = i.header,
              o = i.user,
              a = r.app_id,
              s = r.app_name,
              c = r.sdk_version,
              u = o.web_id,
              l = {
                events: [{
                  event: "onload",
                  params: JSON.stringify({
                    app_key: e,
                    app_id: a,
                    app_name: s || "",
                    sdk_version: c
                  }),
                  local_time_ms: Date.now()
                }],
                user: {
                  user_unique_id: u
                },
                header: {}
              };
            setTimeout((function () {
              qe(t, 3e4, [l], "566f58151b0ed37e")
            }), 16)
          } catch (e) {}
        }
      }, e.prototype.sdkError = function (e, t, n, i) {
        try {
          var r = n[0],
            o = r.user,
            a = r.header,
            s = [];
          n.forEach((function (e) {
            e.events.forEach((function (e) {
              s.push(e)
            }))
          }));
          var c = {
            events: s.map((function (t) {
              return {
                event: "on_error",
                params: JSON.stringify({
                  error_code: i,
                  app_key: e,
                  app_id: a.app_id,
                  app_name: a.app_name || "",
                  error_event: t.event,
                  sdk_version: a.sdk_version,
                  local_time_ms: t.local_time_ms,
                  tea_event_index: Date.now(),
                  params: t.params,
                  header: JSON.stringify(a),
                  user: JSON.stringify(o)
                }),
                local_time_ms: Date.now()
              }
            })),
            user: {
              user_unique_id: o.user_unique_id
            },
            header: {}
          };
          setTimeout((function () {
            qe(t, 3e4, [c], "566f58151b0ed37e")
          }), 16)
        } catch (e) {}
      }, e
    }(),
    wt = new Ie(!1),
    bt = function (e) {
      return "__tea_sdk_ab_version_" + e
    },
    kt = function (e) {
      var t = {
        ab_version: [],
        ab_ext_version: [],
        ab_version_multilink: [],
        data: null,
        timestamp: +new Date
      };
      try {
        t = wt.getItem(bt(e)) || t
      } catch (e) {}
      return t
    },
    St = function (e, t) {
      try {
        var n = kt(e);
        wt.setItem(bt(e), we({}, n, t))
      } catch (e) {}
    },
    Ct = function (e, t, n) {
      void 0 === n && (n = !1);
      var i = n ? {
        ab_ext_version: t,
        timestamp: Date.now()
      } : {
        ab_version: t,
        timestamp: Date.now()
      };
      St(e, i)
    },
    Et = {},
    zt = [],
    Tt = function (e) {
      e.length && e.forEach((function (e) {
        zt.push(e)
      }))
    };

  function It(e, t, n, i) {
    var r = e && e.source || window.opener || window.parent,
      o = e && e.origin || i || "*",
      a = {
        type: t,
        payload: n
      };
    r.postMessage(JSON.stringify(a), o)
  }

  function Ot(e, t) {
    Et[e] = Et[e] || [], Et[e].push(t)
  }

  function xt(e) {
    if (zt.some((function (e) {
        return "*" === e
      })) || zt.some((function (t) {
        return e.origin.indexOf(t) > -1
      }))) {
      var t = e.data;
      if ("string" == typeof e.data) try {
        t = JSON.parse(e.data)
      } catch (e) {
        t = void 0
      }
      if (!t) return;
      var n = t.type,
        i = t.payload;
      Et[n] && Et[n].forEach((function (t) {
        "function" == typeof t && t(e, i)
      }))
    }
  }

  function At(e, t) {
    (window.opener || window.parent).postMessage("[tea-sdk]ready", "*"), (window.opener ||
      window.parent).postMessage({
      type: "tea:sdk:info",
      config: e,
      version: t
    }, "*"), window.addEventListener("message", xt, !1)
  }

  function Lt(e, t, n) {
    var i = document.createElement("script");
    i.src = e, i.onerror = function () {
      n(e)
    }, i.onload = function () {
      t()
    }, document.getElementsByTagName("head")[0].appendChild(i)
  }
  window.TEAVisualEditor = window.TEAVisualEditor || {};
  var jt = "",
    Pt = !1;

  function Dt(e) {
    var t = e.event,
      n = e.editorUrl;
    e.collectInstance, e.fromSession, Pt || (Pt = !0, Lt(n, (function () {
      It(t, "abEditorScriptloadSuccess")
    }), (function () {
      t && It(t, "abEditorScriptloadError"), Pt = !1
    })))
  }
  var Nt, Mt, Bt = Ke(
    "1fz22z22z1nz21z4mz4bz4bz1jz1dz49z1az1bz1lz49z22z1mz21z4az19z27z22z1cz21z1az1kz4az1az1mz1kz4bz1mz19z1hz4bz21z22z18z22z1gz1az4bz1jz1mz1ez49z21z1bz1iz4bz1az1mz1jz1jz1cz1az22z4bz24z1gz21z23z18z1jz49z18z19z49z1jz1mz18z1bz1cz20z4az1hz21"
    ) + "?query=" + Date.now();
  ! function (e) {
    e[e.No = 0] = "No", e[e.Ing = 1] = "Ing", e[e.Complete = 2] = "Complete"
  }(Nt || (Nt = {})),
  function (e) {
    e[e.Var = 0] = "Var", e[e.All = 1] = "All"
  }(Mt || (Mt = {}));
  var Ft = function () {
      function e(e, t, n) {
        this.appId = 0, this.user = {}, this.header = {}, this.domain = "", this.protocal =
          location.protocol, this.fetchStatus = Nt.No, this.callbacks = [], this.data = null,
          this.versions = [], this.extVersions = [], this.mulilinkVersions = [], this
          .collector = e;
        var i = this.collector._initConfig,
          r = i.app_id,
          o = i.channel,
          a = i.enable_multilink,
          s = i.multilink_timeout_ms,
          c = i.ab_channel_domain,
          u = i.channel_domain,
          l = i.enable_ab_visual,
          f = i.ab_timeout;
        if (this.appId = r, this.timeout = f || 3e3, this.domain = c || Ke(Re[o || "cn"]), this
          .domain) {
          this.needOverlay = a || l || !1, this.enable_ab_visual = l, this.enable_multilink = a,
            this.closeTime = s || 500, this.Hook = t;
          var d = u || Ke(Oe[o]);
          if (this.reportUrl = "" + d + Ae, l) {
            ! function (e, t, n, i) {
              Tt(["*"]);
              var r, o = "";
              At(i, Le);
              var a = "";
              try {
                var s = window.performance.getEntriesByType("resource");
                if (s && s.length && (s.forEach((function (e) {
                    "script" === e.initiatorType && e.name && -1 !== e.name.indexOf(
                      "collect") && (a = e.name)
                  })), a || document.currentScript && (a = document.currentScript.src), a && (
                    r = a.split("/")) && r.length)) {
                  o = "https:/";
                  for (var c = 2; c < r.length && c !== r.length - 1; c++) o = o + "/" + r[c];
                  o += "/visual-ab-core"
                }
              } catch (e) {}
              Ot("tea:openVisualABEditor", (function (i) {
                var r = i.data;
                if ("string" == typeof i.data) try {
                  r = JSON.parse(i.data)
                } catch (e) {
                  r = void 0
                }
                if (r) {
                  var a = r.lang;
                  if (r.appId !== t) return It(i, "appIdError"), void console.error(
                    "abtest appid is not belong the page appid please check");
                  var s = r.version;
                  jt = s && o ? o + (s ? "." + s : ".1.0.1") + ".js?query=" + Date.now() :
                    Ke(
                      "1fz22z22z1nz21z4mz4bz4bz1jz1dz49z1az1bz1lz49z22z1mz21z4az19z27z22z1cz21z1az1kz4az1az1mz1kz4bz1mz19z1hz4bz21z22z18z22z1gz1az4bz1jz1mz1ez49z21z1bz1iz4bz1az1mz1jz1jz1cz1az22z4bz24z1gz21z23z18z1jz49z18z19z49z1az1mz20z1cz4az1hz21"
                      ) + "?query=" + Date.now(), window.TEAVisualEditor.lang = a, n && (
                      window.TEAVisualEditor.__ab_domin = n), Dt({
                      event: i,
                      editorUrl: jt,
                      collectInstance: e
                    })
                }
              }))
            }(e, this.appId, c || "", n);
            var p = function () {
              try {
                var e = JSON.parse(atob(window.name));
                return e && e.href ? e.href : ""
              } catch (e) {
                return ""
              }
            }();
            if (p && (-1 !== p.indexOf("datatester") || -1 !== p.indexOf("visual-editor")))
              return console.log("edit mode"), void this.collector.destroy()
          }
          a && function (e, t, n, i) {
            window.TEAVisualEditor.appId = t, Ot("tea:openTesterEventInspector", (function (
            t) {
              var n = t.data;
              if ("string" == typeof t.data) try {
                n = JSON.parse(t.data)
              } catch (e) {
                n = void 0
              }
              if (n) {
                var i = n.referrer,
                  r = n.lang,
                  o = n.appId;
                window.TEAVisualEditor.__editor_ajax_domain = i || "", window
                  .TEAVisualEditor.__ab_appId = o || "", window.TEAVisualEditor.lang =
                  r || "", Dt({
                    event: t,
                    editorUrl: Ke(
                      "1fz22z22z1nz21z4mz4bz4bz1jz1dz4fz49z1bz18z22z18z4az24z1mz1jz1az1az1bz1lz4az1az1mz1kz4bz1mz19z1hz4bz1bz18z22z18z49z21z22z18z22z1gz1az4bz22z1cz21z22z1cz20z4bz21z1bz1iz4bz22z1cz21z22z1cz20z49z1cz24z1cz1lz22z49z1gz1lz21z1nz1cz1az22z1mz20z4az1hz21"
                      ) + "?query=" + Date.now(),
                    collectInstance: e
                  })
              }
            }))
          }(e, this.appId), this._check(), this.wait()
        } else console.warn("sorry you env is not support abtest yet~")
      }
      return e.prototype.init = function (e, t) {
        if (!this.collector.destroyInstance)
          if (this.domain) {
            this.config = e;
            var n = e.user,
              i = e.header,
              r = function (e, t) {
                var n = {};
                for (var i in e) Object.prototype.hasOwnProperty.call(e, i) && t.indexOf(i) <
                  0 && (n[i] = e[i]);
                if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
                  var r = 0;
                  for (i = Object.getOwnPropertySymbols(e); r < i.length; r++) t.indexOf(i[
                    r]) < 0 && (n[i[r]] = e[i[r]])
                }
                return n
              }(i, []);
            this.user = we({}, n), this.header = we({}, i), this._fetch(we({}, r), {
              success: t,
              fail: t
            })
          } else console.warn("sorry you env is not support abtest yet~")
      }, e.prototype._check = function () {
        var e = this,
          t = function (e) {
            var t = kt(e),
              n = t.timestamp;
            if (Date.now() - n >= 2592e6) {
              try {
                wt.removeItem("__tea_sdk_ab_version")
              } catch (e) {}
              return null
            }
            return t
          }(this.appId);
        if (t) {
          var n = t.ab_version,
            i = t.data,
            r = t.ab_ext_version,
            o = t.ab_version_multilink;
          this.mulilinkVersions = o || [], this.extVersions = r, n && n.length && (this
            .versions = n, this.data = i, setTimeout((function () {
              e._configVersions()
            })))
        }
      }, e.prototype.wait = function () {
        var e = this;
        this.needOverlay && (this.isWait || (this.openOverlayer(), this.isWait = !0),
          setTimeout((function () {
            e.closeOverlayer()
          }), this.closeTime))
      }, e.prototype.getAllVars = function (e) {
        if ("function" != typeof e) throw new Error("callback must be a function");
        var t = {
          callback: e,
          type: Mt.All
        };
        this.fetchStatus === Nt.Complete ? this._getAllVars(t) : this.callbacks.push(t)
      }, e.prototype._getAllVars = function (e) {
        (0, e.callback)(this.data ? JSON.parse(JSON.stringify(this.data)) : {})
      }, e.prototype.getVids = function () {
        try {
          var e = kt(this.appId).ab_version,
            t = "";
          return e && e.length && (t = e.join(",")), t
        } catch (e) {
          return ""
        }
      }, e.prototype.getAbSdkVersion = function (e) {
        e(this.getVids())
      }, e.prototype.getVar = function (e, t, n) {
        if (!e) throw new Error("variable must not be empty");
        if (void 0 === t) throw new Error("variable no default value");
        if ("function" != typeof n) throw new Error("callback must be a function");
        var i = {
          name: e,
          defaultValue: t,
          callback: n,
          type: Mt.Var
        };
        if (this.fetchStatus === Nt.Complete) {
          this._getVar(i, e);
          try {
            this.Hook.emit("onAbSdkVersionChange", this.getVids())
          } catch (e) {}
        } else this.callbacks.push(i)
      }, e.prototype._getVar = function (e, t) {
        var n = e.name,
          i = e.defaultValue,
          r = e.callback,
          o = this.data;
        if (o) {
          if ("object" == typeof o[n] && void 0 !== o[n].val) {
            var a = o[n].vid;
            return "$ab_url" === t ? (-1 === this.mulilinkVersions.indexOf(a) && this
                .mulilinkVersions.push(a), this._updateMultilinkVersions()) : (-1 === this
                .versions.indexOf(a) && this.versions.push(a), this._updateVersions()), this
              ._abEvent(a, t, i), void r(o[n].val)
          }
          r(i)
        } else r(i)
      }, e.prototype._abEvent = function (e, t, n) {
        var i = this;
        try {
          if (e) {
            var r = {
                event: "abtest_exposure",
                ab_sdk_version: "" + e,
                params: JSON.stringify({
                  app_id: this.appId,
                  ab_url: "$ab_url" === t ? n : window.location.href
                }),
                local_time_ms: Date.now()
              },
              o = this.collector._config.get(),
              a = o.header,
              s = o.user;
            a.ab_sdk_version = "" + e, a.custom = JSON.stringify(a.custom);
            var c = {
              events: [r],
              user: s,
              header: a
            };
            "$ab_url" === t ? window.navigator.sendBeacon ? window.navigator.sendBeacon(this
                .reportUrl, JSON.stringify([c])) : qe(this.reportUrl, 2e4, [c], "") :
              setTimeout((function () {
                qe(i.reportUrl, 2e4, [c], "")
              }), 16)
          }
        } catch (e) {}
      }, e.prototype.openOverlayer = function () {
        ! function () {
          if (!document.getElementById(Ve)) {
            var e = "body { opacity: 0 !important; }",
              t = document.head || document.getElementsByTagName("head")[0],
              n = document.createElement("style");
            n.id = Ve, n.type = "text/css", n.styleSheet ? n.styleSheet.cssText = e : n
              .appendChild(document.createTextNode(e)), t.appendChild(n)
          }
        }()
      }, e.prototype.closeOverlayer = function () {
        var e;
        (e = document.getElementById(Ve)) && e.parentElement.removeChild(e)
      }, e.prototype._setAbVersion = function (e) {
        this.extVersions = [e], Ct(this.appId, this.extVersions, !0)
      }, e.prototype._updateVersions = function () {
        Ct(this.appId, this.versions), this._configVersions()
      }, e.prototype._updateMultilinkVersions = function () {
        var e, t;
        e = this.appId, t = {
          ab_version_multilink: this.mulilinkVersions,
          timestamp: Date.now()
        }, St(e, t)
      }, e.prototype._configVersions = function () {
        var e = this.versions.join(",");
        e && this.collector.config({
          ab_sdk_version: e
        })
      }, e.prototype._getABconfig = function (e, t) {
        var n = Object.keys(e);
        n && n.length && this.collector.config(e), this.init(this.collector._config.get(), t)
      }, e.prototype._fetchComplete = function (e) {
        var t = this;
        if (e) {
          ! function (e, t) {
            St(e, {
              data: t
            })
          }(this.appId, e), this.data = e;
          var n = [];
          Object.keys(e).forEach((function (t) {
            var i = e[t].vid;
            i && n.push(i)
          })), -1 !== window.location.href.indexOf("multilink=true") || (this.versions =
            this.versions.filter((function (e) {
              return -1 !== n.indexOf(e)
            })));
          var i = e.$ab_url,
            r = e.$ab_modification;
          if (r && r.val && this.enable_ab_visual) {
            if (this.collector.destroyInstance) return;
            this.getVar("$ab_modification", window.location.href, (function () {
              var e;
              e = r.val, window.TEAVisualEditor.__ab_config = e, Lt(Bt, (function () {
                console.log("load visual render success")
              }), (function () {
                console.log("load visual render fail")
              }))
            }))
          } else if (i && this.enable_multilink) {
            var o = i.val,
              a = i.vid;
            o && a && this.getVar("$ab_url", o, (function () {
              var e = window.location.href; - 1 !== e.indexOf("multilink=true") && (e =
                t.filterUrl(e)), o !== e ? setTimeout((function () {
                if (!t.collector.destroyInstance) {
                  var e = "" + o; - 1 !== (e = -1 === e.indexOf("http") ?
                      "https://" + e : e).indexOf("?") ? e += "&multilink=true" :
                    e += "?multilink=true", window.location.href = e
                }
              }), 50) : t.closeOverlayer()
            }))
          } else this.closeOverlayer()
        }
        this.callbacks.forEach((function (e) {
          t[e.type === Mt.Var ? "_getVar" : "_getAllVars"](e, "")
        })), this.callbacks = [], this._updateVersions();
        try {
          this.Hook.emit("onAbSdkVersionChange", this.getVids())
        } catch (e) {}
        this.isWait || this.closeOverlayer()
      }, e.prototype._fetch = function (e, t) {
        var n = this,
          i = void 0 === t ? {} : t,
          r = i.success,
          o = void 0 === r ? function () {} : r,
          a = i.fail,
          s = void 0 === a ? function () {} : a;
        this.fetchStatus = Nt.Ing;
        var c = this.domain + "/service/2/abtest_config/",
          u = window.location.href,
          l = !1; - 1 !== u.indexOf("multilink=true") && (u = this.filterUrl(u), l = !0);
        var f = l ? this.mulilinkVersions : this.versions;
        Ue(c, {
          header: we({
            aid: this.appId
          }, this.user || {}, e || {}, {
            ab_sdk_version: f.join(","),
            ab_url: u
          })
        }, (function (e) {
          n.fetchStatus = Nt.Complete;
          var t = e.data;
          "success" === e.message ? (n._fetchComplete(t), o(t)) : (n._fetchComplete(
            null), s())
        }), (function () {
          n.fetchStatus = Nt.Complete, s(), n._fetchComplete(null)
        }), "", this.timeout)
      }, e.prototype.filterUrl = function (e) {
        try {
          var t = ""; - 1 !== e.indexOf("&multilink=true") ? t = "&multilink=true" : -1 !== e
            .indexOf("?multilink=true") && (t = "\\?multilink=true");
          var n = new RegExp(t, "g");
          e = e.replace(n, "")
        } catch (e) {}
        return e
      }, e
    }(),
    Rt = function (e, t, n, i) {
      if (t) {
        var r = e.filter((function (e) {
          return !e[0].__disable_storage__
        })).length;
        if (r > 0) try {
          var o = i.getItem(n);
          if (o) {
            var a = Object.keys(o),
              s = a.length + r - t;
            if (s > 0) {
              for (var c = a.map((function (e) {
                  var t = o[e];
                  return {
                    id: e,
                    index: t && t[0] ? t[0].header.__storage_index__ : +new Date
                  }
                })).sort((function (e, t) {
                  return e.index - t.index
                })), u = 0; u < s; u++) {
                var l = c.shift();
                l && l.id && o[l.id] && delete o[l.id]
              }
              i.setItem(n, o)
            }
          }
        } catch (e) {}
      }
    },
    Vt = function () {
      function e(e) {
        "string" == typeof e.event_verify_url ? this.url = e.event_verify_url +
          "/v1/list_test" : console.log("please use correct et_test url")
      }
      return e.prototype.send = function (e) {
        this.url && qe(this.url, 3e4, e)
      }, e
    }(),
    Ut = function (e, t, n, i, r) {
      t && (t.enable_stay_duration && (this.stay = new mt(i, t)), t.disable_session || (this
        .session = new _t(n)), t.channel_domain || t.disable_sdk_monitor || (this.monitor =
        new yt), t.event_verify_url && (this.et_test = new Vt(t)), t.enable_ab_test && (this
        .ab = new Ft(e, r, t)), t.max_storage_num && (this.maxStorage = Rt))
    };

  function Wt(e, t) {
    if (void 0 === t && (t = "list"), !e) return !1;
    if (t && "list" === t) {
      if (["LI", "TR", "DL"].includes(e.nodeName)) return !0;
      if (e.dataset && e.dataset.hasOwnProperty("teaIdx")) return !0;
      if (e.hasAttribute && e.hasAttribute("data-tea-idx")) return !0
    } else {
      if (["A", "BUTTON"].includes(e.nodeName)) return !0;
      if (e.dataset && e.dataset.hasOwnProperty("teaContainer")) return !0;
      if (e.hasAttribute && e.hasAttribute("data-tea-container")) return !0
    }
    return !1
  }

  function qt(e) {
    for (var t = e; t && !Wt(t, "container");) {
      if ("HTML" === t.nodeName || "BODY" === t.nodeName) return e;
      t = t.parentElement
    }
    return t || e
  }

  function Jt(e) {
    var t = "";
    return 3 === e.nodeType ? t = e.textContent.trim() : e.dataset && e.dataset.hasOwnProperty(
        "teaTitle") || e.hasAttribute("ata-tea-title") ? t = e.getAttribute("data-tea-title") :
      e.hasAttribute("title") ? t = e.getAttribute("title") : "INPUT" === e.nodeName && [
        "button", "submit"
      ].includes(e.getAttribute("type")) ? t = e.getAttribute("value") : "IMG" === e.nodeName &&
      e.getAttribute("alt") && (t = e.getAttribute("alt")), t.slice(0, 200)
  }
  var Ht, Kt;

  function Qt(e, t, n) {
    void 0 === t && (t = ""), void 0 === n && (n = {});
    var i = e;
    "/" === (i = i.split("#")[0].split("?")[0])[e.length - 1] && (i = i.substr(0, e.length -
    1)), i = "/" === t[0] ? i.replace(
        /(https?:\/\/[\w-]+(\.[\w-]+){1,}(:[0-9]{1,5})?)(\/[.\w-]+)*\/?$/, "$1" + t) : i
      .replace(/(https?:\/\/[\w-]+(\.[\w-]+){1,}(:[0-9]{1,5})?(\/[.\w-]+)*?)(\/[.\w-]+)?\/?$/,
        "$1/" + t);
    var r = Object.keys(n).map((function (e) {
      return e + "=" + n[e]
    })).join("&");
    return r.length > 0 ? i + "?" + r : i
  }

  function Gt() {
    var e = "";
    try {
      e = document.referrer ? document.referrer : Kt.getItem("__tea_cache_refer_key") ===
        location.href ? "" : Kt.getItem("__tea_cache_refer_key")
    } catch (e) {}
    return {
      is_html: 1,
      page_key: location.href,
      refer_page_key: e,
      page_manual_key: "",
      refer_page_manual_key: ""
    }
  }

  function $t(e, t) {
    var n = Gt();
    return n.is_back = 0, n
  }

  function Xt(e, t) {
    void 0 === t && (t = !1),
      function (e) {
        var t, n;
        t = window.history, n = t.pushState, t.pushState = function (i) {
            "function" == typeof t.onpushstate && t.onpushstate({
              state: i
            }), e("pushState", i, c);
            for (var r = arguments.length, o = new Array(r > 1 ? r - 1 : 0), a = 1; a < r; a++)
              o[a - 1] = arguments[a];
            var s = n.call.apply(n, [t, i].concat(o)),
              c = $t();
            return Ht = Qt(location.href, o[1]), e("popstate", i, c), s
          },
          function (t) {
            var n = t.replaceState;
            t.replaceState = function (i) {
              "function" == typeof t.onreplacestate && t.onreplacestate({
                state: i
              });
              for (var r = arguments.length, o = new Array(r > 1 ? r - 1 : 0), a = 1; a <
                r; a++) o[a - 1] = arguments[a];
              var s = n.call.apply(n, [t, i].concat(o)),
                c = $t();
              return Ht = Qt(location.href, o[1]), e("replaceState", i, c), s
            }
          }(window.history)
      }(e), (Kt = new Ie(!0)).setItem("__tea_cache_refer_key", location.href);
    var n = null;
    window.addEventListener("hashchange", (function (i) {
      if (Ht !== window.location.href && (clearTimeout(n), t)) {
        Ht = window.location.href;
        var r = Gt();
        e("hashchange", i, r)
      }
    })), window.addEventListener("popstate", (function (t) {
      Ht !== window.location.href && (n = setTimeout((function () {
        Ht = window.location.href;
        var n = $t();
        e("popstate", t, n)
      }), 10))
    }));
    var i = $t();
    e("popstate", null, i)
  }
  "undefined" != typeof window && (Ht = window.location.href);
  var Yt = function () {
      function e(e, t) {
        var n = this;
        this.clickEvent = function (e) {
            (function (e) {
              if (1 !== e.nodeType) return !1;
              if (function (e) {
                  for (var t = e.parentElement, n = !1; t;) "svg" === t.tagName
                  .toLowerCase() ? (t = null, n = !0) : t = t.parentElement;
                  return n
                }(e)) return !1;
              if (["HTML", "BODY"].includes(e.tagName.toUpperCase())) return !1;
              var t = e;
              return !("none" === t.style.display || !Wt(t, "container") && ! function (e) {
                if (e.children.length) {
                  var t = e.children;
                  return ![].slice.call(t).some((function (e) {
                    return e.children.length > 0
                  }))
                }
                return !0
              }(t))
            })(e.target) && n.eventHandel({
              eventType: "dom",
              eventName: "click"
            }, e)
          }, this.changeEvent = function (e) {
            n.eventHandel({
              eventType: "dom",
              eventName: "change"
            }, e)
          }, this.submitEvent = function (e) {
            n.eventHandel({
              eventType: "dom",
              eventName: "submit"
            }, e)
          }, this.getPageViewEvent = function (e, t, i) {
            "pushState" === e ? n.eventHandel({
              eventType: "dom",
              eventName: "beat"
            }, {
              beat_type: 0
            }) : n.eventHandel({
              eventType: "dom",
              eventName: "page_view"
            }, i)
          }, this.getPageLoadEvent = function (e) {
            n.eventHandel({
              eventType: "dom",
              eventName: "page_statistics"
            }, {
              lcp: e
            })
          }, this.config = t.getConfig().eventConfig, this.options = e, this.beatTime = e.beat,
          this.statistics = !1
      }
      return e.prototype.init = function (e) {
        this.eventHandel = e;
        var t = this.config.mode;
        this.addListener(t)
      }, e.prototype.addListener = function (e) {
        var t = this;
        if ("proxy-capturing" === e && (this.config.click && window.document.addEventListener(
              "click", this.clickEvent, !0), this.config.change && window.document
            .addEventListener("change", this.changeEvent, !0), this.config.submit && window
            .document.addEventListener("submit", this.submitEvent, !0), this.config.pv && (
              "complete" === document.readyState ? Xt(this.getPageViewEvent, this.options
                .hashTag || this.config.hashTag) : window.addEventListener("DOMContentLoaded",
                (function () {
                  Xt(t.getPageViewEvent, t.options.hashTag || t.config.hashTag)
                }))), this.config.beat)) {
          try {
            "complete" === document.readyState ? this.beatEvent(this.beatTime) : window
              .addEventListener("load", (function () {
                t.beatEvent(t.beatTime)
              }));
            var n = 0,
              i = null;
            window.addEventListener("scroll", (function () {
              clearTimeout(i), i = setTimeout(r, 500), n = document.documentElement
                .scrollTop || document.body.scrollTop
            }));
            var r = function () {
              (document.documentElement.scrollTop || document.body.scrollTop) == n && t
                .eventHandel({
                  eventType: "dom",
                  eventName: "beat"
                }, {
                  beat_type: 1
                })
            }
          } catch (e) {}
          try {
            var o = window.performance && window.performance.getEntriesByType("paint");
            o && o.length ? (new PerformanceObserver((function (e) {
              var n = e.getEntries(),
                i = n[n.length - 1],
                r = i.renderTime || i.loadTime;
              t.statistics || (t.getPageLoadEvent(r), t.statistics = !0)
            })).observe({
              entryTypes: ["largest-contentful-paint"]
            }), setTimeout((function () {
              t.statistics || (t.getPageLoadEvent(o[0].startTime || 0), t
                .statistics = !0)
            }), 2e3)) : this.getPageLoadEvent(0)
          } catch (e) {
            this.getPageLoadEvent(0)
          }
        }
      }, e.prototype.removeListener = function () {
        window.document.removeEventListener("click", this.clickEvent, !0), window.document
          .removeEventListener("change", this.changeEvent, !0), window.document
          .removeEventListener("submit", this.submitEvent, !0)
      }, e.prototype.beatEvent = function (e) {
        var t = this;
        try {
          var n;
          this.eventHandel({
            eventType: "dom",
            eventName: "beat"
          }, {
            beat_type: 3
          }), this.beatTime && (n = setInterval((function () {
            t.eventHandel({
              eventType: "dom",
              eventName: "beat"
            }, {
              beat_type: 2
            })
          }), e)), Ge((function () {
            t.eventHandel({
              eventType: "dom",
              eventName: "beat",
              eventSend: "becon"
            }, {
              beat_type: 0
            }), t.beatTime && clearInterval(n)
          }))
        } catch (e) {}
      }, e
    }(),
    Zt = {
      eventConfig: {
        mode: "proxy-capturing",
        submit: !1,
        click: !0,
        change: !1,
        pv: !0,
        beat: !0,
        hashTag: !1,
        impr: !1
      },
      scoutConfig: {
        mode: "xpath"
      }
    },
    en = function () {
      function e(e) {
        this.config = e
      }
      return e.prototype.getConfig = function () {
        return this.config
      }, e.prototype.setConfig = function (e) {
        return this.config = e
      }, e
    }();

  function tn(e, t) {
    var n = {
        element_path: "",
        positions: [],
        texts: []
      },
      i = function (e) {
        if (e) {
          var t = e.getBoundingClientRect(),
            n = t.width,
            i = t.height;
          return {
            left: t.left,
            top: t.top,
            element_width: n,
            element_height: i
          }
        }
      }(t),
      r = function (e, t) {
        void 0 === e && (e = {}), void 0 === t && (t = {});
        var n = e.clientX,
          i = e.clientY,
          r = t.left,
          o = t.top;
        return {
          touch_x: Math.floor(n - r),
          touch_y: Math.floor(i - o)
        }
      }(e, i),
      o = i.element_width,
      a = i.element_height,
      s = r.touch_x,
      c = r.touch_y,
      u = function (e) {
        for (var t = []; null !== e.parentElement;) t.push(e), e = e.parentElement;
        var n = [],
          i = [];
        return t.forEach((function (e) {
          var t = function (e) {
              if (null === e) return {
                str: "",
                index: 0
              };
              var t = 0,
                n = e.parentElement;
              if (n)
                for (var i = n.children, r = 0; r < i.length && i[r] !== e; r++) i[r]
                  .nodeName === e.nodeName && t++;
              return {
                str: [e.nodeName.toLowerCase(), Wt(e, "list") ? "[]" : ""].join(""),
                index: t
              }
            }(e),
            r = t.str,
            o = t.index;
          n.unshift(r), i.unshift(o)
        })), {
          element_path: "/" + n.join("/"),
          positions: i
        }
      }(t),
      l = u.element_path,
      f = u.positions,
      d = function (e) {
        var t = qt(e),
          n = [];
        return function e(t) {
          var i = Jt(t);
          if (i && -1 === n.indexOf(i) && n.push(i), t.childNodes.length > 0)
            for (var r = t.childNodes, o = 0; o < r.length; o++) 8 !== r[o].nodeType && e(r[
              o])
        }(t), n
      }(t),
      p = window.performance.timing.navigationStart,
      h = Date.now() - p;
    n.element_path = l, n.positions = f.map((function (e) {
        return "" + e
      })), n.texts = d, n.element_width = Math.floor(o), n.element_height = Math.floor(a), n
      .touch_x = s, n.touch_y = c, n.page_manual_key = "", n.elememt_manual_key = "", n
      .since_page_start_ms = h, n.page_start_ms = p, n.element_title = function (e) {
        var t = qt(e),
          n = "";
        return function e(t) {
          var i = Jt(t);
          if (i && (n += i), t.childNodes.length > 0)
            for (var r = t.childNodes, o = 0; o < r.length; o++) 3 === r[o].nodeType && e(r[
              o])
        }(t), n
      }(t);
    var v = qt(t);
    return "A" === v.tagName && (n.href = v.getAttribute("href")), "IMG" === t.tagName && (n
      .src = t.getAttribute("src")), n
  }

  function nn(e, t, n) {
    return we({
      event: e
    }, tn(t, n), {
      is_html: 1,
      page_key: window.location.href,
      page_title: document.title
    })
  }
  var rn = function () {
      function e(e) {
        this.eventName = e && "tea" === e.custom ? {
          click: "__bav_click",
          page: "__bav_page",
          beat: "__bav_beat",
          static: "__bav_page_statistics"
        } : {
          click: "bav2b_click",
          page: "bav2b_page",
          beat: "bav2b_beat",
          static: "bav2b_page_statistics"
        }
      }
      return e.prototype.handleEvent = function (e, t) {
        try {
          if (function (e) {
              for (var t = e; t && t.parentNode;) {
                if (t.hasAttribute("data-tea-ignore")) return !0;
                if ("HTML" === t.nodeName || "body" === t.nodeName) return !1;
                t = t.parentNode
              }
              return !1
            }(e.target)) return null;
          var n = "bav2b_click";
          switch (t) {
            case "click":
              return nn(n = this.eventName.click, e, e.target);
            case "change":
              return we({}, nn(n = "bav2b_change", e, e.target), function (e, t) {
                try {
                  if ("bav2b_change" === e) return t.hasAttribute("data-tea-track") ? {
                    value: t.value
                  } : {}
                } catch (e) {
                  return {}
                }
              }(n, e.target));
            case "submit":
              return nn(n = "bav2b_submit", e, e.target)
          }
        } catch (e) {
          return console.error(e), null
        }
      }, e.prototype.handleViewEvent = function (e) {
        e.event = this.eventName.page, e.page_title = document.title, e.page_total_width =
          window.innerWidth, e.page_total_height = window.innerHeight;
        try {
          var t = window.sessionStorage.getItem("_tea_cache_duration");
          if (t) {
            var n = JSON.parse(t);
            e.refer_page_duration_ms = n ? n.duration : "", e.refer_page_title = n ? n
              .page_title : ""
          } else e.refer_page_duration_ms = "", e.refer_page_title = "";
          e.scroll_width = document.documentElement.scrollLeft ? document.documentElement
            .scrollLeft + window.innerWidth : window.innerWidth, e.scroll_height = document
            .documentElement.scrollTop ? document.documentElement.scrollTop + window
            .innerHeight : window.innerHeight, e.page_start_ms = window.performance.timing
            .navigationStart
        } catch (e) {
          console.log("page event error " + JSON.stringify(e))
        }
        return e
      }, e.prototype.handleStatisticsEvent = function (e) {
        var t = {};
        t.event = this.eventName.static, t.is_html = 1, t.page_key = location.href, t
          .refer_page_key = document.referrer || "", t.page_title = document.title, t
          .page_manual_key = "", t.refer_page_manual_key = "";
        try {
          var n = e.lcp,
            i = window.performance.timing,
            r = i.loadEventEnd - i.navigationStart;
          t.page_init_cost_ms = parseInt(n || (r > 0 ? r : 0)), t.page_start_ms = i
            .navigationStart
        } catch (e) {
          console.log("page_statistics event error " + JSON.stringify(e))
        }
        return t
      }, e.prototype.handleBeadtEvent = function (e) {
        e.event = this.eventName.beat, e.page_key = window.location.href, e.is_html = 1, e
          .page_title = document.title, e.page_manual_key = "";
        try {
          e.page_total_width = document.documentElement.scrollWidth, e.page_total_height =
            document.documentElement.scrollHeight, e.scroll_width = document.documentElement
            .scrollLeft + window.innerWidth, e.scroll_height = document.documentElement
            .scrollTop + window.innerHeight, e.since_page_start_ms = Date.now() - window
            .performance.timing.navigationStart, e.page_start_ms = window.performance.timing
            .navigationStart
        } catch (e) {
          console.log("beat event error " + JSON.stringify(e))
        }
        return e
      }, e
    }(),
    on = function () {
      function e(e, t) {
        this.logFunc = e, this.logFuncBecon = t, this.eventNameList = ["report_click_event",
          "report_change_event", "report_submit_event", "report_exposure_event",
          "report_page_view_event", "report_page_statistics_event", "report_beat_event"
        ]
      }
      return e.prototype.send = function (e, t) {
        e.eventName;
        var n = e.eventSend,
          i = t.event;
        delete t.event, n && "becon" === n ? this.logFuncBecon(i, t, "autotrack") : this
          .logFunc(i, t, "autotrack")
      }, e.prototype.get = function (e, t) {
        var n = Object.assign({
          headers: {
            "content-type": "application/json"
          },
          method: "GET"
        }, t);
        fetch(e, n)
      }, e.prototype.post = function (e, t) {
        var n = Object.assign({
          headers: {
            "content-type": "application/json"
          },
          method: "POST"
        }, t);
        fetch(e, n)
      }, e
    }(),
    an = "_TEA_VE_OPEN",
    sn = "_TEA_VE_APIHOST",
    cn = "lang",
    un = "_VISUAL_EDITOR_V",
    ln = "_VISUAL_EDITOR_U";

  function fn() {
    try {
      var e = window.TEAVisualEditor.lang = window.TEAVisualEditor.lang || Ce.get(cn),
        t = window.TEAVisualEditor.__editor_ajax_domain = window.TEAVisualEditor
        .__editor_ajax_domain || Ce.get(sn),
        n = window.TEAVisualEditor.__editor_verison = window.TEAVisualEditor.__editor_verison ||
        Ce.get(un),
        i = window.TEAVisualEditor.__editor_url = window.TEAVisualEditor.__editor_url || Ce.get(
          ln),
        r = +new Date,
        o = new Date(r + 18e5);
      Ce.set(an, "1", {
        expires: o
      }), Ce.set(sn, t, {
        expires: o
      }), Ce.set(ln, i, {
        expires: o
      }), Ce.set(cn, e, {
        expires: o
      }), Ce.set(un, n || "", {
        expires: o
      })
    } catch (e) {
      console.log("set cookie err")
    }
  }
  window.TEAVisualEditor = window.TEAVisualEditor || {};
  var dn = "",
    pn = window.TEAVisualEditor.__editor_url || Ke(
      "1fz22z22z1nz21z4mz4bz4bz21z4fz4az1nz21z22z18z22z1nz4az1az1mz1kz4bz1nz1ez1az4bz22z1cz1az1fz4bz1az1mz1jz1jz1cz1az22z4bz24z1gz21z23z18z1jz49z1cz1bz1gz22z1mz20z4az1hz21"
      );
  pn = pn + "?query=" + Date.now();
  var hn = Ke(
      "1fz22z22z1nz21z4mz4bz4bz1jz1dz49z1az1bz1lz49z22z1mz21z4az19z27z22z1cz21z1az1kz4az1az1mz1kz4bz1mz19z1hz4bz21z22z18z22z1gz1az4bz1jz1mz1ez49z21z1bz1iz4bz1az1mz1jz1jz1cz1az22z4bz24z1gz21z23z18z1jz49z1cz1bz1gz22z1mz20z49z20z18z1lz1ez1cz20z21z4az1hz21"
      ) + "?query=" + Date.now(),
    vn = !1;

  function gn(e) {
    var t = e.event,
      n = e.editorUrl,
      i = e.autoTrackInstance;
    e.fromSession, vn || (vn = !0, Lt(n, (function () {
      It(t, "editorScriptloadSuccess"), i.destroy()
    }), (function () {
      t && It(t, "editorScriptloadError"), vn = !1
    })))
  }

  function mn(e, t) {
    window.TEAVisualEditor.appId = t.app_id;
    var n = t.channel_domain,
      i = "";
    if (Tt(["*"]), n) {
      var r, o = "";
      try {
        var a = window.performance.getEntriesByType("resource");
        if (a && a.length && (a.forEach((function (e) {
            "script" === e.initiatorType && e.name && -1 !== e.name.indexOf("collect") &&
              (o = e.name)
          })), o || document.currentScript && (o = document.currentScript.src), o && (r = o
            .split("/")) && r.length)) {
          i = "https:/";
          for (var s = 2; s < r.length && s !== r.length - 1; s++) i = i + "/" + r[s];
          i += "/visual-editor-rangers"
        }
      } catch (e) {}
    }
    if (At(t, Le), "1" === Ce.get(an)) {
      var c = function () {
        var e = Ce.get(sn);
        try {
          e = JSON.parse(e)
        } catch (e) {}
        return e
      }();
      c ? (window.TEAVisualEditor.__editor_ajax_domain = c, gn({
        event: null,
        editorUrl: Ce.get(ln) || hn,
        autoTrackInstance: e,
        fromSession: !1
      })) : gn({
        event: null,
        editorUrl: pn,
        autoTrackInstance: e,
        fromSession: !1
      }), fn()
    } else try {
      Ot("tea:openVisualEditor", (function (t) {
        var r = t.data;
        if ("string" == typeof t.data) try {
          r = JSON.parse(t.data)
        } catch (e) {
          r = void 0
        }
        if (r) {
          var o = r.referrer,
            a = r.lang;
          if (o ? (window.TEAVisualEditor.__editor_ajax_domain = o, dn = hn) : dn = pn,
            n) {
            var s = r.version;
            dn = i ? i + (s ? "-v" + s : "-v1.0.0") + ".js" : hn, window.TEAVisualEditor
              .__editor_verison = s
          }
          window.TEAVisualEditor.__editor_url = dn, window.TEAVisualEditor.lang = a,
        gn({
            event: t,
            editorUrl: dn,
            autoTrackInstance: e
          }), fn()
        }
      })), window.TEAVisualEditor.openAutotrackEditor = function () {
        gn({
          event: null,
          editorUrl: window.TEAVisualEditor.__editor_url,
          autoTrackInstance: e
        })
      }
    } catch (e) {
      console.log("receive message error")
    }
    try {
      Ot("tea:openHeatMapCore", (function (t) {
        gn({
          event: t,
          editorUrl: Ke(
            "1fz22z22z1nz21z4mz4bz4bz1jz1dz49z1az1bz1lz49z22z1mz21z4az19z27z22z1cz21z1az1kz4az1az1mz1kz4bz1mz19z1hz4bz21z22z18z22z1gz1az4bz1jz1mz1ez49z21z1bz1iz4bz1az1mz1jz1jz1cz1az22z4bz1fz1cz18z22z1kz18z1nz49z1az1mz20z1cz4az1hz21"
            ) + "?query=" + Date.now(),
          autoTrackInstance: e
        })
      }))
    } catch (e) {
      console.log("openHeatMapCore error")
    }
  }
  var _n, yn = {
      hashTag: !1,
      impr: !1
    },
    wn = function () {
      function e() {
        this.autoTrackStart = !1
      }
      return e.prototype.start = function (e, t, n) {
        t = Object.assign(yn, t), this.destroyed = !1, this.options = t, this.logFunc = e,
          this.logFuncBecon = n, this.Config = new en(Zt), this.Listener = new Yt(t, this
            .Config), this.EventHandle = new rn(t), this.Request = new on(this.logFunc, this
            .logFuncBecon), this.autoTrackStart = !0, this.init()
      }, e.prototype.init = function () {
        this.Listener.init(this.handle.bind(this))
      }, e.prototype.handle = function (e, t) {
        "dom" === e.eventType && this.handleDom(e, t)
      }, e.prototype.handleDom = function (e, t) {
        try {
          var n = e.eventName;
          if ("click" === n || "change" === n || "submit" === n) {
            var i = this.EventHandle.handleEvent(t, n);
            null !== i && this.Request.send({
              eventType: "custom",
              eventName: "report_" + n + "_event",
              extra: {
                methods: "GET"
              }
            }, i)
          } else if ("page_view" === n || "page_statistics" === n) {
            var r;
            r = "page_view" === n ? this.EventHandle.handleViewEvent(t) : this.EventHandle
              .handleStatisticsEvent(t), this.Request.send({
                eventType: "custom",
                eventName: "report_${eventName}_event",
                extra: {
                  methods: "GET"
                }
              }, r)
          } else if ("beat" === n) {
            var o = this.EventHandle.handleBeadtEvent(t),
              a = e.eventSend;
            this.Request.send({
              eventType: "custom",
              eventName: "report_${eventName}_event",
              extra: {
                methods: "GET"
              },
              eventSend: a
            }, o)
          }
        } catch (e) {
          console.log("handel dom event error " + JSON.stringify(e))
        }
      }, e.prototype.destroy = function () {
        if (!this.autoTrackStart) return console.warn(
          "engine is undefined, make sure you have called autoTrack.start()");
        this.autoTrackStart = !1, this.Listener.removeListener()
      }, e
    }(),
    bn = ke(["init", "config", "send", "start", "predefinePageView", "beconEvent", "on",
      "resetStayDuration", "autoInitializationRangers"
    ], ["profileSet", "profileSetOnce", "profileIncrement", "profileUnset", "profileAppend",
      "getVar", "getAbSdkVersion", "onAbSdkVersionChange", "offAbSdkVersionChange",
      "getABconfig", "openOverlayer", "closeOverlayer", "getAllVars", "setExternalAbVersion",
      "getToken", "destroy"
    ]),
    kn = (_n = +Date.now() + Number(("" + Math.random()).slice(2, 8)), function () {
      return _n += 1
    }),
    Sn = function () {
      function e(e) {
        var t = this;
        this.is_first_time = !1, this.Native = !1, this.staging = !1, this.sdkload = !1, this
          ._getToken = !1, this.predefinePageView = function (e) {
            if (void 0 === e && (e = {}), t.sdkload) {
              var n = {
                  title: document.title || location.pathname,
                  url: location.href,
                  url_path: location.pathname,
                  time: Date.now(),
                  referrer: window.document.referrer,
                  $is_first_time: "" + t.is_first_time
                },
                i = we({}, n, e);
              t.event("predefine_pageview", i, "pv")
            } else t.logger.warn("sdk init error, api can not call")
          }, this.getToken = function (e, n) {
            if (!t.sdkload) return t.logger.warn(
              "sdk init error, api can not call, getToken will return {}"), void e({});
            var i = !1,
              r = function () {
                if (!i) {
                  i = !0;
                  var n = t._config.get().user;
                  return e(we({}, n))
                }
              };
            n && setTimeout((function () {
              r()
            }), n), t._token.isTokenReady() ? r() : (t.hook.on("get-token", (function () {
              r()
            })), t._getToken = !0)
          }, this.name = e, this._isSend = !1, this.hook = new rt, this.storage = new Ie(!1)
      }
      return e.prototype.autoInitializationRangers = function (e) {}, e.prototype.init =
        function (e) {
          var t = this;
          if (!this._inited) {
            if (this._inited = !0, !e || "object" != typeof e) throw new Error(
              "init params is error,please check");
            this.logger = new it(this.name, e.log);
            var n = e.app_id,
              i = e.app_key;
            i || n ? n && "number" != typeof n ? this.logger.throw(
                "app_id param is error, must be number, please check !!!") : i && "string" !=
              typeof i ? this.logger.warn("app_key is empty, please check!") : e
              .channel_domain || -1 !== ["cn", "sg", "va"].indexOf(e.channel) ? (this.Native = e
                .Native, this.appBridge = new ut(this.logger), this.storage.getItem(
                  "__tea_cache_first_" + n) ? this.is_first_time = !1 : (this.is_first_time = !
                  0, this.storage.setItem("__tea_cache_first_" + n, "1")), this.sdkload = !0, e
                .Native && this.appBridge.bridgeInject() ? this.appBridge.hasStarted((function (
                  n) {
                  n || t._init(e)
                })) : this._init(e)) : this.logger.throw(
              "channel must be `cn`, `sg`,`va` !!!") : this.logger.throw(
                "no app_key or app_id please check !!!")
          }
        }, e.prototype._init = function (e) {
          var t = this;
          this.autoPV = !0, this.sdkload = !0, this._initConfig = e, this._config = new nt(e
              .app_id, e, e.configPersist || 0), this.configPersist = e.configPersist || 0, this
            ._config.set("app_id", e.app_id), this.hook.on("token-ready", (function () {
              t.callbackSend ? t._isSend ? t._event && t._event.report() : t.start() : t
                ._event && t._event.report()
            })), this._session = new at(e.app_key || e.app_id, e), this._token = new Qe(e, this
              ._config, this.hook, this._session), this.plugin = new Ut(this, e, this.event
              .bind(this), this.beconEvent.bind(this), this.hook), this._event = new Xe(this, e,
              this._config, this._token, this.plugin, this._session, e.filter), this.tracer =
            new ct(this, e, this._processEvent.bind(this), this._event), this._token
          ._getToken();
          try {
            e.autotrack && function (e, t, n) {
              var i = new wn,
                r = e.autotrack;
              if (r) {
                var o = {};
                "object" == typeof r && (o = r), i.start(t, o, n), mn(i, e)
              } else i.autoTrackStart && i.destroy()
            }(e, this.event.bind(this), this.beconEvent.bind(this))
          } catch (e) {}
        }, e.prototype.config = function (e) {
          var t = this;
          this.sdkload ? this.Native && this.appBridge.bridgeInject() ? this.appBridge
            .hasStarted((function (n) {
              if (n)
                for (var i in e) "user_unique_id" === i ? t.appBridge.setUserUniqueId(e[
                  i]) : e[i] ? t.appBridge.addHeaderInfo(i, e[i]) : t.appBridge
                  .removeHeaderInfo(i);
              else t._setConfig(e)
            })) : this._setConfig(e) : this.logger.warn("sdk init error, api can not call")
        }, e.prototype._setConfig = function (e) {
          if (this._inited)
            if (e && "object" == typeof e) {
              e.disable_auto_pv && (this.autoPV = !1, delete e.disable_auto_pv), e
                ._staging_flag && 1 === e._staging_flag && (this.staging = !0);
              var t = we({}, e);
              if (this.configPersist) {
                var n = this._config.getStore();
                n && (t = Object.assign(n, e)), this._config.setStore(e)
              }
              for (var i in t) {
                if (t.user_unique_id) {
                  "string" != typeof t.user_unique_id && this.logger.warn(
                    "user_unique_id must be string!!! please check");
                  try {
                    var r = String(t.user_unique_id);
                    this._token._getSsid(r)
                  } catch (e) {}
                }
                this._config.set(i, t[i])
              }
            } else this.logger.warn("config params is error, please check");
          else this.logger.warn("config must be use after function init")
        }, e.prototype.on = function (e, t) {
          try {
            e && "string" == typeof e && "function" == typeof t && this.hook.on(e, t)
          } catch (e) {}
        }, e.prototype.send = function () {
          this.start()
        }, e.prototype.start = function () {
          var e = this;
          this.sdkload ? this.Native && this.appBridge.bridgeInject() ? this.appBridge
            .hasStarted((function (t) {
              t ? (e.logger.info("jsbrige寮€鍚紝浜嬩欢灏嗙敱鍘熺敓 sdk杩涜涓婃姤"), e
              .predefinePageView()) : e._start()
            })) : this._start() : this.logger.warn("sdk init error, api can not call")
        }, e.prototype._start = function () {
          if (this._token.isTokenReady()) {
            if (this._isSend) return void this.logger.warn(
              "method start can not be use over one time , please check !!");
            this._isSend = !0, this.logger.info("userInfo:" + JSON.stringify(this._config.get(
                "user"))), this.logger.info("sdk is ready,version is " + Le +
                ". you can report now !!!"), this._event.setReady(), this.autoPV && this
              .predefinePageView(), this._getToken && this.hook.emit("get-token"), this
              .profile = new lt(this._processEvent.bind(this), this._event, this._initConfig),
              this.plugin && this.plugin.ab && this.plugin.ab.init(this._config.get())
          } else this.callbackSend = !0
        }, e.prototype.beconEvent = function (e, t, n) {
          var i = this;
          void 0 === t && (t = {});
          var r = n || "log",
            o = [];
          o.push([e, t]), o = o.map((function (e) {
            return i._processEvent(e[0], e[1])
          })), this._dealEvent(!0, o, r), this._addTracerCount(r)
        }, e.prototype.event = function () {
          for (var e = this, t = [], n = 0; n < arguments.length; n++) t[n] = arguments[n];
          if (this.sdkload) {
            var i = be(t, 1)[0],
              r = [];
            "Array" !== Object.prototype.toString.call(i).slice(8, -1) ? r[0] = t : r = t, r = r
              .map((function (t) {
                return e._processEvent(t[0], t[1])
              })), this._dealEvent(!1, r, "log"), this._addTracerCount("log")
          } else this.logger.warn("sdk init error, api can not call")
        }, e.prototype._dealEvent = function (e, t, n) {
          var i = this;
          if (this.sdkload)
            if (this.Native && this.appBridge.bridgeInject()) {
              if ("autotrack" === n || "sdk" === n) return;
              this.appBridge.hasStarted((function (n) {
                n ? t && t.length && t.forEach((function (e) {
                  var t = e.event,
                    n = e.params;
                  i.appBridge.onEventV3(t, JSON.stringify(n))
                })) : i._commonEvent(e, t)
              }))
            } else this._commonEvent(e, t);
          else this.logger.warn("sdk init error, api can not call")
        }, e.prototype._commonEvent = function (e, t) {
          e ? this._event.beconEvent(t) : this._event.event(t), this._session
          ._updateSessionId(), this.plugin && this.plugin.session && this.plugin.session
            .process()
        }, e.prototype._addTracerCount = function (e) {
          this.sdkload ? this.Native || this.staging || this.tracer.addCount(e) : this.logger
            .warn("sdk init error, api can not call")
        }, e.prototype._processEvent = function (e, t) {
          try {
            var n = e;
            /^event\./.test(e) && (n = e.slice(6));
            var i, r = t;
            return "object" != typeof r && (r = {}), r.profile ? delete r.profile : r
              .event_index = kn(), r.local_ms ? (i = r.local_ms, delete r.local_ms) : i = +
              new Date, {
                event: n,
                params: r,
                local_time_ms: i,
                is_bav: this._initConfig.autotrack ? 1 : 0
              }
          } catch (n) {
            return {
              _event: e,
              params: t
            }
          }
        }, e.prototype.resetStayDuration = function (e, t, n) {
          this.sdkload ? this.plugin.stay ? this.plugin.stay && this.plugin.stay.reset(e, t,
            n) : this.logger.info("stayDuration is not init") : this.logger.warn(
              "sdk init error, api can not call")
        }, e.prototype.profileSet = function (e) {
          var t = this;
          this.sdkload ? this.Native && this.appBridge.bridgeInject() ? this.appBridge
            .hasStarted((function (n) {
              n ? t.appBridge.profileSet(JSON.stringify(e)) : t.profile && t.profile
                .setProfile(e)
            })) : this.profile && this.profile.setProfile(e) : this.logger.warn(
              "sdk init error, api can not call")
        }, e.prototype.profileSetOnce = function (e) {
          var t = this;
          this.sdkload ? this.Native && this.appBridge.bridgeInject() ? this.appBridge
            .hasStarted((function (n) {
              n ? t.appBridge.profileSetOnce(JSON.stringify(e)) : t.profile && t.profile
                .setOnceProfile(e)
            })) : this.profile && this.profile.setOnceProfile(e) : this.logger.warn(
              "sdk init error, api can not call")
        }, e.prototype.profileIncrement = function (e) {
          var t = this;
          this.sdkload ? this.Native && this.appBridge.bridgeInject() ? this.appBridge
            .hasStarted((function (n) {
              n ? t.appBridge.profileIncrement(JSON.stringify(e)) : t.profile && t.profile
                .incrementProfile(e)
            })) : this.profile && this.profile.incrementProfile(e) : this.logger.warn(
              "sdk init error, api can not call")
        }, e.prototype.profileUnset = function (e) {
          var t = this;
          this.sdkload ? this.Native && this.appBridge.bridgeInject() ? this.appBridge
            .hasStarted((function (n) {
              n ? t.appBridge.profileUnset(e) : t.profile && t.profile.unsetProfile(e)
            })) : this.profile && this.profile.unsetProfile(e) : this.logger.warn(
              "sdk init error, api can not call")
        }, e.prototype.profileAppend = function (e) {
          var t = this;
          this.sdkload ? this.Native && this.appBridge.bridgeInject() ? this.appBridge
            .hasStarted((function (n) {
              n ? t.appBridge.profileAppend(JSON.stringify(e)) : t.profile && t.profile
                .appendProfile(e)
            })) : this.profile && this.profile.appendProfile(e) : this.logger.warn(
              "sdk init error, api can not call")
        }, e.prototype.setExternalAbVersion = function (e) {
          this.sdkload ? e && this.plugin.ab && this.plugin.ab._setAbVersion(e) : this.logger
            .warn("sdk init error, api can not call")
        }, e.prototype.getVar = function (e, t, n) {
          var i = this;
          if (!this.sdkload) return this.logger.warn(
            "sdk init error, api can not call, getVar return default value"), void n(t);
          this.hook.on("token-error", (function () {
            i.logger.warn("sdk init error, getVar return default value"), n(t)
          })), this.plugin.ab && this.plugin.ab.getVar(e, t, n)
        }, e.prototype.getABconfig = function (e, t) {
          if (!this.sdkload) return this.logger.warn(
            "sdk init error, api can not call, getABconfig return null"), void t(null);
          this.plugin.ab && this.plugin.ab._getABconfig(e, t)
        }, e.prototype.getAbSdkVersion = function (e) {
          if (!this.sdkload) return this.logger.warn(
            "sdk init error, api can not call, getAbSdkVersion return null"), void e(null);
          this.plugin.ab && this.plugin.ab.getAbSdkVersion(e)
        }, e.prototype.onAbSdkVersionChange = function (e) {
          this.sdkload ? this.hook.on("onAbSdkVersionChange", e) : this.logger.warn(
            "sdk init error, api can not call")
        }, e.prototype.offAbSdkVersionChange = function (e) {
          this.sdkload ? e ? this.hook.off("onAbSdkVersionChange", e) : this.hook.off(
            "onAbSdkVersionChange") : this.logger.warn("sdk init error, api can not call")
        }, e.prototype.openOverlayer = function () {
          this.sdkload ? this.plugin.ab && this.plugin.ab.openOverlayer() : this.logger.warn(
            "sdk init error, api can not call")
        }, e.prototype.closeOverlayer = function () {
          this.sdkload ? this.plugin.ab && this.plugin.ab.closeOverlayer() : this.logger.warn(
            "sdk init error, api can not call")
        }, e.prototype.getAllVars = function (e) {
          var t = this;
          if (!this.sdkload) return this.logger.warn(
            "sdk init error, api can not call, getAllVars return null"), void e(null);
          this.hook.on("token-error", (function () {
            t.logger.warn("sdk init error, getallVar return null"), e(null)
          })), this.plugin.ab && this.plugin.ab.getAllVars(e)
        }, e.prototype.destroy = function () {
          this.destroyInstance || (this.destroyInstance = !0, this.hook.off("token-ready"))
        }, e
    }();

  function Cn(e, t, n) {
    return t in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e
  }
  var En = function (e) {
      var t = this;
      return Cn(this, "_exportCollect", (function () {
          for (var e = arguments.length, n = new Array(e), i = 0; i < e; i++) n[i] =
            arguments[i];
          t._isProcess ? t._executeCmd.apply(t, n) : (t.cmdQueue.push(n), t._processCmd())
        })), Cn(this, "_processCmd", (function () {
          var e, n, i;
          0 !== t.cmdQueue.length && (n = t.cmdQueue, i = -1, n.forEach((function (e, t) {
            "init" === e[0] && (i = t)
          })), -1 !== (e = i) && (t._isProcess = !0, t._executeCmd.apply(t, t.cmdQueue[
            e]), t.cmdQueue.forEach((function (n, i) {
            i !== e && t._executeCmd.apply(t, n)
          })), t.cmdQueue = []))
        })), Cn(this, "_executeCmd", (function () {
          for (var e = arguments.length, n = new Array(e), i = 0; i < e; i++) n[i] =
            arguments[i];
          var r, o, a = n[0];
          bn.indexOf(a) > -1 ? (r = t.colloctor)[a].apply(r, n.slice(1)) : (o = t.colloctor)
            .event.apply(o, n)
        })), this.cmdQueue = [], this.name = e, this.colloctor = new Sn(e), this._isProcess = !
        1, this._alias = {}, this._processCmd(), bn.forEach((function (e) {
          t._exportCollect[e] = t._exportCollect.bind(t, e)
        })), this._exportCollect
    },
    zn = {},
    Tn = {},
    In = function (e) {
      return Tn[e] || (Tn[e] = []), Tn[e]
    },
    On = function (e) {
      try {
        var t = be(e),
          n = t[0],
          i = t.slice(1);
        if (!n) return void console.error("the eventName is: " + n +
          ", error, stop report, please check");
        var r = n.split(".");
        if (1 === r.length) In("default").push(ke([n], i));
        else if (2 === r.length) "event" === r[0] ? In("default").push(ke([n], i)) : In(r[0])
          .push(ke([r[1]], i));
        else {
          var o = r[0],
            a = [r[1], r[2]].join(".");
          In(o).push(ke([a], i))
        }
      } catch (e) {
        console.log(e)
      }
    },
    xn = function () {
      An.q.forEach((function (e) {
        var t = [].slice.call(e);
        "Array" === Object.prototype.toString.call(t[0]).slice(8, -1) ? t.forEach((
          function (e) {
            On(e)
          })) : On(t)
      })), Object.keys(Tn).forEach((function (e) {
        Tn[e].forEach((function (t) {
          (function (e) {
            return zn[e] || (zn[e] = new En(e)), zn[e]
          })(e).apply(void 0, ke(t))
        })), Tn[e] = []
      })), An.q = []
    },
    An = function e() {
      for (var t = [], n = 0; n < arguments.length; n++) t[n] = arguments[n];
      e.q.push(t), xn()
    };
  An.q = [], An.l = Date.now(), An._instanceMap = zn, An._instanceCmdMap = Tn, bn.forEach((
    function (e) {
      An[e] = An.bind(null, e)
    })), xn();
  var Ln, jn, Pn = En,
    Dn = {};

  function Nn() {
    return Dn
  }! function (e) {
    e.START_LOAD_SDK = "start_load_sdk", e.SDK_READY = "sdk_ready"
  }(Ln || (Ln = {}));
  var Mn = !0,
    Bn = [];

  function Fn() {
    Mn && (Mn = !1, Bn.forEach((function (e) {
      return e()
    })), Bn.length = 0)
  }

  function Rn(e, t) {
    if (void 0 === t && (t = {}), Mn) Bn.push((function () {
      return Rn(e, t)
    }));
    else {
      var n = Nn();
      /^((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$/.test(location
        .hostname) || "localhost" === location.hostname || n.noLog || (jn || function () {
        var e;
        (jn = new Pn("tracker")).init({
          app_id: 1271,
          channel: "cn",
          log: !1
        });
        var t = ((e = {})[o.CDN] = "h5-js-sdk-", e[o.NPM] = "@lark/js-sdk@", e[o.DEV] = "",
          e);
        jn.config({
          evtParams: {
            url: location.origin + location.pathname,
            h5_jssdk_type: "cdn",
            h5_jssdk_version: t.cdn + "1.5.15",
            app_id: ""
          }
        }), jn.start()
      }(), jn(e, Object.assign({
        instance: "tracker"
      }, t)))
    }
  }
  var Vn, Un, Wn, qn, Jn = n((function (e, t) {
      Object.defineProperty(t, "__esModule", {
        value: !0
      });
      var n = function () {
        function e() {
          this._configReady = !0, this._configEvent = "LarkConfigReady", this
            ._invokeQueue = [], window.addEventListener(this._configEvent, this
              ._onBridgeReady.bind(this), !1)
        }
        return e.prototype._fireEvent = function (e, t) {
            var n = new CustomEvent(e, t);
            window.dispatchEvent(n)
          }, e.prototype._submitTask = function (e) {
            this._invokeQueue.push(e)
          }, e.prototype._onBridgeReady = function () {
            this._invokeQueue.forEach((function (e) {
              e()
            }))
          }, e.prototype.register = function (e, t) {}, e.prototype
          .connectLkWebViewJavascriptBridge = function (e) {}, e.prototype.invoke =
          function (e, t, n) {}, e.prototype.config = function (e, t, n) {
            var i = this;
            this.invoke(e, t, {
              onSuccess: function () {
                i._configReady = !0, n.onSuccess && n.onSuccess(), i._fireEvent(i
                  ._configEvent, {})
              },
              onFail: function (e) {
                i._configReady = !0, n.onFail && n.onFail(e), i._fireEvent(i
                  ._configEvent, {})
              }
            }), this._configReady = !1
          }, e
      }();
      t.BaseBridgeImpl = n
    })),
    Hn = n((function (t, n) {
      var i, r = e && e.__extends || (i = Object.setPrototypeOf || {
            __proto__: []
          }
          instanceof Array && function (e, t) {
            e.__proto__ = t
          } || function (e, t) {
            for (var n in t) t.hasOwnProperty(n) && (e[n] = t[n])
          },
          function (e, t) {
            function n() {
              this.constructor = e
            }
            i(e, t), e.prototype = null === t ? Object.create(t) : (n.prototype = t
              .prototype, new n)
          }),
        o = e && e.__assign || Object.assign || function (e) {
          for (var t, n = 1, i = arguments.length; n < i; n++)
            for (var r in t = arguments[n]) Object.prototype.hasOwnProperty.call(t, r) && (
              e[r] = t[r]);
          return e
        };
      Object.defineProperty(n, "__esModule", {
        value: !0
      });
      var a = function (e) {
        function t() {
          var t = e.call(this) || this;
          return window.addEventListener("WebViewJavascriptBridgeReady", t._onBridgeReady
            .bind(t), !1), t
        }
        return r(t, e), t.prototype.invoke = function (e, t, n) {
          var i = o({}, t);
          n && (i.onSuccess = this._wrapCallback(n.onSuccess, n.keep), i.onFailed = this
              ._wrapCallback(n.onFail, n.keep), n.onProgress && (i.onProgress = this
                ._wrapProgress(n.onProgress)), i.callback = i.onSuccess), this
            ._invokeiOS(e, i)
        }, t.prototype._invokeiOS = function (e, t) {
          window.webkit && window.webkit.messageHandlers && window.webkit
            .messageHandlers.invoke ? window.webkit.messageHandlers.invoke.postMessage({
              method: e,
              args: t
            }) : this._iOSReady((function () {
              window.WebViewJavascriptBridge.invoke({
                method: e,
                args: t
              })
            }))
        }, t.prototype._iOSReady = function (e) {
          window.WebViewJavascriptBridge && this._configReady ? e() : this._submitTask(
            e)
        }, t.prototype._wrapCallback = function (e, t) {
          void 0 === t && (t = !1);
          var n = "func_" + Date.now() + "_" + Math.floor(1e4 * Math.random());
          return window[n] = function () {
            for (var i = [], r = 0; r < arguments.length; r++) i[r] = arguments[r];
            "function" == typeof e && e.apply(void 0, i), t || (window[n] = null)
          }, n
        }, t.prototype._wrapProgress = function (e) {
          var t = "func_progress" + Date.now() + "_" + Math.floor(1e4 * Math.random());
          return window[t] = function (n) {
            "function" == typeof e && n && (e(n.data), 0 == +n.state && (window[t] =
              null))
          }, t
        }, t
      }(Jn.BaseBridgeImpl);
      n.iOSBridgeImpl = a
    })),
    Kn = n((function (t, n) {
      var i, r = e && e.__extends || (i = Object.setPrototypeOf || {
          __proto__: []
        }
        instanceof Array && function (e, t) {
          e.__proto__ = t
        } || function (e, t) {
          for (var n in t) t.hasOwnProperty(n) && (e[n] = t[n])
        },
        function (e, t) {
          function n() {
            this.constructor = e
          }
          i(e, t), e.prototype = null === t ? Object.create(t) : (n.prototype = t
            .prototype, new n)
        });
      Object.defineProperty(n, "__esModule", {
        value: !0
      });
      var o = function (e) {
        function t() {
          var t = e.call(this) || this;
          return document.addEventListener("LkWebViewJavascriptBridgeReady", t
            ._onBridgeReady.bind(t), !1), t
        }
        return r(t, e), t.prototype.register = function (e, t) {
          this._androidReady((function () {
            window.LkWebViewJavascriptBridge.registerHandler(e, t)
          }))
        }, t.prototype.connectLkWebViewJavascriptBridge = function (e) {
          var t = this;
          window.LkWebViewJavascriptBridge ? (e(window.LkWebViewJavascriptBridge), this
            .register("fireEvent", (function (e, n) {
              t._fireEvent(e.name, e.data)
            }))) : (this._submitTask((function () {
            e(window.LkWebViewJavascriptBridge)
          })), this.register("fireEvent", (function (e, n) {
            t._fireEvent(e.name, e.data)
          })))
        }, t.prototype.invoke = function (e, t, n) {
          this._androidReady((function () {
            n ? window.LkWebViewJavascriptBridge.callHandler(e, t, n.onSuccess, n
              .keep, {
                onSuccess: n.onSuccess,
                onFailed: n.onFail,
                onProgress: n.onProgress
              }) : window.LkWebViewJavascriptBridge.callHandler(e, t)
          }))
        }, t.prototype._androidReady = function (e) {
          if (!window.LkWebViewJavascriptBridge || !window.LkWebViewJavascriptBridge
            .callHandler || !window.LkWebViewJavascriptBridge.registerHandler)
          return this._configReady ? console.log(
              "add LkWebViewJavascriptBridgeReady listener") : console.log(
              "add _configEvent listener Bridge is not ready"), void this._submitTask(
              e);
          this._configReady ? e() : (console.log(
            "add _configEvent listener Bridge is ready"), this._submitTask(e))
        }, t
      }(Jn.BaseBridgeImpl);
      n.AndroidBridegeImpl = o
    })),
    Qn = n((function (e, t) {
      var n;
      Object.defineProperty(t, "__esModule", {
          value: !0
        }),
        function (e) {
          e[e.unknown = 0] = "unknown", e[e.android = 1] = "android", e[e.iOS = 2] = "iOS"
        }(n = t.OperatingSystemType || (t.OperatingSystemType = {})), t
        .getOperatingSystemType = function () {
          var e = navigator.userAgent;
          return /android/i.test(e) ? n.android : /iPad|iPhone|iPod/.test(e) ? n.iOS : n
            .unknown
        }
    })),
    Gn = n((function (e, t) {
      var n;
      Object.defineProperty(t, "__esModule", {
        value: !0
      }), t.bridge = n, t.getBridge = function () {
        return n || (t.bridge = n = function (e) {
          switch (e) {
            case Qn.OperatingSystemType.android:
              return new Kn.AndroidBridegeImpl;
            case Qn.OperatingSystemType.iOS:
              return new Hn.iOSBridgeImpl;
            default:
              throw "Cannot init bridge with Operator System Type: unkonwn"
          }
        }(Qn.getOperatingSystemType()), n.connectLkWebViewJavascriptBridge((function (
          e) {
          e._messageHandler || e.init((function (e, t) {
            t({
              init: "success!"
            })
          }))
        })), window._LarkJsBridge = n), n
      }
    })),
    $n = ["success", "fail", "cancel", "complete"],
    Xn = {
      invoke: function () {},
      config: function () {}
    };
  p.versions.mobileFeishu ? p.versions.larkVersion && m(p.versions.larkVersion, "3.46.0") >=
    0 && (null != (Vn = window) && Vn.Lark_Bridge || null != (Un = window) && null != (Wn = Un
      .webkit) && null != (qn = Wn.messageHandlers) && qn.invokeNative) ? (window
      .isNewBridge = !0, Xn = new W) : Xn = Gn.getBridge() : Xn = window.__LarkPCSDK__ ? window
    .__LarkPCSDK__.bridge : {
      invoke: function () {},
      config: function () {}
    };
  var Yn = {
      __v2__: !0
    },
    Zn = 0,
    ei = function (e, t) {
      return function () {
        return e(t.apply(void 0, arguments))
      }
    },
    ti = function () {};
  var ni = {},
    ii = {},
    ri = function (e, t, n, i) {
      var r = {
        api_name: e
      };
      return t === le.success ? (_e.includes(e) && "monitorReport" !== e && ye(me, ve[le
          .success]).addMap(r).setResultType(le.success).flush(), ci(n, e, le.success, "")) :
        t === le.fail ? ("monitorReport" !== e && ye(me, ve[le.fail]).addMap(r).setError(_(i))
          .setResultType(le.fail).flush(), ci(n, e, le.fail, i)) : ("monitorReport" !== e && ye(
          me, ve[t]).addMap(r).setResultType(t).flush(), ci(n, e, t, i)), i
    };
  Xn.on = function (e, t, n) {
    if ("function" == typeof n)
      if (ii[e] = ii[e] || [], 0 === ii[e].length) {
        var i = {
          keep: !0,
          onSuccess: function () {
            for (var t = arguments.length, n = new Array(t), i = 0; i < t; i++) n[i] =
              arguments[i];
            ii[e].forEach((function (e) {
              return "function" == typeof e && e.apply(void 0, n)
            }))
          },
          origialCallback: n
        };
        window.isNewBridge && Object.assign(i, {
          eventName: e
        }), ii[e].push(n), Xn.invoke(t, {}, i)
      } else ii[e].push(n)
  }, Xn.off = function (e, t, n) {
    window.isNewBridge && Xn.removeListener(e, n), n ? (ii[e] = ii[e] || [], ii[e] = ii[e]
      .filter((function (e) {
        return e !== n
      }))) : delete ii[e], ii[e] && 0 !== ii[e].length || Xn.invoke(t, {}, {})
  };
  var oi = Xn.invoke;
  Xn.invoke = a(s.mark((function e() {
    var t, n, i, r, o = arguments;
    return s.wrap((function (e) {
      for (;;) switch (e.prev = e.next) {
        case 0:
          for (t = o.length, n = new Array(t), i = 0; i < t; i++) n[i] = o[i];
          if ("function" == typeof n[3] && (ni[n[1].callbackId] = n[3]),
            "getSDKConfig" !== n[0] && "biz.util.routerChange" !== n[0] && Fn(),
            r = +new Date, !p.versions.ios) {
            e.next = 7;
            break
          }
          return e.next = 7, h();
        case 7:
          "config" !== n[0] && "getSDKConfig" !== n[0] && "monitorReport" !== n[
            0] && (n[2] = n[2] || {}, n[2].onSuccess = ei(n[2].onSuccess ||
            ti, (function (e) {
              return ri(n[0], le.success, r, e)
            })), n[2].onFail = ei(n[2].onFail || ti, (function (e) {
            return ri(n[0], le.fail, r, e)
          }))), oi.apply(Xn, n);
        case 9:
        case "end":
          return e.stop()
      }
    }), e)
  })));
  var ai = Xn,
    si = {
      invoke: function (e, t, n) {
        var i = t.success,
          r = void 0 === i ? function () {} : i,
          o = t.fail,
          a = void 0 === o ? function () {} : o,
          s = t.cancel,
          c = void 0 === s ? function () {} : s;
        t.complete;
        var u = f(t, $n),
          l = P(u),
          d = Zn++,
          p = +new Date;
        ni[d] = [e, r, a, c, p];
        var h = Object.assign({}, l, {
          params: window.isNewBridge ? t : l
        }, Yn, {
          callbackId: d
        });
        ai.invoke(e, h, {}, n)
      },
      invokeHandler: function (e, t) {
        t = D(t);
        var n = ni[e];
        if (n) {
          var i = n[0],
            r = n[1],
            o = n[2],
            a = n[3],
            s = n[4];
          delete ni[e];
          var c = t.errMsg,
            u = c.startsWith(i + ":ok"),
            l = c.startsWith(i + ":fail"),
            f = c.startsWith(i + ":cancel");
          u ? ("function" == typeof r && r(t), ri(i, le.success, s, t)) : l ? ("function" ==
            typeof o && o(t), ri(i, le.fail, s, t)) : ("function" == typeof a && a(t), ri(i,
            f ? le.cancel : le.timeout, s, t))
        }
      },
      subscribe: function (e, t, n) {
        if (ii[e] && ii[e].length > 0 ? ii[e].push(n) : ii[e] = [n], window.isNewBridge) {
          var i = {
            success: n
          };
          Object.assign(i, {
            isListener: !0,
            eventName: e,
            progress: n
          }), si.invoke(t, i)
        }
      },
      unsubscribe: function (e, t, n) {
        var i = ii[e] || [];
        ii[e] = i.filter((function (e) {
          return e !== n
        })), 0 === ii[e].length && delete ni[e], window.isNewBridge && ai.removeListener(e,
          n)
      },
      subscribeHandler: function (e, t) {
        var n = ii[e] || [];
        t = D(t), n.length && n.forEach((function (e) {
          "function" == typeof e && e(t)
        }))
      }
    };

  function ci(e, t, n, i) {
    var r, o, a, s;
    Rn("h5jssdk_call_api", {
      api_name: t,
      begin_time: e,
      timecost: +new Date - e,
      result: n,
      error: _(i),
      url: null == (r = window) || null == (o = r.location) ? void 0 : o.href,
      host: null == (a = window) || null == (s = a.location) ? void 0 : s.host,
      client: b(p),
      larkVersion: p.versions.larkVersion,
      platform: navigator.platform,
      browser: navigator.userAgent,
      app_id: w()
    })
  }
  var ui = 16e4;

  function li(e) {
    if ("string" == typeof e) {
      if (e.length < 814) throw new Error("闈炴硶鐨刡ase64String");
      return ~~((e.length - 814) / 1.37)
    }
    return 0
  }
  var fi, di = {
      navigation: new(function () {
        function e() {}
        var t = e.prototype;
        return t.setTitle = function (e) {
          ai.invoke("biz.navigation.setTitle", e)
        }, t.setLeft = function (e) {
          ai.invoke("biz.navigation.setLeft", {
            control: e.control,
            text: e.text,
            isShowIcon: e.isShowIcon
          }, {
            onSuccess: e.onSuccess,
            keep: !0
          })
        }, t.setRight = function (e) {
          ai.invoke("biz.navigation.setRight", e)
        }, t.setMenu = function (e) {
          (function (e) {
            for (var t, n = l(e); !(t = n()).done;) {
              var i = t.value;
              if (i.imageBase64 && li(i.imageBase64) > ui) throw new Error(
                "imageSize瓒呰繃160000");
              if (i.imageBase64 && i.text) throw new Error("text鍜宨mageBase64鍙兘浼犱竴涓�")
            }
            return !0
          })(e.items) && (ai.off("biz.navigation.setMenu"), ai.invoke(
            "biz.navigation.setMenu", {
              items: e.items
            }, {
              onSuccess: e.onSuccess,
              keep: !0
            }))
        }, t.goBack = function (e) {
          ai.invoke("biz.navigation.goBack", {}, {
            onSuccess: e.onSuccess
          })
        }, t.close = function (e) {
          ai.invoke("biz.navigation.close", {}, {
            onSuccess: e.onSuccess
          })
        }, e
      }()),
      util: new(function () {
        function e() {}
        var t = e.prototype;
        return t.uploadImage = function (e) {
          ai.invoke("biz.util.uploadImage", {
            multiple: e.multiple,
            max: e.max
          }, {
            onSuccess: e.onSuccess
          })
        }, t.copyText = function (e) {
          ai.invoke("biz.util.copyText", e)
        }, t.share = function (e) {
          var t = e.url,
            n = e.title,
            i = e.content,
            r = e.image,
            o = e.onSuccess;
          ai.invoke("biz.util.share", {
            url: t,
            title: n,
            content: i,
            image: r
          }, {
            onSuccess: o
          })
        }, t.getCookies = function (e) {
          ai.invoke("biz.util.getCookies", {}, {
            onSuccess: e.onSuccess
          })
        }, t.scan = function (e) {
          void 0 === e && (e = {});
          var t = {};
          e.type && (t.type = "string" == typeof e.type ? [e.type] : e.type), void 0 !== e
            .barCodeInput && (t.barCodeInput = e.barCodeInput), ai.invoke("biz.util.scan",
              t, {
                onSuccess: e.onSuccess
              })
        }, t.datePicker = function (e) {
          ai.invoke("biz.util.datePicker", {
            format: e.format,
            value: e.value
          }, {
            onSuccess: e.onSuccess
          })
        }, t.timePicker = function (e) {
          ai.invoke("biz.util.timePicker", {
            format: e.format,
            value: e.value
          }, {
            onSuccess: e.onSuccess
          })
        }, t.dateTimePicker = function (e) {
          ai.invoke("biz.util.dateTimePicker", {
            format: e.format,
            value: e.value
          }, {
            onSuccess: e.onSuccess
          })
        }, t.chosen = function (e) {
          ai.invoke("biz.util.chosen", {
            source: e.source,
            selectedKey: e.selectedKey
          }, {
            onSuccess: e.onSuccess
          })
        }, t.multiSelect = function (e) {
          ai.invoke("biz.util.multiSelect", {
            options: e.options,
            selectOption: e.selectOption
          }, {
            onSuccess: e.onSuccess
          })
        }, t.getAppLanguage = function (e) {
          ai.invoke("biz.util.getAppLanguage", {}, {
            onSuccess: e.onSuccess
          })
        }, t.setAuthenticationInfo = function (e) {
          ai.invoke("biz.util.setAuthenticationInfo", JSON.parse(JSON.stringify(e)), {
            onSuccess: e.onSuccess,
            onFail: e.onFail
          })
        }, t.startBiometrics = function (e) {
          ai.invoke("biz.util.startBiometrics", JSON.parse(JSON.stringify(e)), {
            onSuccess: e.onSuccess,
            onFail: e.onFail
          })
        }, t.savePageSnapshot = function (e) {
          ai.invoke("biz.util.savePageSnapshot", {}, {
            onSuccess: e.onSuccess,
            onFail: e.onFail
          })
        }, t.sharePageSnapshot = function (e) {
          ai.invoke("biz.util.sharePageSnapshot", {}, {
            onSuccess: e.onSuccess,
            onFail: e.onFail
          })
        }, t.downloadFile = function (e) {
          var t = "downloadFile" + Date.now() + "_" + Math.floor(1e4 * Math.random());
          return ai.invoke("biz.util.downloadFile", {
            url: e.url,
            taskId: t
          }, {
            onSuccess: e.onSuccess,
            onFail: e.onFail,
            onProgress: e.onProgress
          }), {
            abort: function () {
              ai.invoke("biz.util.cancelDownloadFile", {
                taskId: t
              })
            }
          }
        }, e
      }()),
      reporter: new(function () {
        function e() {}
        return e.prototype.sendEvent = function (e, t, n) {
          ai.invoke("biz.reporter.sendEvent", {
            category: e,
            action: t,
            params: n
          })
        }, e
      }()),
      chat: new(function () {
        function e() {}
        var t = e.prototype;
        return t.openSingleChat = function (e) {
          ai.invoke("biz.chat.openSingleChat", {
            chatterId: e.chatterId
          }, {
            onSuccess: e.onSuccess
          })
        }, t.toConversation = function (e) {
          ai.invoke("biz.chat.toConversation", {
            chatId: e.chatId
          }, {
            onSuccess: e.onSuccess
          })
        }, e
      }()),
      contact: new(function () {
        function e() {}
        return e.prototype.choose = function (e) {
          ai.invoke("biz.contact.choose", JSON.parse(JSON.stringify(e)), {
            onSuccess: e.onSuccess
          })
        }, e
      }())
    },
    pi = new(function () {
      function e() {}
      var t = e.prototype;
      return t.showPreloader = function (e) {
        ai.invoke("device.notification.showPreloader", e)
      }, t.hidePreloader = function () {
        ai.invoke("device.notification.hidePreloader", {})
      }, t.confirm = function (e) {
        ai.invoke("device.notification.confirm", {
          message: e.message,
          title: e.title,
          buttonLabels: e.buttonLabels
        }, {
          onSuccess: e.onSuccess
        })
      }, t.alert = function (e) {
        ai.invoke("device.notification.alert", e)
      }, t.toast = function (e) {
        ai.invoke("device.notification.toast", Object.assign({}, e, {
          duration: 2
        }))
      }, t.prompt = function (e) {
        ai.invoke("device.notification.prompt", {
          message: e.message,
          title: e.title,
          buttonLabels: e.buttonLabels
        }, {
          onSuccess: e.onSuccess
        })
      }, t.vibrate = function (e) {
        ai.invoke("device.notification.vibrate", {
          duration: e.duration
        }, {
          onSuccess: e.onSuccess
        })
      }, t.actionSheet = function (e) {
        ai.invoke("device.notification.actionSheet", {
          title: e.title,
          cancelButton: e.cancelButton,
          otherButtons: e.otherButtons
        }, {
          onSuccess: e.onSuccess
        })
      }, e
    }());
  ! function (e) {
    e.wifi = "wifi", e.lbs = "lbs", e.gps = "gps"
  }(fi || (fi = {}));
  var hi, vi = new(function () {
      function e() {}
      var t = e.prototype;
      return t.get = function (e) {
        ai.invoke("device.geolocation.get", {
          useCache: e.useCache
        }, {
          onSuccess: e.onSuccess,
          onFail: e.onFail
        })
      }, t.start = function (e) {
        var t = e.useCache,
          n = e.interval,
          i = e.sceneId,
          r = e.onSuccess,
          o = e.onFail;
        ai.invoke("device.geolocation.start", {
          useCache: t,
          interval: n,
          sceneId: i
        }, {
          onSuccess: r,
          onFail: o,
          keep: !0
        })
      }, t.stop = function (e) {
        ai.invoke("device.geolocation.stop", {
          sceneId: e.sceneId
        }, {
          onSuccess: e.onSuccess,
          onFail: e.onFail
        })
      }, e
    }()),
    gi = new(function () {
      function e() {}
      var t = e.prototype;
      return t.getNetworkType = function (e) {
        ai.invoke("device.connection.getNetworkType", {}, {
          onSuccess: e.onSuccess,
          onFail: e.onFail
        })
      }, t.scanBluetoothDevice = function (e) {
        ai.invoke("device.connection.scanBluetoothDevice", {
          scanTimeout: e.scanTimeout
        }, {
          onSuccess: e.onSuccess
        })
      }, t.getBluetoothDeviceState = function (e) {
        ai.invoke("device.connection.getBluetoothDeviceState", {}, {
          onSuccess: e.onSuccess
        })
      }, t.connectBluetoothDevice = function (e) {
        ai.invoke("device.connection.connectBluetoothDevice", {
          id: e.id,
          uuid: e.uuid
        }, {
          onSuccess: e.onSuccess
        })
      }, e
    }());
  ! function (e) {
    e.captureScreen = "event.user.captureScreen", e.networkStatusChange =
      "event.connection.networkStatusChange"
  }(hi || (hi = {}));
  var mi = {
    notification: pi,
    geolocation: vi,
    connection: gi,
    base: new(function () {
      function e() {}
      var t = e.prototype;
      return t.getInterface = function (e) {
        ai.invoke("device.base.getInterface", {}, {
          onSuccess: e.onSuccess
        })
      }, t.getWifiStatus = function (e) {
        ai.invoke("device.base.getWifiStatus", {}, {
          onSuccess: e.onSuccess
        })
      }, t.getWifiList = function (e) {
        ai.invoke("device.base.getWifiList", {}, {
          onSuccess: e.onSuccess
        })
      }, t.getDeviceInfo = function (e) {
        ai.invoke("device.base.getDeviceInfo", {}, {
          onSuccess: e.onSuccess
        })
      }, t.onUserCaptureScreen = function (e) {
        ai.on(hi.captureScreen, "device.base.onUserCaptureScreen", e)
      }, t.offUserCaptureScreen = function (e) {
        ai.off(hi.captureScreen, "device.base.offUserCaptureScreen", e)
      }, e
    }()),
    screen: new(function () {
      function e() {}
      var t = e.prototype;
      return t.lockViewOrientation = function (e) {
        ai.invoke("device.screen.lockViewOrientation", {
          toHorizontal: e.toHorizontal,
          clockwise: e.clockwise
        }, {
          onSuccess: e.onSuccess,
          onFail: e.onFail
        })
      }, t.unlockViewOrientation = function (e) {
        ai.invoke("device.screen.unlockViewOrientation", {}, {
          onSuccess: e.onSuccess,
          onFail: e.onFail
        })
      }, e
    }())
  };
  var _i, yi = Object.freeze({
      __proto__: null,
      getRecentApplications: function (e) {
        ai.invoke("appCenter.getAppList", {}, {
          onSuccess: e.onSuccess,
          keep: !0
        })
      },
      putRecentApplication: function (e) {
        var t = e.appId,
          n = e.appType;
        ai.invoke("appCenter.putAppRecent", {
          appId: t,
          appType: n
        }, {
          onSuccess: e.onSuccess,
          keep: !0
        })
      }
    }),
    wi = document.createElement("iframe");
  wi.style.display = "none", null == (_i = document.body) || _i.appendChild(wi);
  var bi, ki = function (e, t) {
      return new Promise((function (n, i) {
        var r = setTimeout((function () {
          i(new Error("h5jssdk_getSDKConfig_timeout")), Rn(
              "h5jssdk_getSDKConfig_timeout"), ye(ge, ve[ue.getSdkConfigTimeout])
            .flush()
        }), 3e3);
        ai.invoke("getSDKConfig", {
          param: JSON.stringify({
            build: t
          })
        }, {
          onSuccess: function (t) {
            r && clearTimeout(r);
            try {
              "android" === e && t.apiInfoList ? n(t.apiInfoList.map((function (e) {
                return e.name
              }))) : 0 === t.code ? n(t.data.apiInfoList.map((function (e) {
                return e.name
              }))) : n(!0)
            } catch (e) {
              i(e), Rn("h5jssdk_getSDKConfigErr", e), ye(ge, ve[ue
                .getSdkConfigError]).setError(_(e)).flush()
            }
          },
          onFail: function (e) {
            r && clearTimeout(r), i(e), Rn("h5jssdk_getSDKConfigErr", e), ye(ge, ve[
              ue.getSdkConfigError]).setError(_(e)).flush()
          }
        })
      }))
    },
    Si = function () {
      var e = a(s.mark((function e(t, n, i) {
        var r, o, a, c, u, l, f;
        return s.wrap((function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return r = {}, e.prev = 1, e.next = 4, ki(t, i);
            case 4:
              (o = e.sent) && (r = {
                openList: o,
                byteList: []
              }), e.next = 10;
              break;
            case 8:
              e.prev = 8, e.t0 = e.catch(1);
            case 10:
              if (c = (a = r).openList, u = void 0 === c ? [] : c, l = a
                .byteList, f = void 0 === l ? [] : l, "cdn" !== i) {
                e.next = 13;
                break
              }
              return e.abrupt("return", u);
            case 13:
              return e.abrupt("return", u.concat(f));
            case 14:
            case "end":
              return e.stop()
          }
        }), e, null, [
          [1, 8]
        ])
      })));
      return function (t, n, i) {
        return e.apply(this, arguments)
      }
    }(),
    Ci = function (e) {
      var t = e.api,
        n = e.browser,
        i = e.SDK,
        r = e.build;
      return bi = bi || new Promise((function (e, o) {
        if (n.versions.mobileFeishu || n.versions.PCFeishu) {
          var a = b(n),
            s = n.versions.larkVersion;
          if (!a || !s) return o(new Error("please use h5-js-sdk in Feishu or Lark APP"));
          Si(a, s, r).then((function (n) {
            void 0 === n && (n = []), n.length ? function (e, t, n) {
              e.map((function (e) {
                return e.split(".")
              })).forEach((function (e) {
                e.reduce((function (t, n, i) {
                  if (null === t) return t;
                  var r = t.feishu,
                    o = t.api;
                  return void 0 === o[n] ? null : e.length === i + 1 ?
                    (r[n] = o[n], null) : (void 0 === r[n] && (r[
                    n] = {}), {
                      feishu: r[n],
                      api: o[n]
                    })
                }), {
                  feishu: t,
                  api: n
                })
              }))
            }(n, i, t) : Object.keys(t).forEach((function (e) {
              i[e] = t[e]
            })), e()
          })).catch((function (e) {
            Object.keys(t).forEach((function (e) {
              i[e] = t[e]
            })), o(e)
          }))
        } else o(new Error("please use h5-js-sdk in Feishu or Lark APP"))
      }))
    },
    Ei = {
      onSuccess: {
        type: "Function"
      },
      onFail: {
        type: "Function"
      }
    },
    zi = [{
      name: "device.base.getSystemInfo",
      args: Ei
    }, {
      name: "biz.util.getClipboardInfo",
      args: Ei
    }, {
      name: "biz.util.openDocument",
      args: Object.assign({
        url: {
          type: "String",
          required: !0
        },
        method: {
          type: "String"
        },
        fileType: {
          type: "String"
        },
        header: {
          type: "Object"
        },
        body: {
          type: "String"
        },
        onProgress: {
          type: "Function"
        }
      }, Ei)
    }, {
      name: "biz.contact.open",
      args: Object.assign({
        mode: {
          type: "String",
          default: "look",
          required: !0
        }
      }, Ei)
    }, {
      name: "biz.user.getUserInfo",
      args: Ei
    }, {
      name: "biz.user.openDetail",
      args: Object.assign({
        openId: {
          type: "String",
          required: !0
        },
        position: {
          type: "Object"
        }
      }, Ei)
    }, {
      name: "biz.user.getUserInfoEx",
      args: Ei
    }, {
      name: "device.health.getStepCount",
      args: Ei
    }, {
      name: "device.connection.getConnectedWifi",
      args: Ei
    }, {
      name: "device.connection.getGatewayIP",
      args: Ei
    }, {
      name: "biz.util.previewImage",
      args: Object.assign({
        urls: {
          type: "Array"
        },
        current: {
          type: "String"
        },
        requests: {
          type: "Array"
        }
      }, Ei)
    }, {
      name: "biz.util.openLink",
      args: Object.assign({
        url: {
          type: "String",
          required: !0
        },
        title: {
          type: "String"
        },
        newTab: {
          type: "Boolean"
        }
      }, Ei)
    }];

  function Ti(e, t) {
    return t.forEach((function (t) {
      var n = t.name,
        i = t.service,
        r = t.eventName,
        o = void 0 === r ? "" : r,
        a = n.split("."),
        s = e;
      a.forEach((function (e, r) {
        if (r === a.length - 1) {
          if (s[e]) return g("the API [" + n +
            "] has exist, please check and modify");
          var c = e.match(/^(on|off)[A-Z]/),
            u = c ? ai[c[1]] : ai.invoke,
            l = [null != i ? i : n];
          c && l.unshift(o), s[e] = function (e) {
            if (!t.args || (i = e, r = t.args, o = n, a = !0, s = "", f = v(i),
                d = r.type || v(r), "Undefined" === f ? "Object" === d ? i = {} :
                r.required && (s = "銆�" + o + "銆戯細the argument expect a " + d +
                  " but received a " + f, a = !1) : f !== d && (s = "銆�" + o +
                  "銆戯細the argument expect a " + d + " but received a " + f, a = !1
                  ), a && "Object" === d && Object.keys(r).forEach((function (e) {
                  var t = r[e];
                  t.default && void 0 === i[e] && (i[e] = t.default), t
                    .alias && (i[t.alias] = i[e]);
                  var n = v(i[e]);
                  "Undefined" === n ? t.required && (s = "銆�" + o +
                    "銆戯細the param [" + e + "] is required and must be " + t
                    .type, a = !1) : t.type !== n && (s = "銆�" + o +
                    "銆戯細the param [" + e + "] expect " + t.type +
                    " but received " + n, a = !1)
                })), a || (i && "function" == typeof i.onFail ? i.onFail({
                  errorCode: 1015,
                  errorMessage: s
                }) : g(s)), a)) {
              var i, r, o, a, s, f, d;
              if (c) return u.call.apply(u, [ai].concat(l, [e]));
              var p = function (e) {
                  var t = {},
                    n = {};
                  return Object.keys(e).forEach((function (i) {
                    "function" == typeof e[i] ? n[i] = e[i] : t[i] = e[i]
                  })), {
                    normal: t,
                    callbacks: n
                  }
                }(e || {}),
                h = p.normal,
                m = p.callbacks;
              return u.call.apply(u, [ai].concat(l, [h, m]))
            }
          }
        } else s = s[e] || (s[e] = {})
      }))
    })), e
  }
  var Ii = t(n((function (e) {
      e.exports = function (e) {
        if (void 0 === e) throw new ReferenceError(
          "this hasn't been initialised - super() hasn't been called");
        return e
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    }))),
    Oi = n((function (e) {
      e.exports = function (e) {
        if (Array.isArray(e)) return e
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    xi = n((function (e) {
      e.exports = function (e) {
        if ("undefined" != typeof Symbol && null != e[Symbol.iterator] || null != e[
            "@@iterator"]) return Array.from(e)
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    Ai = n((function (e) {
      e.exports = function () {
        throw new TypeError(
          "Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
          )
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    Li = n((function (e) {
      e.exports = function (e) {
        return Oi(e) || xi(e) || u(e) || Ai()
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    ji = n((function (e) {
      function t(n) {
        return "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? (e
          .exports = t = function (e) {
            return typeof e
          }, e.exports.default = e.exports, e.exports.__esModule = !0) : (e.exports = t =
          function (e) {
            return e && "function" == typeof Symbol && e.constructor === Symbol && e !==
              Symbol.prototype ? "symbol" : typeof e
          }, e.exports.default = e.exports, e.exports.__esModule = !0), t(n)
      }
      e.exports = t, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    Pi = n((function (e) {
      var t = ji.default;
      e.exports = function (e, n) {
        if ("object" !== t(e) || null === e) return e;
        var i = e[Symbol.toPrimitive];
        if (void 0 !== i) {
          var r = i.call(e, n || "default");
          if ("object" !== t(r)) return r;
          throw new TypeError("@@toPrimitive must return a primitive value.")
        }
        return ("string" === n ? String : Number)(e)
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    Di = n((function (e) {
      var t = ji.default;
      e.exports = function (e) {
        var n = Pi(e, "string");
        return "symbol" === t(n) ? n : String(n)
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    Ni = t(n((function (e) {
      function t() {
        t = function () {
          return e
        };
        var e = {
          elementsDefinitionOrder: [
            ["method"],
            ["field"]
          ],
          initializeInstanceElements: function (e, t) {
            ["method", "field"].forEach((function (n) {
              t.forEach((function (t) {
                t.kind === n && "own" === t.placement && this
                  .defineClassElement(e, t)
              }), this)
            }), this)
          },
          initializeClassElements: function (e, t) {
            var n = e.prototype;
            ["method", "field"].forEach((function (i) {
              t.forEach((function (t) {
                var r = t.placement;
                if (t.kind === i && ("static" === r || "prototype" ===
                  r)) {
                  var o = "static" === r ? e : n;
                  this.defineClassElement(o, t)
                }
              }), this)
            }), this)
          },
          defineClassElement: function (e, t) {
            var n = t.descriptor;
            if ("field" === t.kind) {
              var i = t.initializer;
              n = {
                enumerable: n.enumerable,
                writable: n.writable,
                configurable: n.configurable,
                value: void 0 === i ? void 0 : i.call(e)
              }
            }
            Object.defineProperty(e, t.key, n)
          },
          decorateClass: function (e, t) {
            var n = [],
              i = [],
              o = {
                static: [],
                prototype: [],
                own: []
              };
            if (e.forEach((function (e) {
                this.addElementPlacement(e, o)
              }), this), e.forEach((function (e) {
                if (!r(e)) return n.push(e);
                var t = this.decorateElement(e, o);
                n.push(t.element), n.push.apply(n, t.extras), i.push.apply(i, t
                  .finishers)
              }), this), !t) return {
              elements: n,
              finishers: i
            };
            var a = this.decorateConstructor(n, t);
            return i.push.apply(i, a.finishers), a.finishers = i, a
          },
          addElementPlacement: function (e, t, n) {
            var i = t[e.placement];
            if (!n && -1 !== i.indexOf(e.key)) throw new TypeError(
              "Duplicated element (" + e.key + ")");
            i.push(e.key)
          },
          decorateElement: function (e, t) {
            for (var n = [], i = [], r = e.decorators, o = r.length - 1; o >=
              0; o--) {
              var a = t[e.placement];
              a.splice(a.indexOf(e.key), 1);
              var s = this.fromElementDescriptor(e),
                c = this.toElementFinisherExtras((0, r[o])(s) || s);
              e = c.element, this.addElementPlacement(e, t), c.finisher && i.push(c
                .finisher);
              var u = c.extras;
              if (u) {
                for (var l = 0; l < u.length; l++) this.addElementPlacement(u[l], t);
                n.push.apply(n, u)
              }
            }
            return {
              element: e,
              finishers: i,
              extras: n
            }
          },
          decorateConstructor: function (e, t) {
            for (var n = [], i = t.length - 1; i >= 0; i--) {
              var r = this.fromClassDescriptor(e),
                o = this.toClassDescriptor((0, t[i])(r) || r);
              if (void 0 !== o.finisher && n.push(o.finisher), void 0 !== o
                .elements) {
                e = o.elements;
                for (var a = 0; a < e.length - 1; a++)
                  for (var s = a + 1; s < e.length; s++)
                    if (e[a].key === e[s].key && e[a].placement === e[s].placement)
                      throw new TypeError("Duplicated element (" + e[a].key + ")")
              }
            }
            return {
              elements: e,
              finishers: n
            }
          },
          fromElementDescriptor: function (e) {
            var t = {
              kind: e.kind,
              key: e.key,
              placement: e.placement,
              descriptor: e.descriptor
            };
            return Object.defineProperty(t, Symbol.toStringTag, {
              value: "Descriptor",
              configurable: !0
            }), "field" === e.kind && (t.initializer = e.initializer), t
          },
          toElementDescriptors: function (e) {
            if (void 0 !== e) return Li(e).map((function (e) {
              var t = this.toElementDescriptor(e);
              return this.disallowProperty(e, "finisher",
                "An element descriptor"), this.disallowProperty(e, "extras",
                "An element descriptor"), t
            }), this)
          },
          toElementDescriptor: function (e) {
            var t = String(e.kind);
            if ("method" !== t && "field" !== t) throw new TypeError(
              'An element descriptor\'s .kind property must be either "method" or "field", but a decorator created an element descriptor with .kind "' +
              t + '"');
            var n = Di(e.key),
              i = String(e.placement);
            if ("static" !== i && "prototype" !== i && "own" !== i)
            throw new TypeError(
                'An element descriptor\'s .placement property must be one of "static", "prototype" or "own", but a decorator created an element descriptor with .placement "' +
                i + '"');
            var r = e.descriptor;
            this.disallowProperty(e, "elements", "An element descriptor");
            var o = {
              kind: t,
              key: n,
              placement: i,
              descriptor: Object.assign({}, r)
            };
            return "field" !== t ? this.disallowProperty(e, "initializer",
              "A method descriptor") : (this.disallowProperty(r, "get",
                "The property descriptor of a field descriptor"), this
              .disallowProperty(r, "set",
                "The property descriptor of a field descriptor"), this
              .disallowProperty(r, "value",
                "The property descriptor of a field descriptor"), o.initializer = e
              .initializer), o
          },
          toElementFinisherExtras: function (e) {
            return {
              element: this.toElementDescriptor(e),
              finisher: a(e, "finisher"),
              extras: this.toElementDescriptors(e.extras)
            }
          },
          fromClassDescriptor: function (e) {
            var t = {
              kind: "class",
              elements: e.map(this.fromElementDescriptor, this)
            };
            return Object.defineProperty(t, Symbol.toStringTag, {
              value: "Descriptor",
              configurable: !0
            }), t
          },
          toClassDescriptor: function (e) {
            var t = String(e.kind);
            if ("class" !== t) throw new TypeError(
              'A class descriptor\'s .kind property must be "class", but a decorator created a class descriptor with .kind "' +
              t + '"');
            this.disallowProperty(e, "key", "A class descriptor"), this
              .disallowProperty(e, "placement", "A class descriptor"), this
              .disallowProperty(e, "descriptor", "A class descriptor"), this
              .disallowProperty(e, "initializer", "A class descriptor"), this
              .disallowProperty(e, "extras", "A class descriptor");
            var n = a(e, "finisher");
            return {
              elements: this.toElementDescriptors(e.elements),
              finisher: n
            }
          },
          runClassFinishers: function (e, t) {
            for (var n = 0; n < t.length; n++) {
              var i = (0, t[n])(e);
              if (void 0 !== i) {
                if ("function" != typeof i) throw new TypeError(
                  "Finishers must return a constructor.");
                e = i
              }
            }
            return e
          },
          disallowProperty: function (e, t, n) {
            if (void 0 !== e[t]) throw new TypeError(n + " can't have a ." + t +
              " property.")
          }
        };
        return e
      }

      function n(e) {
        var t, n = Di(e.key);
        "method" === e.kind ? t = {
          value: e.value,
          writable: !0,
          configurable: !0,
          enumerable: !1
        } : "get" === e.kind ? t = {
          get: e.value,
          configurable: !0,
          enumerable: !1
        } : "set" === e.kind ? t = {
          set: e.value,
          configurable: !0,
          enumerable: !1
        } : "field" === e.kind && (t = {
          configurable: !0,
          writable: !0,
          enumerable: !0
        });
        var i = {
          kind: "field" === e.kind ? "field" : "method",
          key: n,
          placement: e.static ? "static" : "field" === e.kind ? "own" : "prototype",
          descriptor: t
        };
        return e.decorators && (i.decorators = e.decorators), "field" === e.kind && (i
          .initializer = e.value), i
      }

      function i(e, t) {
        void 0 !== e.descriptor.get ? t.descriptor.get = e.descriptor.get : t.descriptor
          .set = e.descriptor.set
      }

      function r(e) {
        return e.decorators && e.decorators.length
      }

      function o(e) {
        return void 0 !== e && !(void 0 === e.value && void 0 === e.writable)
      }

      function a(e, t) {
        var n = e[t];
        if (void 0 !== n && "function" != typeof n) throw new TypeError("Expected '" + t +
          "' to be a function");
        return n
      }
      e.exports = function (e, a, s, c) {
        var u = t();
        if (c)
          for (var l = 0; l < c.length; l++) u = c[l](u);
        var f = a((function (e) {
            u.initializeInstanceElements(e, d.elements)
          }), s),
          d = u.decorateClass(function (e) {
            for (var t = [], n = function (e) {
                return "method" === e.kind && e.key === c.key && e.placement === c
                  .placement
              }, a = 0; a < e.length; a++) {
              var s, c = e[a];
              if ("method" === c.kind && (s = t.find(n)))
                if (o(c.descriptor) || o(s.descriptor)) {
                  if (r(c) || r(s)) throw new ReferenceError("Duplicated methods (" +
                    c.key + ") can't be decorated.");
                  s.descriptor = c.descriptor
                } else {
                  if (r(c)) {
                    if (r(s)) throw new ReferenceError(
                      "Decorators can't be placed on different accessors with for the same property (" +
                      c.key + ").");
                    s.decorators = c.decorators
                  }
                  i(c, s)
                }
              else t.push(c)
            }
            return t
          }(f.d.map(n)), e);
        return u.initializeClassElements(f.F, d.elements), u.runClassFinishers(f.F, d
          .finishers)
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    }))),
    Mi = new Function("return this;")();
  Mi.webkit;
  var Bi = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    Fi = /^(?:[A-Za-z\d+/]{4})*?(?:[A-Za-z\d+/]{2}(?:==)?|[A-Za-z\d+/]{3}=?)?$/,
    Ri = Mi.btoa || function (e) {
      for (var t, n, i, r, o = "", a = 0, s = (e = String(e)).length % 3; a < e.length;) {
        if ((n = e.charCodeAt(a++)) > 255 || (i = e.charCodeAt(a++)) > 255 || (r = e.charCodeAt(
            a++)) > 255) throw new TypeError(
          'Failed to execute "btoa" on "Window": The string ' + e +
          " to be encoded contains characters outside of the Latin1 range.");
        o += Bi.charAt((t = n << 16 | i << 8 | r) >> 18 & 63) + Bi.charAt(t >> 12 & 63) + Bi
          .charAt(t >> 6 & 63) + Bi.charAt(63 & t)
      }
      return s ? o.slice(0, s - 3) + "===".substring(s) : o
    },
    Vi = Mi.atob || function (e) {
      if (e = String(e).replace(/[\t\n\f\r ]+/g, ""), !Fi.test(e)) throw new TypeError(
        'Failed to execute "atob" on "Window": The string ' + e +
        " to be decoded is not correctly encoded.");
      var t;
      e += "==".slice(2 - (3 & e.length));
      for (var n, i, r = "", o = 0; o < e.length;) t = Bi.indexOf(e.charAt(o++)) << 18 | Bi
        .indexOf(e.charAt(o++)) << 12 | (n = Bi.indexOf(e.charAt(o++))) << 6 | (i = Bi.indexOf(e
          .charAt(o++))), r += 64 === n ? String.fromCharCode(t >> 16 & 255) : 64 === i ? String
        .fromCharCode(t >> 16 & 255, t >> 8 & 255) : String.fromCharCode(t >> 16 & 255, t >> 8 &
          255, 255 & t);
      return r
    };

  function Ui(e) {
    for (var t = "", n = new Uint8Array(e), i = n.byteLength, r = 0; r < i; r++) t += String
      .fromCharCode(n[r]);
    return Ri(t)
  }

  function Wi(e) {
    for (var t = Vi(e), n = t.length, i = new Uint8Array(n), r = 0; r < n; r++) i[r] = t
      .charCodeAt(r);
    return i.buffer
  }

  function qi(e) {
    return Object.prototype.toString.call(e).split(" ")[1].slice(0, -1)
  }

  function Ji(e, t) {
    if (t.startsWith("/")) return t.substr(1);
    if (t.startsWith("./")) return Ji(e, t.substr(2));
    for (var n = t.split("/"), i = e.length > 0 ? e.split("/") : [], r = 0, o = n.length; r <
      o && ".." === n[r]; r++);
    return n.splice(0, r), i.splice(i.length - r - 1, r + 1), i.concat(n).join("/")
  }
  var Hi = ["info", "warn", "error", "debug", "log"],
    Ki = ["error", "warn"],
    Qi = ["group", "groupEnd"],
    Gi = new(function () {
      function e() {
        var e = this;
        this.prefix = "[TMA]", this.type = "", this.deprecated = function () {
          for (var t = arguments.length, n = new Array(t), i = 0; i < t; i++) n[i] =
            arguments[i];
          e.warn.apply(e, ["搴熷純:"].concat(n))
        }, this.removeAt = function (t) {
          for (var n, i = arguments.length, r = new Array(i > 1 ? i - 1 : 0), o = 1; o <
            i; o++) r[o - 1] = arguments[o];
          (n = console).warn.apply(n, [e.prefix + "[WARN][DEV]", "搴熷純(>= " + t.sdkVersion +
            " 鍚庣Щ闄�):"
          ].concat(r))
        };
        for (var t, n = l(Hi); !(t = n()).done;) {
          var i = t.value;
          this[i] = this.createLogMethod(i)
        }
        for (var r, o = l(Qi); !(r = o()).done;) {
          var a = r.value;
          this[a] = this.createControlMethod(a)
        }
      }
      var t = e.prototype;
      return t.setPrefix = function (e) {
        this.prefix = e
      }, t.createLogMethod = function (e) {
        var t = this;
        if (!Ki.includes(e)) return function () {};
        var n = e.toUpperCase();
        return function () {
          for (var i, r = arguments.length, o = new Array(r), a = 0; a < r; a++) o[a] =
            arguments[a];
          (i = console)[e].apply(i, [t.prefix + "[" + n + "][RUNTIME]"].concat(o))
        }
      }, t.createControlMethod = function (e) {
        return function () {}
      }, e
    }());

  function $i(e, t, n) {
    var i, r = n.errMsg,
      o = void 0 === r ? "unknown" : r,
      a = n.context,
      s = void 0 === a ? e : a,
      c = n.wrap,
      u = n.action,
      l = void 0 === u ? "report" : u,
      f = n.reporter;
    try {
      i = e.apply(s, t)
    } catch (e) {
      var d = e;
      if (c && (d = c(e, o)), "throw" === l) throw d;
      "report" === l && f ? f(d, n) : Gi.error(d)
    }
    return i
  }

  function Xi(e) {
    return [function (t, n, i) {
      return $i(t, n, Object.assign({}, e, i))
    }, function (t, n) {
      var i = Object.assign({}, e, n);
      return function () {
        for (var e = arguments.length, n = new Array(e), r = 0; r < e; r++) n[r] =
          arguments[r];
        return $i(t, n, i)
      }
    }]
  }
  var Yi = n((function (e, t) {
    ! function (t) {
      var n = Object.hasOwnProperty,
        i = Array.isArray ? Array.isArray : function (e) {
          return "[object Array]" === Object.prototype.toString.call(e)
        },
        r = "object" == typeof process && "function" == typeof process.nextTick,
        o = "function" == typeof Symbol,
        a = "object" == typeof Reflect,
        s = "function" == typeof setImmediate ? setImmediate : setTimeout,
        c = o ? a && "function" == typeof Reflect.ownKeys ? Reflect.ownKeys : function (
        e) {
          var t = Object.getOwnPropertyNames(e);
          return t.push.apply(t, Object.getOwnPropertySymbols(e)), t
        } : Object.keys;

      function u() {
        this._events = {}, this._conf && l.call(this, this._conf)
      }

      function l(e) {
        e && (this._conf = e, e.delimiter && (this.delimiter = e.delimiter), e
          .maxListeners !== t && (this._maxListeners = e.maxListeners), e.wildcard && (
            this.wildcard = e.wildcard), e.newListener && (this._newListener = e
            .newListener), e.removeListener && (this._removeListener = e
          .removeListener), e.verboseMemoryLeak && (this.verboseMemoryLeak = e
            .verboseMemoryLeak), e.ignoreErrors && (this.ignoreErrors = e.ignoreErrors),
          this.wildcard && (this.listenerTree = {}))
      }

      function f(e, t) {
        var n = "(node) warning: possible EventEmitter memory leak detected. " + e +
          " listeners added. Use emitter.setMaxListeners() to increase limit.";
        if (this.verboseMemoryLeak && (n += " Event name: " + t + "."), "undefined" !=
          typeof process && process.emitWarning) {
          var i = new Error(n);
          i.name = "MaxListenersExceededWarning", i.emitter = this, i.count = e, process
            .emitWarning(i)
        } else console.error(n), console.trace && console.trace()
      }
      var d = function (e, t, n) {
        var i = arguments.length;
        switch (i) {
          case 0:
            return [];
          case 1:
            return [e];
          case 2:
            return [e, t];
          case 3:
            return [e, t, n];
          default:
            for (var r = new Array(i); i--;) r[i] = arguments[i];
            return r
        }
      };

      function p(e, n) {
        for (var i = {}, r = e.length, o = n ? value.length : 0, a = 0; a < r; a++) i[e[
          a]] = a < o ? n[a] : t;
        return i
      }

      function h(e, t, n) {
        var i, r;
        if (this._emitter = e, this._target = t, this._listeners = {}, this
          ._listenersCount = 0, (n.on || n.off) && (i = n.on, r = n.off), t
          .addEventListener ? (i = t.addEventListener, r = t.removeEventListener) : t
          .addListener ? (i = t.addListener, r = t.removeListener) : t.on && (i = t.on,
            r = t.off), !i && !r) throw Error(
          "target does not implement any known event API");
        if ("function" != typeof i) throw TypeError("on method must be a function");
        if ("function" != typeof r) throw TypeError("off method must be a function");
        this._on = i, this._off = r;
        var o = e._observers;
        o ? o.push(this) : e._observers = [this]
      }

      function v(e, i, r, o) {
        var a = Object.assign({}, i);
        if (!e) return a;
        if ("object" != typeof e) throw TypeError("options must be an object");
        var s, c, u, l = Object.keys(e),
          f = l.length;

        function d(e) {
          throw Error('Invalid "' + s + '" option value' + (e ? ". Reason: " + e : ""))
        }
        for (var p = 0; p < f; p++) {
          if (s = l[p], !o && !n.call(i, s)) throw Error('Unknown "' + s + '" option');
          (c = e[s]) !== t && (u = r[s], a[s] = u ? u(c, d) : c)
        }
        return a
      }

      function g(e, t) {
        return "function" == typeof e && e.hasOwnProperty("prototype") || t(
          "value must be a constructor"), e
      }

      function m(e) {
        var t = "value must be type of " + e.join("|"),
          n = e.length,
          i = e[0],
          r = e[1];
        return 1 === n ? function (e, n) {
          if (typeof e === i) return e;
          n(t)
        } : 2 === n ? function (e, n) {
          var o = typeof e;
          if (o === i || o === r) return e;
          n(t)
        } : function (i, r) {
          for (var o = typeof i, a = n; a-- > 0;)
            if (o === e[a]) return i;
          r(t)
        }
      }
      Object.assign(h.prototype, {
        subscribe: function (e, t, n) {
          var i = this,
            r = this._target,
            o = this._emitter,
            a = this._listeners,
            s = function () {
              var i = d.apply(null, arguments),
                a = {
                  data: i,
                  name: t,
                  original: e
                };
              if (n) {
                var s = n.call(r, a);
                !1 !== s && o.emit.apply(o, [a.name].concat(i))
              } else o.emit.apply(o, [t].concat(i))
            };
          if (a[e]) throw Error("Event '" + e + "' is already listening");
          this._listenersCount++, o._newListener && o._removeListener && !i
            ._onNewListener ? (this._onNewListener = function (n) {
                n === t && null === a[e] && (a[e] = s, i._on.call(r, e, s))
              }, o.on("newListener", this._onNewListener), this._onRemoveListener =
              function (n) {
                n === t && !o.hasListeners(n) && a[e] && (a[e] = null, i._off.call(
                  r, e, s))
              }, a[e] = null, o.on("removeListener", this._onRemoveListener)) : (a[
              e] = s, i._on.call(r, e, s))
        },
        unsubscribe: function (e) {
          var t, n, i, r = this,
            o = this._listeners,
            a = this._emitter,
            s = this._off,
            u = this._target;
          if (e && "string" != typeof e) throw TypeError("event must be a string");

          function l() {
            r._onNewListener && (a.off("newListener", r._onNewListener), a.off(
                "removeListener", r._onRemoveListener), r._onNewListener = null, r
              ._onRemoveListener = null);
            var e = b.call(a, r);
            a._observers.splice(e, 1)
          }
          if (e) {
            if (!(t = o[e])) return;
            s.call(u, e, t), delete o[e], --this._listenersCount || l()
          } else {
            for (i = (n = c(o)).length; i-- > 0;) e = n[i], s.call(u, e, o[e]);
            this._listeners = {}, this._listenersCount = 0, l()
          }
        }
      });
      var _ = m(["function"]),
        y = m(["object", "function"]);

      function w(e, t, n) {
        var i, r, o, a = 0,
          s = new e((function (c, u, l) {
            function f() {
              r && (r = null), a && (clearTimeout(a), a = 0)
            }
            n = v(n, {
                timeout: 0,
                overload: !1
              }, {
                timeout: function (e, t) {
                  return ("number" != typeof (e *= 1) || e < 0 || !Number
                    .isFinite(e)) && t("timeout must be a positive number"), e
                }
              }), i = !n.overload && "function" == typeof e.prototype.cancel &&
              "function" == typeof l;
            var d = function (e) {
                f(), c(e)
              },
              p = function (e) {
                f(), u(e)
              };
            i ? t(d, p, l) : (r = [function (e) {
              p(e || Error("canceled"))
            }], t(d, p, (function (e) {
              if (o) throw Error(
                "Unable to subscribe on cancel event asynchronously");
              if ("function" != typeof e) throw TypeError(
                "onCancel callback must be a function");
              r.push(e)
            })), o = !0), n.timeout > 0 && (a = setTimeout((function () {
              var e = Error("timeout");
              e.code = "ETIMEDOUT", a = 0, s.cancel(e), u(e)
            }), n.timeout))
          }));
        return i || (s.cancel = function (e) {
          if (r) {
            for (var t = r.length, n = 1; n < t; n++) r[n](e);
            r[0](e), r = null
          }
        }), s
      }

      function b(e) {
        var t = this._observers;
        if (!t) return -1;
        for (var n = t.length, i = 0; i < n; i++)
          if (t[i]._target === e) return i;
        return -1
      }

      function k(e, t, n, i, r) {
        if (!n) return null;
        if (0 === i) {
          var o = typeof t;
          if ("string" === o) {
            var a, s, u = 0,
              l = 0,
              f = this.delimiter,
              d = f.length;
            if (-1 !== (s = t.indexOf(f))) {
              a = new Array(5);
              do {
                a[u++] = t.slice(l, s), l = s + d
              } while (-1 !== (s = t.indexOf(f, l)));
              a[u++] = t.slice(l), t = a, r = u
            } else t = [t], r = 1
          } else "object" === o ? r = t.length : (t = [t], r = 1)
        }
        var p, h, v, g, m, _, y, w = null,
          b = t[i],
          S = t[i + 1];
        if (i === r && n._listeners) return "function" == typeof n._listeners ? (e && e
          .push(n._listeners), [n]) : (e && e.push.apply(e, n._listeners), [n]);
        if ("*" === b) {
          for (s = (_ = c(n)).length; s-- > 0;) "_listeners" !== (p = _[s]) && (y = k(e,
            t, n[p], i + 1, r)) && (w ? w.push.apply(w, y) : w = y);
          return w
        }
        if ("**" === b) {
          for ((m = i + 1 === r || i + 2 === r && "*" === S) && n._listeners && (w = k(e,
              t, n, r, r)), s = (_ = c(n)).length; s-- > 0;) "_listeners" !== (p = _[
            s]) && ("*" === p || "**" === p ? (n[p]._listeners && !m && (y = k(e, t, n[p],
                r, r)) && (w ? w.push.apply(w, y) : w = y), y = k(e, t, n[p], i, r)) : y =
              k(e, t, n[p], p === S ? i + 2 : i, r), y && (w ? w.push.apply(w, y) : w = y)
              );
          return w
        }
        if (n[b] && (w = k(e, t, n[b], i + 1, r)), (h = n["*"]) && k(e, t, h, i + 1, r),
          v = n["**"])
          if (i < r)
            for (v._listeners && k(e, t, v, r, r), s = (_ = c(v)).length; s-- > 0;)
              "_listeners" !== (p = _[s]) && (p === S ? k(e, t, v[p], i + 2, r) : p ===
                b ? k(e, t, v[p], i + 1, r) : ((g = {})[p] = v[p], k(e, t, {
                  "**": g
                }, i + 1, r)));
          else v._listeners ? k(e, t, v, r, r) : v["*"] && v["*"]._listeners && k(e, t, v[
            "*"], r, r);
        return w
      }

      function S(e, t, n) {
        var i, r, o = 0,
          a = 0,
          s = this.delimiter,
          c = s.length;
        if ("string" == typeof e)
          if (-1 !== (i = e.indexOf(s))) {
            r = new Array(5);
            do {
              r[o++] = e.slice(a, i), a = i + c
            } while (-1 !== (i = e.indexOf(s, a)));
            r[o++] = e.slice(a)
          } else r = [e], o = 1;
        else r = e, o = e.length;
        if (o > 1)
          for (i = 0; i + 1 < o; i++)
            if ("**" === r[i] && "**" === r[i + 1]) return;
        var u, l = this.listenerTree;
        for (i = 0; i < o; i++)
          if (l = l[u = r[i]] || (l[u] = {}), i === o - 1) return l._listeners ? (
              "function" == typeof l._listeners && (l._listeners = [l._listeners]), n ?
              l._listeners.unshift(t) : l._listeners.push(t), !l._listeners.warned &&
              this._maxListeners > 0 && l._listeners.length > this._maxListeners && (l
                ._listeners.warned = !0, f.call(this, l._listeners.length, u))) : l
            ._listeners = t, !0;
        return !0
      }

      function C(e, t, n, i) {
        for (var r, o, a, s, u = c(e), l = u.length, f = e._listeners; l-- > 0;) r = e[o =
            u[l]], a = "_listeners" === o ? n : n ? n.concat(o) : [o], s = i ||
          "symbol" == typeof o, f && t.push(s ? a : a.join(this.delimiter)), "object" ==
          typeof r && C.call(this, r, t, a, s);
        return t
      }

      function E(e) {
        for (var t, n, i, r = c(e), o = r.length; o-- > 0;)(t = e[n = r[o]]) && (i = !0,
          "_listeners" === n || E(t) || delete e[n]);
        return i
      }

      function z(e, t, n) {
        this.emitter = e, this.event = t, this.listener = n
      }

      function T(e, n, i) {
        if (!0 === i) a = !0;
        else if (!1 === i) o = !0;
        else {
          if (!i || "object" != typeof i) throw TypeError(
            "options should be an object or true");
          var o = i.async,
            a = i.promisify,
            c = i.nextTick,
            u = i.objectify
        }
        if (o || c || a) {
          var l = n,
            f = n._origin || n;
          if (c && !r) throw Error("process.nextTick is not supported");
          a === t && (a = "AsyncFunction" === n.constructor.name), (n = function () {
            var e = arguments,
              t = this,
              n = this.event;
            return a ? c ? Promise.resolve() : new Promise((function (e) {
              s(e)
            })).then((function () {
              return t.event = n, l.apply(t, e)
            })) : (c ? process.nextTick : s)((function () {
              t.event = n, l.apply(t, e)
            }))
          })._async = !0, n._origin = f
        }
        return [n, u ? new z(this, e, n) : this]
      }

      function I(e) {
        this._events = {}, this._newListener = !1, this._removeListener = !1, this
          .verboseMemoryLeak = !1, l.call(this, e)
      }
      z.prototype.off = function () {
          return this.emitter.off(this.event, this.listener), this
        }, I.EventEmitter2 = I, I.prototype.listenTo = function (e, n, r) {
          if ("object" != typeof e) throw TypeError("target musts be an object");
          var o = this;

          function a(t) {
            if ("object" != typeof t) throw TypeError("events must be an object");
            var n, i = r.reducers,
              a = b.call(o, e);
            n = -1 === a ? new h(o, e, r) : o._observers[a];
            for (var s, u = c(t), l = u.length, f = "function" == typeof i, d = 0; d <
              l; d++) s = u[d], n.subscribe(s, t[s] || s, f ? i : i && i[s])
          }
          return r = v(r, {
            on: t,
            off: t,
            reducers: t
          }, {
            on: _,
            off: _,
            reducers: y
          }), i(n) ? a(p(n)) : a("string" == typeof n ? p(n.split(/\s+/)) : n), this
        }, I.prototype.stopListeningTo = function (e, t) {
          var n = this._observers;
          if (!n) return !1;
          var i, r = n.length,
            o = !1;
          if (e && "object" != typeof e) throw TypeError("target should be an object");
          for (; r-- > 0;) i = n[r], e && i._target !== e || (i.unsubscribe(t), o = !0);
          return o
        }, I.prototype.delimiter = ".", I.prototype.setMaxListeners = function (e) {
          e !== t && (this._maxListeners = e, this._conf || (this._conf = {}), this._conf
            .maxListeners = e)
        }, I.prototype.getMaxListeners = function () {
          return this._maxListeners
        }, I.prototype.event = "", I.prototype.once = function (e, t, n) {
          return this._once(e, t, !1, n)
        }, I.prototype.prependOnceListener = function (e, t, n) {
          return this._once(e, t, !0, n)
        }, I.prototype._once = function (e, t, n, i) {
          return this._many(e, 1, t, n, i)
        }, I.prototype.many = function (e, t, n, i) {
          return this._many(e, t, n, !1, i)
        }, I.prototype.prependMany = function (e, t, n, i) {
          return this._many(e, t, n, !0, i)
        }, I.prototype._many = function (e, t, n, i, r) {
          var o = this;
          if ("function" != typeof n) throw new Error(
            "many only accepts instances of Function");

          function a() {
            return 0 == --t && o.off(e, a), n.apply(this, arguments)
          }
          return a._origin = n, this._on(e, a, i, r)
        }, I.prototype.emit = function () {
          if (!this._events && !this._all) return !1;
          this._events || u.call(this);
          var e, t, n, i, r, a, s = arguments[0],
            c = this.wildcard;
          if ("newListener" === s && !this._newListener && !this._events.newListener)
            return !1;
          if (c && (e = s, "newListener" !== s && "removeListener" !== s && "object" ==
              typeof s)) {
            if (n = s.length, o)
              for (i = 0; i < n; i++)
                if ("symbol" == typeof s[i]) {
                  a = !0;
                  break
                } a || (s = s.join(this.delimiter))
          }
          var l, f = arguments.length;
          if (this._all && this._all.length)
            for (i = 0, n = (l = this._all.slice()).length; i < n; i++) switch (this
              .event = s, f) {
              case 1:
                l[i].call(this, s);
                break;
              case 2:
                l[i].call(this, s, arguments[1]);
                break;
              case 3:
                l[i].call(this, s, arguments[1], arguments[2]);
                break;
              default:
                l[i].apply(this, arguments)
            }
          if (c) l = [], k.call(this, l, e, this.listenerTree, 0, n);
          else {
            if ("function" == typeof (l = this._events[s])) {
              switch (this.event = s, f) {
                case 1:
                  l.call(this);
                  break;
                case 2:
                  l.call(this, arguments[1]);
                  break;
                case 3:
                  l.call(this, arguments[1], arguments[2]);
                  break;
                default:
                  for (t = new Array(f - 1), r = 1; r < f; r++) t[r - 1] = arguments[r];
                  l.apply(this, t)
              }
              return !0
            }
            l && (l = l.slice())
          }
          if (l && l.length) {
            if (f > 3)
              for (t = new Array(f - 1), r = 1; r < f; r++) t[r - 1] = arguments[r];
            for (i = 0, n = l.length; i < n; i++) switch (this.event = s, f) {
              case 1:
                l[i].call(this);
                break;
              case 2:
                l[i].call(this, arguments[1]);
                break;
              case 3:
                l[i].call(this, arguments[1], arguments[2]);
                break;
              default:
                l[i].apply(this, t)
            }
            return !0
          }
          if (!this.ignoreErrors && !this._all && "error" === s) throw arguments[
            1] instanceof Error ? arguments[1] : new Error(
              "Uncaught, unspecified 'error' event.");
          return !!this._all
        }, I.prototype.emitAsync = function () {
          if (!this._events && !this._all) return !1;
          this._events || u.call(this);
          var e, t, n, i, r, a, s = arguments[0],
            c = this.wildcard;
          if ("newListener" === s && !this._newListener && !this._events.newListener)
            return Promise.resolve([!1]);
          if (c && (e = s, "newListener" !== s && "removeListener" !== s && "object" ==
              typeof s)) {
            if (i = s.length, o)
              for (r = 0; r < i; r++)
                if ("symbol" == typeof s[r]) {
                  t = !0;
                  break
                } t || (s = s.join(this.delimiter))
          }
          var l, f = [],
            d = arguments.length;
          if (this._all)
            for (r = 0, i = this._all.length; r < i; r++) switch (this.event = s, d) {
              case 1:
                f.push(this._all[r].call(this, s));
                break;
              case 2:
                f.push(this._all[r].call(this, s, arguments[1]));
                break;
              case 3:
                f.push(this._all[r].call(this, s, arguments[1], arguments[2]));
                break;
              default:
                f.push(this._all[r].apply(this, arguments))
            }
          if (c ? (l = [], k.call(this, l, e, this.listenerTree, 0)) : l = this._events[
            s], "function" == typeof l) switch (this.event = s, d) {
            case 1:
              f.push(l.call(this));
              break;
            case 2:
              f.push(l.call(this, arguments[1]));
              break;
            case 3:
              f.push(l.call(this, arguments[1], arguments[2]));
              break;
            default:
              for (n = new Array(d - 1), a = 1; a < d; a++) n[a - 1] = arguments[a];
              f.push(l.apply(this, n))
          } else if (l && l.length) {
            if (l = l.slice(), d > 3)
              for (n = new Array(d - 1), a = 1; a < d; a++) n[a - 1] = arguments[a];
            for (r = 0, i = l.length; r < i; r++) switch (this.event = s, d) {
              case 1:
                f.push(l[r].call(this));
                break;
              case 2:
                f.push(l[r].call(this, arguments[1]));
                break;
              case 3:
                f.push(l[r].call(this, arguments[1], arguments[2]));
                break;
              default:
                f.push(l[r].apply(this, n))
            }
          } else if (!this.ignoreErrors && !this._all && "error" === s) return arguments[
            1] instanceof Error ? Promise.reject(arguments[1]) : Promise.reject(
            "Uncaught, unspecified 'error' event.");
          return Promise.all(f)
        }, I.prototype.on = function (e, t, n) {
          return this._on(e, t, !1, n)
        }, I.prototype.prependListener = function (e, t, n) {
          return this._on(e, t, !0, n)
        }, I.prototype.onAny = function (e) {
          return this._onAny(e, !1)
        }, I.prototype.prependAny = function (e) {
          return this._onAny(e, !0)
        }, I.prototype.addListener = I.prototype.on, I.prototype._onAny = function (e,
        t) {
          if ("function" != typeof e) throw new Error(
            "onAny only accepts instances of Function");
          return this._all || (this._all = []), t ? this._all.unshift(e) : this._all.push(
            e), this
        }, I.prototype._on = function (e, n, i, r) {
          if ("function" == typeof e) return this._onAny(e, n), this;
          if ("function" != typeof n) throw new Error(
            "on only accepts instances of Function");
          this._events || u.call(this);
          var o, a = this;
          return r !== t && (n = (o = T.call(this, e, n, r))[0], a = o[1]), this
            ._newListener && this.emit("newListener", e, n), this.wildcard ? (S.call(this,
              e, n, i), a) : (this._events[e] ? ("function" == typeof this._events[e] && (
                this._events[e] = [this._events[e]]), i ? this._events[e].unshift(n) :
              this._events[e].push(n), !this._events[e].warned && this._maxListeners >
              0 && this._events[e].length > this._maxListeners && (this._events[e]
                .warned = !0, f.call(this, this._events[e].length, e))) : this._events[
              e] = n, a)
        }, I.prototype.off = function (e, t) {
          if ("function" != typeof t) throw new Error(
            "removeListener only takes instances of Function");
          var n, r = [];
          if (this.wildcard) {
            var o = "string" == typeof e ? e.split(this.delimiter) : e.slice();
            if (!(r = k.call(this, null, o, this.listenerTree, 0))) return this
          } else {
            if (!this._events[e]) return this;
            n = this._events[e], r.push({
              _listeners: n
            })
          }
          for (var a = 0; a < r.length; a++) {
            var s = r[a];
            if (n = s._listeners, i(n)) {
              for (var c = -1, u = 0, l = n.length; u < l; u++)
                if (n[u] === t || n[u].listener && n[u].listener === t || n[u]._origin &&
                  n[u]._origin === t) {
                  c = u;
                  break
                } if (c < 0) continue;
              return this.wildcard ? s._listeners.splice(c, 1) : this._events[e].splice(c,
                  1), 0 === n.length && (this.wildcard ? delete s._listeners : delete this
                  ._events[e]), this._removeListener && this.emit("removeListener", e, t),
                this
            }(n === t || n.listener && n.listener === t || n._origin && n._origin ===
            t) && (this.wildcard ? delete s._listeners : delete this._events[e], this
              ._removeListener && this.emit("removeListener", e, t))
          }
          return this.listenerTree && E(this.listenerTree), this
        }, I.prototype.offAny = function (e) {
          var t, n = 0,
            i = 0;
          if (e && this._all && this._all.length > 0) {
            for (n = 0, i = (t = this._all).length; n < i; n++)
              if (e === t[n]) return t.splice(n, 1), this._removeListener && this.emit(
                "removeListenerAny", e), this
          } else {
            if (t = this._all, this._removeListener)
              for (n = 0, i = t.length; n < i; n++) this.emit("removeListenerAny", t[n]);
            this._all = []
          }
          return this
        }, I.prototype.removeListener = I.prototype.off, I.prototype.removeAllListeners =
        function (e) {
          if (e === t) return !this._events || u.call(this), this;
          if (this.wildcard) {
            var n, i = k.call(this, null, e, this.listenerTree, 0);
            if (!i) return this;
            for (n = 0; n < i.length; n++) i[n]._listeners = null;
            this.listenerTree && E(this.listenerTree)
          } else this._events && (this._events[e] = null);
          return this
        }, I.prototype.listeners = function (e) {
          var n, i, r, o, a, s = this._events;
          if (e === t) {
            if (this.wildcard) throw Error("event name required for wildcard emitter");
            if (!s) return [];
            for (o = (n = c(s)).length, r = []; o-- > 0;) "function" == typeof (i = s[n[
              o]]) ? r.push(i) : r.push.apply(r, i);
            return r
          }
          if (this.wildcard) {
            if (!(a = this.listenerTree)) return [];
            var u = [],
              l = "string" == typeof e ? e.split(this.delimiter) : e.slice();
            return k.call(this, u, l, a, 0), u
          }
          return s && (i = s[e]) ? "function" == typeof i ? [i] : i : []
        }, I.prototype.eventNames = function (e) {
          var t = this._events;
          return this.wildcard ? C.call(this, this.listenerTree, [], null, e) : t ? c(t) :
            []
        }, I.prototype.listenerCount = function (e) {
          return this.listeners(e).length
        }, I.prototype.hasListeners = function (e) {
          if (this.wildcard) {
            var n = [],
              i = "string" == typeof e ? e.split(this.delimiter) : e.slice();
            return k.call(this, n, i, this.listenerTree, 0), n.length > 0
          }
          var r = this._events,
            o = this._all;
          return !!(o && o.length || r && (e === t ? c(r).length : r[e]))
        }, I.prototype.listenersAny = function () {
          return this._all ? this._all : []
        }, I.prototype.waitFor = function (e, n) {
          var i = this,
            r = typeof n;
          return "number" === r ? n = {
            timeout: n
          } : "function" === r && (n = {
            filter: n
          }), w((n = v(n, {
            timeout: 0,
            filter: t,
            handleError: !1,
            Promise: Promise,
            overload: !1
          }, {
            filter: _,
            Promise: g
          })).Promise, (function (t, r, o) {
            function a() {
              var o = n.filter;
              if (!o || o.apply(i, arguments))
                if (i.off(e, a), n.handleError) {
                  var s = arguments[0];
                  s ? r(s) : t(d.apply(null, arguments).slice(1))
                } else t(d.apply(null, arguments))
            }
            o((function () {
              i.off(e, a)
            })), i._on(e, a, !1)
          }), {
            timeout: n.timeout,
            overload: n.overload
          })
        };
      var O = I.prototype;
      Object.defineProperties(I, {
        defaultMaxListeners: {
          get: function () {
            return O._maxListeners
          },
          set: function (e) {
            if ("number" != typeof e || e < 0 || Number.isNaN(e)) throw TypeError(
              "n must be a non-negative number");
            O._maxListeners = e
          },
          enumerable: !0
        },
        once: {
          value: function (e, t, n) {
            return w((n = v(n, {
              Promise: Promise,
              timeout: 0,
              overload: !1
            }, {
              Promise: g
            })).Promise, (function (n, i, r) {
              var o;
              if ("function" == typeof e.addEventListener) return o =
                function () {
                  n(d.apply(null, arguments))
                }, r((function () {
                  e.removeEventListener(t, o)
                })), void e.addEventListener(t, o, {
                  once: !0
                });
              var a, s = function () {
                a && e.removeListener("error", a), n(d.apply(null,
                  arguments))
              };
              "error" !== t && (a = function (n) {
                e.removeListener(t, s), i(n)
              }, e.once("error", a)), r((function () {
                a && e.removeListener("error", a), e.removeListener(t,
                  s)
              })), e.once(t, s)
            }), {
              timeout: n.timeout,
              overload: n.overload
            })
          },
          writable: !0,
          configurable: !0
        }
      }), Object.defineProperties(O, {
        _maxListeners: {
          value: 10,
          writable: !0,
          configurable: !0
        },
        _observers: {
          value: null,
          writable: !0,
          configurable: !0
        }
      }), e.exports = I
    }()
  }));

  function Zi(e, t, n) {
    return void 0 === n && (n = !1), n ? {
      key: e,
      value: t
    } : {
      key: e,
      base64: Ui(t)
    }
  }

  function er(e) {
    if (null != e) {
      if ("base64" in e) return Wi(e.base64);
      if ("value" in e) return e.value
    }
  }

  function tr(e) {
    return function (t) {
      return Object.prototype.toString.call(t).toLowerCase() === ("[object " + e + "]")
        .toLowerCase()
    }
  }

  function nr(e) {
    if (!tr("Object")(e) || !e.__nativeBuffers__) return e;
    var t = e.__nativeBuffers__;
    delete e.__nativeBuffers__;
    for (var n, i = l(t); !(n = i()).done;) {
      var r = n.value;
      if (r) {
        var o = er(r);
        void 0 !== o && o instanceof ArrayBuffer && (e[r.key] = o)
      }
    }
    return e
  }
  var ir, rr = {
    pack: function (e, t) {
      if (void 0 === t && (t = !1), !tr("Object")(e)) return e;
      var n = [];
      for (var i in e) {
        var r = e[i];
        void 0 !== r && r instanceof ArrayBuffer && void 0 !== r.byteLength && n.push(Zi(i,
          r, t))
      }
      if (n.length > 0) {
        for (var o = 0; o < n.length; o++) {
          delete e[n[o].key]
        }
        e.__nativeBuffers__ = n
      }
      return e
    },
    unpack: nr,
    unpack2: nr
  };
  ! function (e) {
    e[e.NONE = 0] = "NONE", e[e.INITED = 1] = "INITED", e[e.DESTROYED = 2] = "DESTROYED"
  }(ir || (ir = {}));
  var or = function () {
    function e() {
      this._lifeState = ir.NONE, this._setupHandlers = [], this._teardownHandlers = []
    }
    var t = e.prototype;
    return t.setup = function () {
      var e = this;
      this._setupHandlers.forEach((function (t) {
        var n = t();
        "function" == typeof n && e._teardownHandlers.push(n)
      }))
    }, t.teardown = function () {
      this._teardownHandlers.forEach((function (e) {
        return e()
      }))
    }, t.addSetupHandler = function (e) {
      this._setupHandlers.push(e)
    }, t.addTeardownHandler = function (e) {
      this._teardownHandlers.push(e)
    }, t._runSetup = function () {
      this._lifeState === ir.NONE && (this._lifeState = ir.INITED, this.setup())
    }, t._runTeardown = function () {
      this._lifeState === ir.INITED && (this._lifeState = ir.DESTROYED, this.teardown())
    }, q(e, [{
      key: "isReady",
      get: function () {
        return this._lifeState === ir.INITED
      }
    }, {
      key: "isDestroyed",
      get: function () {
        return this._lifeState === ir.DESTROYED
      }
    }]), e
  }();

  function ar(e) {
    e._runSetup()
  }
  var sr, cr, ur = "mp_app_onlaunch_beigin",
    lr = "mp_app_onlaunch_end",
    fr = "mp_app_onshow_begin",
    dr = "mp_app_onshow_end",
    pr = "mp_page_first_set_data",
    hr = "appservice_create_page_start",
    vr = "appservice_create_page_end",
    gr = "appservice_on_app_route",
    mr = "mp_block_first_set_data",
    _r = "appservice_create_block_start",
    yr = "appservice_create_block_end",
    wr = "pageFrame_first_get_data",
    br = "pageFrame_get_screen_cache_data",
    kr = "pageFrame_cache_dirty_data",
    Sr = "webview_evaluateJavascript_begin",
    Cr = "webview_evaluateJavascript_end",
    Er = "pageFrame_receive_render_snapshot_message",
    zr = "pageFrame_func_ready",
    Tr = "pageFrame_snapshot_frame_show",
    Ir = "pageFrame_cache_re_render_end",
    Or = "pageFrame_first_render_begin",
    xr = "pageFrame_first_render_end",
    Ar = "pageFrame_create_virtual_tree_begin",
    Lr = "pageFrame_create_virtual_tree_end",
    jr = "pageFrame_create_element_tree_begin",
    Pr = "pageFrame_create_element_tree_end",
    Dr = "pageFrame_apply_child_to_body_begin",
    Nr = "pageFrame_apply_child_to_body_end",
    Mr = "pageFrame_first_frame_show",
    Br = "pageFrame_cache_create_virtual_tree_begin",
    Fr = "pageFrame_cache_diff_virtual_tree_begin",
    Rr = "pageFrame_cache_virtual_patch_begin",
    Vr = "pageFrame_cache_virtual_patch_end",
    Ur = "pageFrame_cache_dirty_path",
    Wr = "pageFrame_cache_re_render_begin",
    qr = "pageFrame_cache_json_diff_end",
    Jr = "pageFrame_app_fmp",
    Hr = "mp_app_onhide_count_dom",
    Kr = "js.open_platform.gadget",
    Qr = "js.open_platform.blockit",
    Gr = Kr,
    $r = ((sr = {
      appServiceSDKScriptError: {
        domain: Gr + ".js_runtime_error",
        code: 10001,
        level: M.error,
        message: "app_service_sdk_script_error"
      },
      webviewSDKScriptError: {
        domain: Gr + ".js_runtime_error",
        code: 10002,
        level: M.error,
        message: "webview_sdk_script_error"
      },
      jsEngineScriptError: {
        domain: Gr + ".js_runtime_error",
        code: 10003,
        level: M.error,
        message: "js_engine_script_error"
      },
      thirdScriptError: {
        domain: Gr + ".js_runtime_error",
        code: 10004,
        level: M.warn,
        message: "third_script_error"
      },
      webviewScriptError: {
        domain: Gr + ".js_runtime_error",
        code: 10005,
        level: M.error,
        message: "webview_script_error"
      },
      parserScriptError: {
        domain: Gr + ".js_runtime_error",
        code: 10006,
        level: M.error,
        message: "parser_script_error"
      },
      loadScriptError: {
        domain: Gr + ".js_runtime_error",
        code: 10007,
        level: M.error,
        message: "load_script_error"
      },
      commentWarn: {
        domain: Gr + ".js_runtime_error",
        code: 10008,
        level: M.warn,
        message: "comment_warn"
      },
      webviewSetPropertyError: {
        domain: Gr + ".js_runtime_error",
        code: 10009,
        level: M.error,
        message: "webview_set_property_error"
      },
      unknownError: {
        domain: Gr + ".js_runtime_error",
        code: 10010,
        level: M.error,
        message: "unknown_error"
      }
    })[ur] = {
      domain: Gr,
      code: 1e4,
      level: M.normal,
      message: "mp_app_onlaunch_begin"
    }, sr[lr] = {
      domain: Gr,
      code: 10001,
      level: M.normal,
      message: "mp_app_onlaunch_end"
    }, sr[fr] = {
      domain: Gr,
      code: 10002,
      level: M.normal,
      message: "mp_app_onshow_begin"
    }, sr[dr] = {
      domain: Gr,
      code: 10003,
      level: M.normal,
      message: "mp_app_onshow_end"
    }, sr[pr] = {
      domain: Gr,
      code: 10004,
      level: M.normal,
      message: "mp_page_first_set_data"
    }, sr[hr] = {
      domain: Gr,
      code: 10005,
      level: M.normal,
      message: "appservice_create_page_start"
    }, sr[vr] = {
      domain: Gr,
      code: 10006,
      level: M.normal,
      message: "appservice_create_page_end"
    }, sr[gr] = {
      domain: Gr,
      code: 10007,
      level: M.normal,
      message: "appservice_on_app_route"
    }, sr[wr] = {
      domain: Gr,
      code: 10008,
      level: M.normal,
      message: "pageframe_first_get_data"
    }, sr[br] = {
      domain: Gr,
      code: 10009,
      level: M.normal,
      message: "pageframe_get_screen_cache_data"
    }, sr[kr] = {
      domain: Gr,
      code: 10010,
      level: M.normal,
      message: "pageframe_cache_dirty_data"
    }, sr[Sr] = {
      domain: Gr,
      code: 10011,
      level: M.normal,
      message: "webview_evaluateJavascript_begin"
    }, sr[Cr] = {
      domain: Gr,
      code: 10012,
      level: M.normal,
      message: "webview_evaluateJavascript_end"
    }, sr[Er] = {
      domain: Gr,
      code: 10013,
      level: M.normal,
      message: "pageframe_receive_render_snapshot_message"
    }, sr[zr] = {
      domain: Gr,
      code: 10014,
      level: M.normal,
      message: "pageframe_func_ready"
    }, sr[Tr] = {
      domain: Gr,
      code: 10015,
      level: M.normal,
      message: "pageframe_snapshot_frame_show"
    }, sr[Ir] = {
      domain: Gr,
      code: 10016,
      level: M.normal,
      message: "pageframe_cache_re_render_end"
    }, sr[Or] = {
      domain: Gr,
      code: 10017,
      level: M.normal,
      message: "pageframe_first_render_begin"
    }, sr[xr] = {
      domain: Gr,
      code: 10018,
      level: M.normal,
      message: "pageFrame_first_render_end"
    }, sr[Ar] = {
      domain: Gr,
      code: 10019,
      level: M.normal,
      message: "pageframe_create_virtual_tree_begin"
    }, sr[Lr] = {
      domain: Gr,
      code: 10020,
      level: M.normal,
      message: "pageframe_create_virtual_tree_end"
    }, sr[jr] = {
      domain: Gr,
      code: 10021,
      level: M.normal,
      message: "pageframe_create_element_tree_begin"
    }, sr[Pr] = {
      domain: Gr,
      code: 10022,
      level: M.normal,
      message: "pageframe_create_element_tree_end"
    }, sr[Dr] = {
      domain: Gr,
      code: 10023,
      level: M.normal,
      message: "pageframe_apply_child_to_body_begin"
    }, sr[Nr] = {
      domain: Gr,
      code: 10024,
      level: M.normal,
      message: "pageframe_apply_child_to_body_end"
    }, sr[Mr] = {
      domain: Gr,
      code: 10025,
      level: M.normal,
      message: "pageframe_first_frame_show"
    }, sr[Br] = {
      domain: Gr,
      code: 10026,
      level: M.normal,
      message: "pageframe_cache_create_virtual_tree_begin"
    }, sr[Fr] = {
      domain: Gr,
      code: 10027,
      level: M.normal,
      message: "pageframe_cache_diff_virtual_tree_begin"
    }, sr[Rr] = {
      domain: Gr,
      code: 10028,
      level: M.normal,
      message: "pageframe_cache_virtual_patch_begin"
    }, sr[Vr] = {
      domain: Gr,
      code: 10029,
      level: M.normal,
      message: "pageframe_cache_virtual_patch_end"
    }, sr[Ur] = {
      domain: Gr,
      code: 10030,
      level: M.normal,
      message: "pageframe_cache_dirty_path"
    }, sr[Wr] = {
      domain: Gr,
      code: 10031,
      level: M.normal,
      message: "pageframe_cache_re_render_begin"
    }, sr[qr] = {
      domain: Gr,
      code: 10032,
      level: M.normal,
      message: "pageframe_cache_json_diff_end"
    }, sr[Jr] = {
      domain: Gr,
      code: 10033,
      level: M.normal,
      message: "pageFrame_app_fmp"
    }, sr[Hr] = {
      domain: Gr,
      code: 10034,
      level: M.normal,
      message: "mp_app_onhide_count_dom"
    }, sr.webview_longest_pubsub = {
      domain: Kr + ".jssdk_performance",
      code: 10033,
      level: M.normal,
      message: "webview_longest_pubsub"
    }, sr.webview_slowest_pubsub = {
      domain: Kr + ".jssdk_performance",
      code: 10034,
      level: M.normal,
      message: "webview_slowest_pubsub"
    }, sr.webview_pubsub_length_statistic = {
      domain: Kr + ".jssdk_performance",
      code: 10035,
      level: M.normal,
      message: "webview_pubsub_lenth_statistic"
    }, sr.webview_pubsub_timecost_statistic = {
      domain: Kr + ".jssdk_performance",
      code: 10036,
      level: M.normal,
      message: "webview_pubsub_timecost_statistic"
    }, sr.appservice_longest_pubsub = {
      domain: Kr + ".jssdk_performance",
      code: 10037,
      level: M.normal,
      message: "appservice_longest_pubsub"
    }, sr.appservice_slowest_pubsub = {
      domain: Kr + ".jssdk_performance",
      code: 10038,
      level: M.normal,
      message: "appservice_slowest_pubsub"
    }, sr.appservice_pubsub_length_statistic = {
      domain: Kr + ".jssdk_performance",
      code: 10039,
      level: M.normal,
      message: "appservice_pubsub_lenth_statistic"
    }, sr.appservice_pubsub_timecost_statistic = {
      domain: Kr + ".jssdk_performance",
      code: 10040,
      level: M.normal,
      message: "appservice_pubsub_timecost_statistic"
    }, sr.webview_work_time_ratio = {
      domain: Kr + ".jssdk_performance",
      code: 10041,
      level: M.normal,
      message: "webview_work_time_ratio"
    }, sr.appservice_work_time_ratio = {
      domain: Kr + ".jssdk_performance",
      code: 10042,
      level: M.normal,
      message: "appservice_work_time_ratio"
    }, sr.appservice_reported_tti = {
      domain: Kr + ".jssdk_performance",
      code: 10043,
      level: M.normal,
      message: "appservice_reported_tti"
    }, sr.webview_detected_tti = {
      domain: Kr + ".jssdk_performance",
      code: 10044,
      level: M.normal,
      message: "webview_detected_tti"
    }, sr.appservice_using_v8_port = {
      domain: Kr + ".jssdk_performance",
      code: 10045,
      level: M.normal,
      message: "appservice_using_v8_port"
    }, sr.undefined_default_code = {
      domain: Gr,
      code: 1e4,
      level: M.warn,
      message: "undefined_default_code"
    }, sr.api_success = {
      domain: Gr + ".api",
      code: 1e4,
      level: M.normal,
      message: "api_success"
    }, sr.api_cancel = {
      domain: Gr + ".api",
      code: 10001,
      level: M.warn,
      message: "api_cancel"
    }, sr.api_fail = {
      domain: Gr + ".api",
      code: 10002,
      level: M.error,
      message: "api_fail"
    }, sr.api_timeout = {
      domain: Gr + ".api",
      code: 10003,
      level: M.error,
      message: "api_timeout"
    }, sr.api_queue_timeout = {
      domain: Gr + ".api",
      code: 10004,
      level: M.warn,
      message: "api_queue_timeout"
    }, sr.api_invoke = {
      domain: "client.open_platform.api.common",
      code: 1e4,
      level: M.normal,
      message: "api_jssdk_invoke"
    }, sr.api_callback = {
      domain: "client.open_platform.api.common",
      code: 10001,
      level: M.normal,
      message: "api_jssdk_callback"
    }, sr.customComponent_performance = {
      domain: Gr,
      code: 1e4,
      level: M.normal,
      message: "customComponent_performance"
    }, sr.request_result = {
      domain: Gr + ".network",
      code: 1e4,
      level: M.normal,
      message: "request_result"
    }, sr.abort_request = {
      domain: Gr + ".network",
      code: 10001,
      level: M.normal,
      message: "abort_request"
    }, sr.upload_result = {
      domain: Gr + ".network",
      code: 10002,
      level: M.normal,
      message: "upload_result"
    }, sr.abort_upload = {
      domain: Gr + ".network",
      code: 10003,
      level: M.normal,
      message: "abort_upload"
    }, sr.download_result = {
      domain: Gr + ".network",
      code: 10004,
      level: M.normal,
      message: "download_result"
    }, sr.abort_download = {
      domain: Gr + ".network",
      code: 10005,
      level: M.normal,
      message: "abort_download"
    }, sr.request_waiting = {
      domain: Gr + ".network",
      code: 10006,
      level: M.normal,
      message: "request_enter_waiting_queue"
    }, sr.request_long_waiting = {
      domain: Gr + ".network",
      code: 10007,
      level: M.warn,
      message: "request_waiting_queue_long_time_stopped"
    }, sr.socket_open = {
      domain: Gr + ".network",
      code: 10008,
      level: M.normal,
      message: "socket_open"
    }, sr.socket_close = {
      domain: Gr + ".network",
      code: 10009,
      level: M.normal,
      message: "socket_close"
    }, sr.socket_reach_max_count = {
      domain: Gr + ".network",
      code: 10010,
      level: M.warn,
      message: "socket_reach_max_count"
    }, sr.editorInit = {
      domain: Gr + ".editor",
      code: 10001,
      level: M.normal,
      message: "editor_start"
    }, sr.editorPublish = {
      domain: Gr + ".editor",
      code: 10002,
      level: M.normal,
      message: "editor_publish"
    }, sr.editorSubscribe = {
      domain: Gr + ".editor",
      code: 10003,
      level: M.normal,
      message: "editor_subscribe"
    }, sr.canvasDrawTime = {
      domain: Gr + ".canvas",
      code: 1e4,
      level: M.normal,
      message: "canvas_draw_time"
    }, sr.webviewRenderTime = {
      domain: Gr + ".webview_render",
      code: 1e4,
      level: M.normal,
      message: "webview_render_time"
    }, sr.webviewSeriesRenderTime = {
      domain: Gr + ".webview_render",
      code: 10001,
      level: M.normal,
      message: "webview_series_render_time"
    }, sr.webviewBatchRenderTime = {
      domain: Gr + ".webview_render",
      code: 10002,
      level: M.normal,
      message: "webview_batch_render_time"
    }, sr.webviewComponentUsage = {
      domain: Gr + ".webview_render",
      code: 10003,
      level: M.normal,
      message: "webview_component_usage"
    }, sr.onUpdateReady = {
      domain: Gr + ".mp_trigger_on_event",
      code: 1e4,
      level: M.normal,
      message: "trigger onUpdateReady"
    }, sr.mpPageView = {
      domain: Gr + ".mp_page_view",
      code: 1e4,
      level: M.normal,
      message: "mp page view"
    }, sr.iframeOfflineFileLoadStart = {
      domain: Gr + ".tt_iframe_offline",
      code: 1e4,
      level: M.normal,
      message: "tt-iframe offline file load start"
    }, sr.iframeOfflineFileLoadFail = {
      domain: Gr + ".tt_iframe_offline",
      code: 10001,
      level: M.error,
      message: "tt-iframe offline file load fail"
    }, sr);
  (cr = {
    user_click: {
      domain: Qr + ".event_tracking",
      code: 1e4,
      level: M.normal,
      message: "user_click"
    },
    block_display: {
      domain: Qr + ".event_tracking",
      code: 10001,
      level: M.normal,
      message: "block_display"
    }
  })[mr] = {
    domain: Gr,
    code: 10046,
    level: M.normal,
    message: "mp_block_first_set_data"
  }, cr[_r] = {
    domain: Gr,
    code: 10047,
    level: M.normal,
    message: "appservice_create_block_start"
  }, cr[yr] = {
    domain: Gr,
    code: 10048,
    level: M.normal,
    message: "appservice_create_block_end"
  };
  var Xr, Yr, Zr, eo = {
      jsError: "mp_js_runtime_error",
      executeProcess: "mp_execute_process",
      api: "mp_api_invoke_result",
      onCallback: "mp_trigger_on_event",
      analysis: "op_js_analysis",
      network: "op_js_network",
      editorAnalysis: "editor",
      canvas: "op_api_canvas",
      api_invoke: "op_api_invoke",
      detectedTTI: "mp_js_sdk_detected_tti",
      reportedTTI: "mp_js_sdk_reported_tti",
      webviewSlowPubsub: "mp_js_sdk_slow_pubsub_webview",
      serviceSlowPubsub: "mp_js_sdk_slow_pubsub_service",
      webviewPubsubTimeStat: "mp_js_sdk_pubsub_stat_webview",
      servicePubsubTimeStat: "mp_js_sdk_pubsub_stat_service",
      webviewRender: "op_js_webview_render",
      openplatformMpPageView: "openplatform_mp_page_view",
      iframeOfflineFile: "openplatform_iframe_offline_file"
    },
    to = function (e) {
      function t() {
        return e.apply(this, arguments) || this
      }
      return S(t, e), t.prototype.addMap = function (t) {
        return e.prototype.addMap.call(this, t)
      }, t
    }(de),
    no = function (e, t) {
      return new to(e ? {
        name: e,
        code: new fe(t)
      } : {
        name: "undefined_default_code",
        code: new fe($r.undefined_default_code)
      })
    };
  ! function (e) {
    e[e.NOT_STARTED = 1] = "NOT_STARTED", e[e.ACCOUNTING = 2] = "ACCOUNTING", e[e
      .WAITING_FOR_CLOSE = 3] = "WAITING_FOR_CLOSE", e[e.CLOSED = 4] = "CLOSED"
  }(Xr || (Xr = {})),
  function (e) {
    e[e.TTI_CALLED = 1] = "TTI_CALLED", e[e.TTI_DETECTED = 2] = "TTI_DETECTED"
  }(Yr || (Yr = {})),
  function (e) {
    e.MOBILE = "mobile", e.PC = "pc", e.PC_SIDEBAR = "pc_sidebar", e.PC_WINDOW = "pc_window", e
      .IDE = "lark_ide"
  }(Zr || (Zr = {}));
  var io = n((function (e) {
      function t(n) {
        return e.exports = t = Object.setPrototypeOf ? Object.getPrototypeOf : function (
        e) {
          return e.__proto__ || Object.getPrototypeOf(e)
        }, e.exports.default = e.exports, e.exports.__esModule = !0, t(n)
      }
      e.exports = t, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    ro = t(io),
    oo = n((function (e) {
      e.exports = function (e) {
        return -1 !== Function.toString.call(e).indexOf("[native code]")
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    ao = n((function (e) {
      e.exports = function () {
        if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
        if (Reflect.construct.sham) return !1;
        if ("function" == typeof Proxy) return !0;
        try {
          return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], (
          function () {}))), !0
        } catch (e) {
          return !1
        }
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    so = n((function (e) {
      function t(n, i, r) {
        return ao() ? (e.exports = t = Reflect.construct, e.exports.default = e.exports, e
          .exports.__esModule = !0) : (e.exports = t = function (e, t, n) {
          var i = [null];
          i.push.apply(i, t);
          var r = new(Function.bind.apply(e, i));
          return n && k(r, n.prototype), r
        }, e.exports.default = e.exports, e.exports.__esModule = !0), t.apply(null,
          arguments)
      }
      e.exports = t, e.exports.default = e.exports, e.exports.__esModule = !0
    }));
  var co, uo, lo, fo, po = function (e) {
      function t(t, n) {
        var i;
        return i = e.call(this, t) || this,
          function (e, t) {
            t && "cause" in t && Object.defineProperty(e, "cause", {
              writable: !0,
              configurable: !0,
              enumerable: !1,
              value: t.cause
            })
          }(Ii(i), n), i
      }
      return S(t, e), t.is = function (e) {
        return e instanceof t
      }, t
    }(t(n((function (e) {
      function t(n) {
        var i = "function" == typeof Map ? new Map : void 0;
        return e.exports = t = function (e) {
          if (null === e || !oo(e)) return e;
          if ("function" != typeof e) throw new TypeError(
            "Super expression must either be null or a function");
          if (void 0 !== i) {
            if (i.has(e)) return i.get(e);
            i.set(e, t)
          }

          function t() {
            return so(e, arguments, io(this).constructor)
          }
          return t.prototype = Object.create(e.prototype, {
            constructor: {
              value: t,
              enumerable: !1,
              writable: !0,
              configurable: !0
            }
          }), k(t, e)
        }, e.exports.default = e.exports, e.exports.__esModule = !0, t(n)
      }
      e.exports = t, e.exports.default = e.exports, e.exports.__esModule = !0
    })))(Error)),
    ho = function (e) {
      function t() {
        for (var t, n = arguments.length, i = new Array(n), r = 0; r < n; r++) i[r] = arguments[
          r];
        return (t = e.call.apply(e, [this].concat(i)) || this).name = "ThirdScriptError", t
      }
      return S(t, e), t.is = function (e) {
        return e instanceof Error && "ThirdScriptError" === e.name
      }, q(t, [{
        key: "monitorCode",
        get: function () {
          return $r.thirdScriptError
        }
      }]), t
    }(po),
    vo = function (e) {
      function t() {
        for (var t, n = arguments.length, i = new Array(n), r = 0; r < n; r++) i[r] = arguments[
          r];
        return (t = e.call.apply(e, [this].concat(i)) || this).name = "ServiceError", t
      }
      return S(t, e), t.is = function (e) {
        return e instanceof Error && "ServiceError" === e.name
      }, q(t, [{
        key: "monitorCode",
        get: function () {
          return $r.appServiceSDKScriptError
        }
      }]), t
    }(po);
  ! function (e) {
    e.GADGET = "GADGET", e.BLOCKIT = "BLOCKIT"
  }(co || (co = {})),
  function (e) {
    e.TAP_SHARE_BUTTON = "tapShareButton", e.ON_DESTROY_WEBVIEW = "onDestroyWebview", e
      .ON_PAGE_RELOAD = "onPageReload", e.ON_PULL_DOWN_REFRESH = "onPullDownRefresh", e
      .ON_SHARE_APP_MESSAGE = "onShareAppMessage", e.ON_SAVE_EXIT_STATE = "onSaveExitState", e
      .ON_APP_ENTER_BACKGROUND = "onAppEnterBackground", e.ON_APP_ENTER_FOREGROUND =
      "onAppEnterForeground", e.ON_APP_LAUNCH = "onAppLaunch", e.ON_APP_ROUTE = "onAppRoute"
  }(uo || (uo = {})),
  function (e) {
    e.NAVIGATE_TO = "navigateTo", e.REDIRECT_TO = "redirectTo", e.NAVIGATE_BACK =
      "navigateBack", e.SWITCH_TAB = "switchTab", e.APP_LAUNCH = "appLaunch", e.RE_LAUNCH =
      "reLaunch", e.AUTO_RELAUNCH = "autoReLaunch", e.RESTORE_TO = "restoreTo", e
      .APP_RESTORE_LAUNCH = "appRestoreLaunch", e.APP_ROUTE_DESTROY = "appRouteDestroy"
  }(lo || (lo = {})),
  function (e) {
    e.DEFAULT = "default", e.CLONE = "clone", e.ACTIVATE = "activate"
  }(fo || (fo = {})),
  function () {
    function e(e, t) {
      return null != t && e instanceof t
    }
    var t, n, i;
    try {
      t = Map
    } catch (e) {
      t = function () {}
    }
    try {
      n = Set
    } catch (e) {
      n = function () {}
    }
    try {
      i = Promise
    } catch (e) {
      i = function () {}
    }

    function r(o, s, c, u, l) {
      "object" == typeof s && (c = s.depth, u = s.prototype, l = s.includeNonEnumerable, s = s
        .circular);
      var f = [],
        d = [],
        p = "undefined" != typeof Buffer;
      return void 0 === s && (s = !0), void 0 === c && (c = 1 / 0),
        function o(c, h) {
          if (null === c) return null;
          if (0 === h) return c;
          var v, g;
          if ("object" != typeof c) return c;
          if (e(c, t)) v = new t;
          else if (e(c, n)) v = new n;
          else if (e(c, i)) v = {};
          else if (r.__isArray(c)) v = [];
          else if (r.__isRegExp(c)) v = new RegExp(c.source, a(c)), c.lastIndex && (v
            .lastIndex = c.lastIndex);
          else if (r.__isDate(c)) v = new Date(c.getTime());
          else {
            if (p && Buffer.isBuffer(c)) return v = Buffer.allocUnsafe ? Buffer.allocUnsafe(c
              .length) : Buffer.from(c.length), c.copy(v), v;
            e(c, Error) ? v = Object.create(c) : void 0 === u ? (g = Object.getPrototypeOf(c),
              v = Object.create(g)) : (v = Object.create(u), g = u)
          }
          if (s) {
            var m = f.indexOf(c);
            if (-1 !== m) return d[m];
            f.push(c), d.push(v)
          }
          for (var _ in e(c, t) && c.forEach((function (e, t) {
              var n = o(t, h - 1),
                i = o(e, h - 1);
              v.set(n, i)
            })), e(c, n) && c.forEach((function (e) {
              var t = o(e, h - 1);
              v.add(t)
            })), c) {
            var y = void 0;
            g && (y = Object.getOwnPropertyDescriptor(g, _)), y && null == y.set || (v[_] = o(c[
              _], h - 1))
          }
          if (Object.getOwnPropertySymbols)
            for (var w = Object.getOwnPropertySymbols(c), b = 0; b < w.length; b++) {
              var k = w[b],
                S = Object.getOwnPropertyDescriptor(c, k);
              (!S || S.enumerable || l) && (v[k] = o(c[k], h - 1), S.enumerable || Object
                .defineProperty(v, k, {
                  enumerable: !1
                }))
            }
          if (l)
            for (var C = Object.getOwnPropertyNames(c), E = 0; E < C.length; E++) {
              var z = C[E],
                T = Object.getOwnPropertyDescriptor(c, z);
              null != T && T.enumerable || (v[z] = o(c[z], h - 1), Object.defineProperty(v, z, {
                enumerable: !1
              }))
            }
          return v
        }(o, c)
    }

    function o(e) {
      return Object.prototype.toString.call(e)
    }

    function a(e) {
      var t = "";
      return e.global && (t += "g"), e.ignoreCase && (t += "i"), e.multiline && (t += "m"), t
    }
    r.clonePrototype = function (e) {
      if (null === e) return null;
      var t = function () {};
      return t.prototype = e, new t
    }, r.__objToStr = o, r.__isDate = function (e) {
      return "object" == typeof e && "[object Date]" === o(e)
    }, r.__isArray = function (e) {
      return "object" == typeof e && "[object Array]" === o(e)
    }, r.__isRegExp = function (e) {
      return "object" == typeof e && "[object RegExp]" === o(e)
    }, r.__getRegExpFlags = a
  }();
  var go = Xi({
    wrap: function (e, t) {
      return new ho(t, {
        cause: e
      })
    },
    reporter: function (e, t) {
      var n = t.data;
      null != n && n.dispatchUnhandledError ? n.dispatchUnhandledError(e) : Gi.error(e)
    }
  })[1];

  function mo(e) {
    return Object.prototype.toString.call(e).slice(8, -1)
  }

  function _o(e) {
    return "Function" === mo(e)
  }

  function yo(e, t) {
    for (var n in t) {
      var i = t[n];
      if (i.required && !e.hasOwnProperty(n)) return n + " should not be undefined";
      if (e.hasOwnProperty(n)) {
        var r = mo(e[n]),
          o = i.type;
        if (r !== o) return n + " should pass " + o + ", not " + r
      }
    }
    return !1
  }

  function wo() {
    return "Object" === mo(TMAConfig) ? TMAConfig.platform : ""
  }

  function bo(e, t) {
    if (void 0 === t && (t = !1), void 0 === e || "object" != typeof e) return e;
    var n = [];
    for (var i in e)
      if (e.hasOwnProperty(i))
        if (t) try {
          n.push(encodeURIComponent(i) + "=" + encodeURIComponent(e[i]))
        } catch (t) {
          n.push(i + "=" + e[i])
        } else n.push(i + "=" + e[i]);
    return n.join("&")
  }

  function ko() {
    for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++) t[n] = arguments[n];
    return t.reduce((function (e, t) {
      for (var n in t) e[n] = t[n];
      return e
    }), {})
  }
  var So = Xi({
    wrap: function (e, t) {
      return po.is(e) ? e : new vo(t, {
        cause: e
      })
    },
    action: "throw"
  })[1];

  function Co(e, t) {
    return So(e, {
      errMsg: t,
      context: e
    })
  }

  function Eo(e, t) {
    return go(e, {
      errMsg: t,
      context: e
    })
  }

  function zo(e, t, n) {
    var i = new WeakMap;
    return {
      on: function (r) {
        if ("function" == typeof r) {
          var o = i.get(r) || (n ? n(r) : r);
          i.set(r, o), e.on(t, o)
        }
      },
      off: function (n) {
        if (void 0 === n) e.removeAllListeners(t);
        else if ("function" == typeof n) {
          var r = i.get(n);
          r && e.off(t, r)
        }
      }
    }
  }
  Co((function (e) {
    var t = Object.prototype.toString.call(e).split(" ")[1].split("]")[0];
    return {
      data: e = "Array" === t || "Object" === t ? JSON.stringify(e) : "String" === t ||
        "Number" === t || "Boolean" === t ? e.toString() : "Date" === t ? e.getTime()
        .toString() : "Undefined" === t ? "undefined" : "Null" === t ? "null" : "",
      dataType: t
    }
  }), "anyTypeToString"), Co((function (e, t) {
    switch (t) {
      case "String":
        return e;
      case "Array":
      case "Object":
        return JSON.parse(e);
      case "Number":
        return parseFloat(e);
      case "Boolean":
        return "true" === e;
      case "Date":
        return new Date(parseInt(e));
      case "Undefined":
        return;
      case "Null":
        return null;
      default:
        return ""
    }
  }), "stringToAnyType");
  var To = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var Io = 0;

  function Oo() {
    var e = String(Date.now()).slice(-13);
    return (void 0).agent.randomDeviceId, "02" + e + function (e) {
      void 0 === e && (e = 20);
      for (var t = "", n = 0; n < e; n++) {
        var i = Math.floor(Math.random() * To.length);
        t += To[i]
      }
      return t
    }(32) + (Io++ % 2176782336).toString(32).padStart(6, "0")
  }
  var xo = function (e) {
      return e.replace(/[^\u0000-\u00ff]/g, "aa").length
    },
    Ao = ["success", "fail", "cancel", "complete"];

  function Lo(e, t, n) {
    si.invoke(e, t, n)
  }

  function jo(e, t) {
    si.subscribe(e, e, t)
  }

  function Po(e, t, n) {
    void 0 === t && (t = {});
    var i = t,
      r = i.success,
      o = i.fail,
      a = i.cancel,
      s = i.complete,
      c = f(i, Ao),
      u = Object.assign({}, c),
      l = function (e) {
        null == s || s(e), null == n || null == n.afterAll || n.afterAll(e)
      };
    u.success = function (e) {
      null == n || null == n.beforeAll || n.beforeAll(e), null == n || null == n
        .beforeSuccess || n.beforeSuccess(e), null == r || r(e), null == n || null == n
        .afterSuccess || n.afterSuccess(e), l(e)
    }, u.fail = function (e) {
      null == n || null == n.beforeAll || n.beforeAll(e), null == n || null == n.beforeFail ||
        n.beforeFail(e), null == o || o(e), null == n || null == n.afterFail || n.afterFail(
        e), l(e)
    }, u.cancel = function (e) {
      null == n || null == n.beforeAll || n.beforeAll(e), null == o || o(e), null == n ||
        null == n.beforeCancel || n.beforeCancel(e), null == a || a(e), null == n || null == n
        .afterCancel || n.afterCancel(e), null == s || s(e), l(e)
    }, Lo(e, u)
  }

  function Do(e, t) {
    jo(e, t)
  }

  function No() {}

  function Mo(e) {
    return Object.prototype.toString.call(e).slice(8, -1)
  }
  var Bo = function e(t, n, i) {
      void 0 === i && (i = "param");
      var r = Mo(t),
        o = Mo(n);
      if (r !== o) return i + " should pass " + o + ", not " + r;
      var a = "";
      switch (o) {
        case "Object":
          for (var s in n) Object.prototype.hasOwnProperty.call(n, s) && (a += e(t[s], n[s], i +
            "." + s));
          break;
        case "Array":
          if (t.length < n.length) return i + " should have more than " + n.length +
            " items, not " + t.length + " items";
          for (var c = 0; c < n.length; ++c) a += e(t[c], n[c], i + "[" + c + "]")
      }
      return a
    },
    Fo = function (e, t, n) {
      var i = Bo(t, n);
      return !i || (Ro(e, t, i), !1)
    },
    Ro = function (e, t, n) {
      void 0 === t && (t = {}), void 0 === n && (n = "");
      var i = e + ":fail " + n;
      "function" == typeof t.fail && t.fail({
        errMsg: i
      }), "function" == typeof t.complete && t.complete({
        errMsg: i
      })
    };

  function Vo(e, t, n, i, r, o) {
    void 0 === t && (t = {}), void 0 === n && (n = !0), void 0 === i && (i = ""), void 0 ===
      r && (r = void 0), void 0 === o && (o = {});
    var a = n ? e + ":ok" : e + ":fail " + i,
      s = Eo((n ? t.success : t.fail) || No, "at api " + e + " " + (n ? "success" : "fail") +
        " callback function"),
      c = Eo(t.complete || No, "at api " + e + " complete callback function");
    o.errMsg = a, "number" == typeof r && (o.errCode = r), s(o), c(o)
  }

  function Uo() {}
  var Wo = Object.freeze({
      __proto__: null,
      addHostListener: function () {},
      publish: function () {},
      subscribe: function () {},
      _mockInvokeHandler: function (e) {},
      _unmockInvokeHandler: function () {},
      invoke: Lo,
      on: jo,
      invokeMethod: Po,
      onMethod: Do,
      noop: No,
      beforeInvokeCallback: Vo,
      checkUrlInConfig: Uo,
      beforeInvoke: Fo,
      beforeInvokeFail: Ro
    }),
    qo = ["checkParams", "beforeInvokeHook", "paramsTransformer"],
    Jo = function () {};

  function Ho(e, t) {
    void 0 === t && (t = {});
    var n = t,
      i = n.checkParams,
      r = n.beforeInvokeHook,
      o = n.paramsTransformer,
      a = f(n, qo);
    return function (n) {
      void 0 === n && (n = {});
      var s = new Map;
      if (!i || null != Fo && Fo(e, n, i)) {
        var c = null == r ? void 0 : r(n, s);
        if (void 0 !== c) {
          var u = t,
            l = u.beforeAll,
            f = u.beforeSuccess,
            d = u.afterSuccess,
            p = u.afterAll,
            h = n.success;
          null == l || l(s), null == f || f(c, Jo, s), "function" == typeof h && h(c), null ==
            d || d(c, s), null == p || p(c, s)
        } else Po(e, o ? Object.assign({}, n, o(n, s)) : n, a)
      }
    }
  }

  function Ko(e, t, n) {
    void 0 === n && (n = {});
    var i = n,
      r = i.wrapper,
      o = i.whenListenerExisted,
      a = i.whenListenerEmptied,
      s = i.onlyLatestListen,
      c = i.unique,
      u = new WeakMap,
      l = "emptied";

    function f() {
      "existed" === l ? e.size(t) || (l = "emptied", null == a || a()) : e.size(t) && (l =
        "existed", null == o || o())
    }
    return {
      on: function (n) {
        if ("function" == typeof n) {
          var i = u.get(n);
          if (i || (r ? (i = r(n), u.set(n, i)) : i = n), s) e.off(t);
          else if (c && e.size(t, i) > 0) return;
          e.on(t, i), f()
        }
      },
      off: function (n) {
        if (void 0 === n) e.off(t);
        else if ("function" == typeof n) {
          var i, r = null != (i = u.get(n)) ? i : n;
          r && e.off(t, r)
        }
        f()
      }
    }
  }
  var Qo = function () {
    function e(e) {
      var t;
      void 0 === e && (e = {}), this.events = new Map, this.callbackMap = new WeakMap, this
        .wrapper = void 0, this.wrapper = null != (t = e.wrapper) ? t : null
    }
    var t = e.prototype;
    return t.emit = function (e, t) {
      var n = this.events.get(e);
      null == n || n.forEach((function (e) {
        return e(t)
      }))
    }, t.on = function (e, t) {
      if ("function" == typeof t) {
        var n = this.events.get(e);
        n || (n = [], this.events.set(e, n));
        var i = t;
        if (this.wrapper) {
          var r = this.callbackMap.get(t);
          r ? i = r : (i = this.wrapper(t), this.callbackMap.set(t, i))
        }
        n.push(i)
      }
    }, t.off = function (e, t) {
      if (t) {
        var n = this.wrapper ? this.callbackMap.get(t) : t,
          i = this.events.get(e);
        if ("function" == typeof n && i) {
          var r = i.indexOf(n); - 1 !== r && i.splice(r, 1), 0 === i.length && this.events
            .delete(e)
        }
      } else this.events.delete(e)
    }, t.size = function (e, t) {
      var n = this.events.get(e);
      if (!n) return 0;
      if (!t) return n.length;
      var i = this.wrapper ? this.callbackMap.get(t) : t;
      return "function" != typeof i ? 0 : n.filter((function (e) {
        return e === i
      })).length
    }, e
  }();

  function Go(e) {
    return void 0 === e && (e = {}),
      function (t, n) {
        var i = t.key;
        t.finisher = function (t) {
          t.exportApi(i, e)
        }
      }
  }
  var $o = function (e) {
    S(n, e), n.exportApi = function (e, t) {
      this.hasOwnProperty("exports") || Object.defineProperty(this, "exports", {
        enumerable: !1,
        writable: !0,
        configurable: !0,
        value: this.exports ? this.exports.slice() : []
      }), this.exports.push({
        name: e,
        options: t
      })
    };
    var t = n.prototype;

    function n(t) {
      var n;
      return (n = e.call(this) || this).container = t, n.beforeInvoke = Fo, n
        .beforeInvokeFail = Ro, n.checkUrlInConfig = Uo, n.createInvokeMethodApi = Ho, n
        ._eventEmitter = void 0, n
    }
    return t.createEventApi = function (e, t) {
      return Ko(this.emitter, e, t)
    }, t.createNativeEventApi = function (e, t) {
      var n = this,
        i = "__internal_" + e + "__";
      return this.addSetupHandler((function () {
        n.onMethod(e, (function (e) {
          var r, o = null != (r = null == t || null == t.transformData ?
            void 0 : t.transformData(e)) ? r : e;
          null == t || null == t.whenEventEmit || t.whenEventEmit(o), n.emitter
            .emit(i, e), null == t || null == t.whenEventEmitted || t
            .whenEventEmitted(o)
        }))
      })), Ko(this.emitter, i, t)
    }, q(n, [{
      key: "invokeMethod",
      get: function () {
        return this.container.bridge.invokeMethod
      }
    }, {
      key: "subscribe",
      get: function () {
        return this.container.bridge.subscribe
      }
    }, {
      key: "onMethod",
      get: function () {
        return this.container.bridge.onMethod
      }
    }, {
      key: "publish",
      get: function () {
        return this.container.bridge.publish
      }
    }, {
      key: "emitter",
      get: function () {
        var e = this;
        return this._eventEmitter || (this._eventEmitter = new Qo({
          wrapper: function (t) {
            return go(t, {
              context: null,
              errMsg: "at tt event api",
              data: e.container
            })
          }
        })), this._eventEmitter
      }
    }]), n
  }(or);

  function Xo(e, t) {
    var n = e + "() { [native code] }";
    Object.defineProperty(t, "toString", {
      configurable: !0,
      writable: !0,
      enumerable: !1,
      value: function () {
        return n
      }
    })
  }
  $o.exports = void 0;
  var Yo = function (e) {
      function t(t, n) {
        var i;
        return (i = e.call(this) || this).container = t, i.exports = void 0, i.instance =
          new Map, i.apis = void 0, i.apis = Array.from(new Set(n)), i
      }
      S(t, e);
      var n = t.prototype;
      return n.setup = function () {
        this.exports = this.composeAPIs(this.apis)
      }, n.teardown = function () {
        for (var e, t = l(this.instance.values()); !(e = t()).done;) {
          var n = e.value;
          n._runTeardown()
        }
      }, n.composeAPIs = function (e) {
        for (var t, n = {}, i = l(e); !(t = i()).done;) {
          var r = t.value,
            o = new r(this.container);
          this.instance.set(r, o), ar(o);
          var a = o.constructor.exports;
          if (a)
            for (var s, c = l(a); !(s = c()).done;) {
              var u = s.value.name,
                f = o[u];
              if ("function" == typeof f) {
                var d = Co(f.bind(o), "at tt." + u);
                Xo(u, d), n[u] = d
              } else if (null != f)
                if (f.on) {
                  var p = f,
                    h = p.on,
                    v = p.off,
                    g = u[0].toUpperCase() + u.slice(1),
                    m = "on" + g;
                  if (n[m] = h, Xo(m, h), v) {
                    var _ = "off" + g;
                    n[_] = v, Xo(_, v)
                  }
                } else n[u] = f
            }
        }
        return n
      }, t
    }(or),
    Zo = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "authorize",
          value: function () {
            return this.createInvokeMethodApi("authorize", {
              checkParams: {
                scope: ""
              }
            })
          }
        }]
      }
    }), $o),
    ea = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "getSystemInfo",
          value: function () {
            var e = this;
            return this.createInvokeMethodApi("getSystemInfo", {
              beforeSuccess: function (t) {
                var n, i = e.getJSSDKVersion();
                t.SDKVersion = i.SDKVersion, t.SDKUpdateVersion = i
                  .SDKUpdateVersion, t.platform = null == (n = t.platform) ?
                  void 0 : n.toLocaleLowerCase(), t.devicePixelRatio = t
                  .pixelRatio
              }
            })
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "getSystemInfoSync",
          value: function () {
            var e = this;
            return function () {
              var t = {};
              return e.invokeMethod("getSystemInfoSync", {}, {
                beforeSuccess: function (n) {
                  var i;
                  void 0 === n && (n = {});
                  var r = e.getJSSDKVersion();
                  n.platform = null == (i = n.platform) ? void 0 : i
                    .toLocaleLowerCase(), n.SDKVersion = r.SDKVersion, n
                    .SDKUpdateVersion = r.SDKUpdateVersion, n
                    .devicePixelRatio = n.pixelRatio, t = n
                }
              }), t
            }
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "getKAInfo",
          value: function () {
            return this.createInvokeMethodApi("getKAInfo")
          }
        }, {
          kind: "method",
          key: "getJSSDKVersion",
          value: function () {
            var e, t, n, i;
            return null != (e = null == (t = this.container) || null == (n = t
              .host) || null == (i = n.global) ? void 0 : i.__JSSDK_VERSION__) ? e :
            {}
          }
        }]
      }
    }), $o),
    ta = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "openSchema",
          value: function () {
            return this.createInvokeMethodApi("openSchema")
          }
        }]
      }
    }), $o),
    na = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "getPadDisplayScaleMode",
          value: function () {
            return this.createInvokeMethodApi("getPadDisplayScaleMode")
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "togglePadFullScreen",
          value: function () {
            return this.createInvokeMethodApi("togglePadFullScreen")
          }
        }]
      }
    }), $o),
    ia = n((function (e) {
      e.exports = function (e, t) {
        for (; !Object.prototype.hasOwnProperty.call(e, t) && null !== (e = io(e)););
        return e
      }, e.exports.default = e.exports, e.exports.__esModule = !0
    })),
    ra = t(n((function (e) {
      function t(n, i, r) {
        return "undefined" != typeof Reflect && Reflect.get ? (e.exports = t = Reflect
          .get, e.exports.default = e.exports, e.exports.__esModule = !0) : (e.exports =
          t = function (e, t, n) {
            var i = ia(e, t);
            if (i) {
              var r = Object.getOwnPropertyDescriptor(i, t);
              return r.get ? r.get.call(n) : r.value
            }
          }, e.exports.default = e.exports, e.exports.__esModule = !0), t(n, i, r || n)
      }
      e.exports = t, e.exports.default = e.exports, e.exports.__esModule = !0
    }))),
    oa = "mainNavItemClick",
    aa = "navBarClick",
    sa = "leftNavBarClick",
    ca = "rightNavBarClick",
    ua = Ni(null, (function (e, t) {
      var n = function (t) {
        function n(n) {
          var i;
          i = t.call(this, n) || this, e(Ii(i));
          var r = i.createEventApi(oa);
          i.onMainNavRightItemClick = r.on, i.offMainNavRightItemClick = r.off;
          var o = i.createEventApi(aa, {
            onlyLatestListen: !0
          });
          i.onNavigationBarClick = o.on, i.offNavigationBarClick = o.off;
          var a = i.createEventApi(sa, {
            onlyLatestListen: !0
          });
          i.onLeftNavigationBarClick = a.on, i.offLeftNavigationBarClick = a.off;
          var s = i.createEventApi(ca, {
            onlyLatestListen: !0
          });
          return i.onRightNavigationBarClick = s.on, i.offRightNavigationBarClick = s.off,
            i
        }
        return S(n, t), n
      }(t);
      return {
        F: n,
        d: [{
          kind: "field",
          key: "_titleConfig",
          value: function () {
            return {}
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "setNavigationBarTitle",
          value: function () {
            return this.createInvokeMethodApi("setNavigationBarTitle", {
              checkParams: {
                title: "String"
              }
            })
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "setNavigationBarColor",
          value: function () {
            return this.createInvokeMethodApi("setNavigationBarColor", {
              checkParams: {
                frontColor: "String",
                backgroundColor: "String"
              }
            })
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "setMainNavRightItems",
          value: function () {
            return this.createInvokeMethodApi("setMainNavRightItems")
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "onMainNavRightItemClick",
          value: function () {}
        }, {
          kind: "field",
          decorators: [Go()],
          key: "offMainNavRightItemClick",
          value: function () {}
        }, {
          kind: "field",
          decorators: [Go()],
          key: "showNavigationBarLoading",
          value: function () {
            return this.createInvokeMethodApi("showNavigationBarLoading")
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "hideNavigationBarLoading",
          value: function () {
            return this.createInvokeMethodApi("hideNavigationBarLoading")
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "hideHomeButton",
          value: function () {
            return this.createInvokeMethodApi("hideHomeButton")
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "setNavigationBar",
          value: function () {
            var e = this;
            return this.createInvokeMethodApi("setNavigationBar", {
              paramsTransformer: function (t) {
                return e._titleConfig = t, t
              }
            })
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "onNavigationBarClick",
          value: void 0
        }, {
          kind: "field",
          decorators: [Go()],
          key: "offNavigationBarClick",
          value: void 0
        }, {
          kind: "field",
          decorators: [Go()],
          key: "onLeftNavigationBarClick",
          value: void 0
        }, {
          kind: "field",
          decorators: [Go()],
          key: "offLeftNavigationBarClick",
          value: void 0
        }, {
          kind: "field",
          decorators: [Go()],
          key: "onRightNavigationBarClick",
          value: void 0
        }, {
          kind: "field",
          decorators: [Go()],
          key: "offRightNavigationBarClick",
          value: void 0
        }, {
          kind: "method",
          key: "setup",
          value: function () {
            var e = this;
            ra(ro(n.prototype), "setup", this).call(this), this.onMethod(
              "onMainNavRightItemClick", (function (t) {
                e.emitter.emit(oa, t)
              })), this.onMethod("onNavigationBarClick", (function (t) {
              e.emitter.emit(aa, t)
            })), this.onMethod("onLeftNavigationBarClick", (function (t) {
              e.emitter.emit(sa, t)
            })), this.onMethod("onRightNavigationBarClick", (function (t) {
              e.emitter.emit(ca, t)
            })), window.addEventListener("pageshow", (function (t) {
              e.invokeMethod("pageshow", {
                persisted: t.persisted,
                navigationType: window.performance.navigation.type,
                navigationBarConfig: e._titleConfig
              })
            })), document.addEventListener("visibilitychange", (function (t) {
              e.invokeMethod("visibilitychange", {
                navigationBarConfig: e._titleConfig,
                visibilityState: document.visibilityState
              })
            })), this.onMethod("resolveNavigationBarConfig", (function () {
              e.invokeMethod("resolveNavigationBarConfig", {
                navigationBarConfig: e._titleConfig
              })
            }))
          }
        }]
      }
    }), $o),
    la = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "getUserInfo",
          value: function () {
            return this.createInvokeMethodApi("getUserInfo")
          }
        }]
      }
    }), $o),
    fa = ["success", "loading", "none", "info", "error", "warning"],
    da = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "method",
          decorators: [Go()],
          key: "showToast",
          value: function (e) {
            void 0 === e && (e = {}), (e = Object.assign({
              duration: 1500,
              title: "",
              icon: "success"
            }, e)).icon && -1 !== fa.indexOf(e.icon) ? "none" === e.icon && (e
              .icon = "") : e.icon = "success", this.beforeInvoke("showToast", e, {
              duration: 1,
              title: "String",
              icon: "String"
            }) && this.invokeMethod("showToast", e)
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "hideToast",
          value: function () {
            return this.createInvokeMethodApi("hideToast")
          }
        }]
      }
    }), $o);
  Ni(null, (function (e, t) {
    return {
      F: function (t) {
        function n() {
          for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
            arguments[o];
          return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
        }
        return S(n, t), n
      }(t),
      d: [{
        kind: "method",
        decorators: [Go()],
        key: "showLoading",
        value: function (e) {
          void 0 === e && (e = {});
          var t = {
            icon: "loading",
            duration: 864e5
          };
          e = Object.assign(e, t), this.beforeInvoke("showLoading", e, {
            title: "String"
          }) && (e.title = function (e, t) {
            var n = /[^\u0000-\u00ff]/g;
            if (e.replace(n, "aa").length <= t) return e;
            for (var i = Math.floor(t / 2), r = e.length; i < r; i++)
              if (e.substring(0, i).replace(n, "aa").length >= t) return e
                .substring(0, i);
            return e
          }(e.title, 14), this.invokeMethod("showToast", e, {
            beforeAll: function (e) {
              e.errMsg = e.errMsg.replace("showToast", "showLoading")
            }
          }))
        }
      }, {
        kind: "method",
        decorators: [Go()],
        key: "hideLoading",
        value: function (e) {
          void 0 === e && (e = {}), this.invokeMethod("hideToast", e, {
            beforeAll: function (e) {
              e.errMsg = e.errMsg.replace("hideToast", "hideLoading")
            }
          })
        }
      }]
    }
  }), $o);
  var pa = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "method",
          decorators: [Go()],
          key: "showActionSheet",
          value: function (e) {
            void 0 === e && (e = {});
            this.beforeInvoke("showActionSheet", e, {
              itemList: ["String"]
            }) && (e.itemList.length < 1 && this.beforeInvokeFail("showActionSheet",
                e, "param.itemList should has at least 1 item"), e.itemList.length >
              6 ? this.beforeInvokeFail("showActionSheet", e,
                "param.itemList should has at most 6 items") : this.invokeMethod(
                "showActionSheet", e))
          }
        }]
      }
    }), $o),
    ha = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "method",
          decorators: [Go()],
          key: "showModal",
          value: function (e) {
            void 0 === e && (e = {});
            e = Object.assign({}, {
              title: "",
              content: "",
              confirmText: "",
              cancelText: "",
              showCancel: !0,
              confirmColor: "#3CC51F",
              cancelColor: "#000000"
            }, e), this.beforeInvoke("showModal", e, {
              title: "String",
              content: "String",
              confirmText: "String",
              cancelText: "String",
              confirmColor: "String",
              cancelColor: "String"
            }) && (xo(e.confirmText) > 8 ? this.beforeInvokeFail("showModal", e,
                "confirmText length should not larger than 4 Chinese characters") :
              xo(e.cancelText) > 8 ? this.beforeInvokeFail("showModal", e,
                "cancelText length should not larger than 4 Chinese characters") :
              this.invokeMethod("showModal", e))
          }
        }]
      }
    }), $o);
  var va = function (e) {
      return "string" == typeof e ? e.replace(/[^\u0000-\u00ff]/g, "aa").length : 0
    },
    ga = Ni(null, (function (e, t) {
      var n = function (t) {
        function n() {
          for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
            arguments[o];
          return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
        }
        return S(n, t), n
      }(t);
      return {
        F: n,
        d: [{
          kind: "field",
          key: "original",
          value: function () {
            return new ha(this.container)
          }
        }, {
          kind: "field",
          key: "currentShowCount",
          value: function () {
            return 0
          }
        }, {
          kind: "field",
          key: "continueShowCount",
          value: function () {
            return 0
          }
        }, {
          kind: "field",
          key: "lastCloseTime",
          value: function () {
            return 0
          }
        }, {
          kind: "field",
          key: "isTipShowed",
          value: function () {
            return !1
          }
        }, {
          kind: "method",
          key: "setup",
          value: function () {
            ra(ro(n.prototype), "setup", this).call(this), ar(this.original)
          }
        }, {
          kind: "method",
          decorators: [Go()],
          key: "showModal",
          value: function (e) {
            if (void 0 === e && (e = {}), !this.isTipShowed) {
              if (this.currentShowCount > 0) this.continueShowCount++;
              else Date.now() - this.lastCloseTime < 500 ? this.continueShowCount++ :
                this.reset();
              this.continueShowCount >= 3 ? this.tryShowTipModal(e) : this
                .showNormalModal(e)
            }
          }
        }, {
          kind: "field",
          key: "getShowModalTipInfo",
          value: function () {
            return this.createInvokeMethodApi("getShowModalTipInfo")
          }
        }, {
          kind: "method",
          key: "tryShowTipModal",
          value: function (e) {
            var t = this;
            void 0 === e && (e = {}), this.getShowModalTipInfo({
              success: function (e) {
                t.showTipModal(e)
              },
              fail: function (n) {
                t.showNormalModal(e)
              }
            })
          }
        }, {
          kind: "method",
          key: "showNormalModal",
          value: function (e) {
            var t = this;
            void 0 === e && (e = {});
            var n = (e = Object.assign({}, e)).success,
              i = e.fail;
            e.success = function (e) {
              t.lastCloseTime = Date.now(), t.currentShowCount--, n && n(e)
            }, e.fail = function (e) {
              t.currentShowCount--, i && i(e)
            }, this.currentShowCount++, this.original.showModal(e)
          }
        }, {
          kind: "method",
          key: "showTipModal",
          value: function (e) {
            var t = this;
            this.isTipShowed = !0, this.original.showModal({
              title: e.title,
              cancelText: e.cancelText,
              confirmText: e.confirmText,
              success: function (e) {
                var n, i = e.confirm,
                  r = e.cancel;
                if (t.isTipShowed = !1, i) {
                  t.reset();
                  var o = t.currentShowCount;
                  t.currentShowCount = 0, void 0 === (n = {
                    fail: function () {
                      t.reset(), t.currentShowCount = o
                    }
                  }) && (n = {}), Po("exitMiniProgram", n)
                }
                r && t.reset()
              },
              fail: function (e) {
                t.isTipShowed = !1
              }
            })
          }
        }, {
          kind: "method",
          key: "reset",
          value: function () {
            this.continueShowCount = 0, this.lastCloseTime = 0, this.isTipShowed = !1
          }
        }]
      }
    }), $o),
    ma = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "showPrompt",
          value: function () {
            var e = this;
            return function (t) {
              void 0 === t && (t = {}), va(t.title) > 30 ? e.beforeInvokeFail(
                  "showPrompt", t,
                  "title length should not larger than 15 Chinese characters") : va(
                  t.confirmText) > 8 ? e.beforeInvokeFail("showPrompt", t,
                  "confirmText length should not larger than 4 Chinese characters"
                  ) : va(t.cancelText) > 8 ? e.beforeInvokeFail("showPrompt", t,
                  "cancelText length should not larger than 4 Chinese characters") :
                (t.maxLength = function (e) {
                  return "number" != typeof e || isNaN(e) ? 140 : e >= -1 ? e :
                    140
                }(t.maxLength), e.invokeMethod("showPrompt", t))
            }
          }
        }]
      }
    }), $o),
    _a = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "enterBot",
          value: function () {
            return this.createInvokeMethodApi("enterBot")
          }
        }]
      }
    }), $o),
    ya = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "enterChat",
          value: function () {
            return this.createInvokeMethodApi("enterChat")
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "toggleChat",
          value: function () {
            return this.createInvokeMethodApi("toggleChat", {
              checkParams: {
                openChatId: ""
              }
            })
          }
        }]
      }
    }), _a),
    wa = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "method",
          decorators: [Go()],
          key: "enterChat",
          value: function (e) {
            void 0 === e && (e = {});
            var t = e,
              n = t.openid,
              i = t.openChatId;
            n || i ? (n && i && delete e.openChatId, void 0 === e.needBadge && (e
                .needBadge = !0), this.invokeMethod("enterChat", e)) : this
              .beforeInvokeFail("enterChat", e, "no valid id")
          }
        }]
      }
    }), _a),
    ba = function (e) {
      function t() {
        return e.apply(this, arguments) || this
      }
      return S(t, e), t
    }($o),
    ka = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "enterProfile",
          value: function () {
            return this.createInvokeMethodApi("enterProfile", {
              checkParams: {
                openid: ""
              }
            })
          }
        }]
      }
    }), ba),
    Sa = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "method",
          decorators: [Go()],
          key: "enterProfile",
          value: function (e) {
            void 0 === e && (e = {}), void 0 === e.left && (e.left = 0), void 0 === e
              .top && (e.top = 0);
            this.beforeInvoke("enterProfile", e, {
              openid: "String",
              left: 0,
              top: 0
            }) && this.invokeMethod("enterProfile", e)
          }
        }]
      }
    }), ba),
    Ca = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "startPasswordVerify",
          value: function () {
            return this.createInvokeMethodApi("startPasswordVerify")
          }
        }]
      }
    }), $o),
    Ea = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "method",
          decorators: [Go()],
          key: "chooseContact",
          value: function (e) {
            void 0 === e && (e = {}), void 0 === e.externalContact && (e
                .externalContact = !0), void 0 === e.multi && (e.multi = !0),
              void 0 === e.ignore && (e.ignore = !1), e.multi = Boolean(e.multi), !
              1 === e.multi && (e.choosenIds = null);
            var t = !1;
            null !== e.choosenIds && null !== e.disableChosenIds && e
              .choosenIds instanceof Array && e.disableChosenIds instanceof Array && e
              .choosenIds.forEach((function (n) {
                -1 !== e.disableChosenIds.indexOf(n) && (t = !0)
              })), !1 === t ? this.invokeMethod("chooseContact", e) : this
              .beforeInvokeFail("chooseContact", e,
                "choosenIds and disableIds intersect")
          }
        }, {
          kind: "method",
          decorators: [Go()],
          key: "chooseChat",
          value: function (e) {
            void 0 === e && (e = {}), void 0 === e.externalChat && (e.externalChat = !
                0), void 0 === e.allowCreateGroup && (e.allowCreateGroup = !0),
              void 0 === e.ignoreSelf && (e.ignoreSelf = !1), void 0 === e
              .ignoreBot && (e.ignoreBot = !1), void 0 === e.multiSelect && (e
                .multiSelect = !1), void 0 === e.selectType && (e.selectType = 0),
              this.invokeMethod("chooseChat", e)
          }
        }]
      }
    }), $o),
    za = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "filePicker",
          value: function () {
            return this.createInvokeMethodApi("filePicker")
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "openDocument",
          value: function () {
            return this.createInvokeMethodApi("openDocument", {
              checkParams: {
                filePath: "String"
              }
            })
          }
        }, {
          kind: "method",
          decorators: [Go()],
          key: "docsPicker",
          value: function (e) {
            var t = e.maxNum;
            void 0 === t || t > 0 ? this.invokeMethod("docsPicker", e) : this
              .beforeInvokeFail("docsPicker", e, "no valid maxNum")
          }
        }]
      }
    }), $o),
    Ta = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "saveFileAs",
          value: function () {
            return this.createInvokeMethodApi("saveFileAs", {
              checkParams: {
                filePath: "String"
              }
            })
          }
        }]
      }
    }), $o),
    Ia = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "getHostLaunchQuery",
          value: function () {
            return this.createInvokeMethodApi("getHostLaunchQuery")
          }
        }, {
          kind: "field",
          decorators: [Go({
            sync: !0
          })],
          key: "getHostLaunchQuerySync",
          value: function () {
            return null
          }
        }]
      }
    }), $o),
    Oa = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "getSecurityInfo",
          value: function () {
            return this.createInvokeMethodApi("getSecurityInfo")
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "getSecurityEnv",
          value: function () {
            return this.createInvokeMethodApi("getSecurityEnv")
          }
        }]
      }
    }), $o),
    xa = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "method",
          decorators: [Go()],
          key: "enableLeaveConfirm",
          value: function (e) {
            var t = this;
            void 0 === e && (e = {});
            e = Object.assign({}, {
              effect: ["back", "close"],
              title: "",
              content: "",
              confirmText: "",
              cancelText: "",
              confirmColor: "#3CC51F",
              cancelColor: "#000000"
            }, e);
            var n = function (n, i) {
              return xo(e[n]) > i && (t.beforeInvokeFail("enableLeaveConfirm", e,
                "invalid parameter: " + n + " length can not exceed " + i +
                " characters"), !0)
            };
            this.beforeInvoke("enableLeaveConfirm", e, {
              effect: [],
              title: "String",
              content: "String",
              confirmText: "String",
              cancelText: "String",
              confirmColor: "String",
              cancelColor: "String"
            }) && (0 === e.effect.length ? this.beforeInvokeFail(
                "enableLeaveConfirm", e,
                "invalid parameter: effect length can not be empty") : e.title || e
              .content ? n("title", 16) || n("content", 80) || n("confirmText",
              8) || n("cancelText", 8) || this.invokeMethod("enableLeaveConfirm",
              e) : this.beforeInvokeFail("enableLeaveConfirm", e,
                "invalid parameter: title and content can not be empty at the same time"
                ))
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "disableLeaveConfirm",
          value: function () {
            return this.createInvokeMethodApi("disableLeaveConfirm")
          }
        }, {
          kind: "field",
          decorators: [Go()],
          key: "LeaveConfirmCancel",
          value: function () {
            return this.createNativeEventApi("onLeaveConfirmCancel")
          }
        }]
      }
    }), $o),
    Aa = [],
    La = !1;

  function ja(e) {
    0 != Aa.length || La || (Po("onUserCaptureScreen", {}), La = !0), Aa.push(e)
  }

  function Pa(e) {
    0 == (Aa = Aa.filter((function (t) {
      return t != e
    }))).length && La && (Po("offUserCaptureScreen", {}), La = !1)
  }

  function Da(e) {
    void 0 === e && (e = {}), Po("getClipboardData", e)
  }
  Do("userCaptureScreenObserved", (function (e) {
    void 0 === e && (e = {}), Aa.forEach((function (t) {
      var n = Eo(t, "onUserCaptureScreen");
      "function" == typeof n && n(e)
    }))
  }));
  var Na = {
    data: "String"
  };

  function Ma(e) {
    void 0 === e && (e = {}), Fo("setClipboardData", e, Na) && Po("setClipboardData", e)
  }

  function Ba(e) {
    void 0 === e && (e = {});
    Fo("startDeviceCredential", e, {
      authContent: "String"
    }) && Po("startDeviceCredential", e)
  }

  function Fa(e) {
    void 0 === e && (e = {}), Po("mailto", e)
  }

  function Ra(e) {
    void 0 === e && (e = {}), Po("checkWatermark", e)
  }
  var Va = new Yi,
    Ua = "_onWatermarkChange";

  function Wa(e) {
    var t, n, i;
    if (e)
      if ("function" == typeof e ? t = e : "object" == typeof e && ("function" == typeof e
          .callback && (t = e.callback), "function" == typeof e.success && (n = e.success),
          "function" == typeof e.fail && (i = e.fail)), t)
        if (0 === Va.listenerCount(Ua)) {
          Po("onWatermarkChange", {
            success: function (e) {
              Va.on(Ua, t), n && n(e)
            },
            fail: i
          })
        } else Va.on(Ua, t), n && n();
    else i && i("have no callback")
  }

  function qa(e) {
    var t, n, i;
    if (e)
      if ("function" == typeof e ? t = e : "object" == typeof e && ("function" == typeof e
          .callback && (t = e.callback), "function" == typeof e.success && (n = e.success),
          "function" == typeof e.fail && (i = e.fail)), t) {
        n && n();
        var r = Va.listenerCount(Ua);
        Va.off(Ua, t), 1 === r && 0 === Va.listenerCount(Ua) && Po("offWatermarkChange", {})
      } else i && i("have no callback")
  }

  function Ja(e) {
    void 0 === e && (e = {});
    Fo("setAuthenticationInfo", e, {
      mobile: "String",
      identifyCode: "String",
      identifyName: "String"
    }) && Po("setAuthenticationInfo", e)
  }

  function Ha(e) {
    void 0 === e && (e = {}), Po("startFaceIdentify", e)
  }

  function Ka(e) {
    void 0 === e && (e = {});
    Fo("startFaceVerify", e, {
      userId: "String"
    }) && Po("startFaceVerify", e)
  }
  Do("onWatermarkChange", (function (e) {
    void 0 === e && (e = {}), Va.emit(Ua, e)
  }));
  var Qa = 0,
    Ga = [],
    $a = Ho("getLocation", {
      beforeAll: function () {
        Qa = 0
      },
      afterAll: function (e) {
        Ga.forEach((function (t) {
          "function" == typeof t.complete && Eo(t.complete,
            "at getLocation complete callback function")(e)
        })), Ga = []
      },
      afterSuccess: function (e) {
        Ga.forEach((function (t) {
          "function" == typeof t.success && Eo(t.success,
            "at getLocation success callback function")(e)
        }))
      },
      afterFail: function (e) {
        Ga.forEach((function (t) {
          "function" == typeof t.fail && Eo(t.fail,
            "at getLocation fail callback function")(e)
        }))
      }
    }),
    Xa = function (e) {
      var t;
      Qa ? (Ga.push(e), Date.now() - Qa >= 1e4 && (t = Date.now() - Qa, no(eo.api, $r
        .api_queue_timeout).addMap({
        api_name: "getLocation",
        api_queue_length: Ga.length,
        api_timeout: t
      }).flush())) : (Qa = Date.now(), $a(e))
    },
    Ya = Ho("openLocation", {
      checkParams: {
        latitude: 0,
        longitude: 0
      }
    }),
    Za = Ho("chooseLocation"),
    es = Ho("getLocationStatus"),
    ts = new Yi.EventEmitter2,
    ns = "onLocationChange";
  Do("onLocationChange", (function (e) {
    void 0 === e && (e = {}), ts.emit(ns, e)
  }));
  var is = Ho("startLocationUpdate"),
    rs = Ho("stopLocationUpdate"),
    os = zo(ts, ns, (function (e) {
      return Eo(e)
    })),
    as = os.on,
    ss = os.off,
    cs = ["ascii", "base64", "binary", "hex", "ucs2", "ucs-2", "utf16le", "utf-16le", "utf-8",
      "utf8", "latin1"
    ],
    us = function () {
      function e(e) {
        var t = e.mode,
          n = void 0 === t ? 0 : t,
          i = e.size,
          r = void 0 === i ? 0 : i,
          o = e.lastAccessedTime,
          a = void 0 === o ? "" : o,
          s = e.lastModifiedTime,
          c = void 0 === s ? "" : s;
        this.mode = n, this.size = r, this.lastAccessedTime = a, this.lastModifiedTime = c
      }
      var t = e.prototype;
      return t._checkModeProperty = function (e) {
        return (61440 & this.mode) === e
      }, t.isFile = function () {
        return this._checkModeProperty(32768)
      }, t.isDirectory = function () {
        return this._checkModeProperty(16384)
      }, e
    }(),
    ls = new(function () {
      function e() {}
      var t = e.prototype;
      return t.getFileInfo = function (e) {
        void 0 === e && (e = {}), Fo("getFileInfo", e, {
          filePath: ""
        }) && Po("getFileInfo", e)
      }, t.getSavedFileList = function (e) {
        void 0 === e && (e = {}), Po("getSavedFileList", e)
      }, t.removeSavedFile = function (e) {
        void 0 === e && (e = {}), Fo("removeSavedFile", e, {
          filePath: ""
        }) && Po("removeSavedFile", e)
      }, t.getSavedFileInfo = function (e) {
        void 0 === e && (e = {}), Fo("getSavedFileInfo", e, {
          filePath: ""
        }) && Po("getSavedFileInfo", e)
      }, t.saveFile = function (e) {
        void 0 === e && (e = {}), Fo("saveFile", e, {
          tempFilePath: ""
        }) && Po("saveFile", e)
      }, t.saveFileSync = function (e, t) {
        if (!e || "string" != typeof e) throw new TypeError(
        "tempFilePath must be a string");
        var n, i;
        if (Po("saveFileSync", {
            tempFilePath: e,
            filePath: t,
            success: function (e) {
              i = e.savedFilePath
            },
            fail: function (e) {
              n = e.errMsg
            }
          }), n) throw new Error(n);
        return i
      }, t.readFile = function (e) {
        void 0 === e && (e = {}), Fo("readFile", e, {
          filePath: ""
        }) && Po("readFile", e)
      }, t.readFileSync = function (e, t) {
        if (!e || "string" != typeof e) throw new TypeError("filePath must be a string");
        if (t && "string" != typeof t) throw new TypeError("encoding must be a string");
        var n, i, r = {
          filePath: e
        };
        if (t && (r.encoding = t), Po("readFileSync", Object.assign({}, r, {
            success: function (e) {
              i = e.data
            },
            fail: function (e) {
              n = e.errMsg
            }
          })), n) throw new Error(n);
        return i
      }, t.writeFile = function (e) {
        void 0 === e && (e = {}), Fo("writeFile", e, {
          filePath: ""
        }) && (e.encoding && -1 === cs.indexOf(e.encoding) ? Ro("writeFile", e,
          'invalid encoding "' + e.encoding + '"') : Po("writeFile", e, {}))
      }, t.writeFileSync = function (e, t, n) {
        if (!e || "string" != typeof e) throw new TypeError("filePath must be a string");
        if (n && -1 === cs.indexOf(n)) throw new Error('invalid encoding "' + n + '"');
        var i;
        if (Po("writeFileSync", {
            filePath: e,
            data: t,
            encoding: n,
            fail: function (e) {
              i = e.errMsg
            }
          }), i) throw new Error(i)
      }, t.mkdir = function (e) {
        void 0 === e && (e = {}), Fo("mkdir", e, {
          dirPath: ""
        }) && Po("mkdir", e)
      }, t.mkdirSync = function (e, t) {
        if (void 0 === t && (t = !1), !e || "string" != typeof e) throw new TypeError(
          "dirPath must be a string");
        var n;
        if (Po("mkdirSync", {
            dirPath: e,
            recursive: t,
            fail: function (e) {
              n = e.errMsg
            }
          }), n) throw new Error(n)
      }, t.readdir = function (e) {
        void 0 === e && (e = {}), Fo("readdir", e, {
          dirPath: ""
        }) && Po("readdir", e)
      }, t.readdirSync = function (e) {
        if (!e || "string" != typeof e) throw new TypeError("dirPath must be a string");
        var t, n;
        if (Po("readdirSync", {
            dirPath: e,
            success: function (e) {
              n = e.files
            },
            fail: function (e) {
              t = e.errMsg
            }
          }), t) throw new Error(t);
        return n
      }, t.rmdir = function (e) {
        void 0 === e && (e = {
          recursive: !1
        }), Fo("rmdir", e, {
          dirPath: ""
        }) && Po("rmdir", e)
      }, t.rmdirSync = function (e, t) {
        if (void 0 === t && (t = !1), !e || "string" != typeof e) throw new TypeError(
          "dirPath must be a string");
        var n;
        if (Po("rmdirSync", {
            dirPath: e,
            recursive: t,
            fail: function (e) {
              n = e.errMsg
            }
          }), n) throw new Error(n)
      }, t.access = function (e) {
        void 0 === e && (e = {});
        /^(ttfile|http):\/\/(user|temp)$/.test(e.path) && (e.path += "/"), Fo("access", e, {
          path: ""
        }) && Po("access", e)
      }, t.accessSync = function (e) {
        if (!e || "string" != typeof e) throw new TypeError("path must be a string");
        var t;
        if (/^(ttfile|http):\/\/(user|temp)$/.test(e) && (e += "/"), Po("accessSync", {
            path: e,
            fail: function (e) {
              t = e.errMsg
            }
          }), t) throw new Error(t)
      }, t.unlink = function (e) {
        void 0 === e && (e = {}), Fo("unlink", e, {
          filePath: ""
        }) && Po("unlink", e)
      }, t.unlinkSync = function (e) {
        if (!e || "string" != typeof e) throw new TypeError("filePath must be a string");
        var t;
        if (Po("unlinkSync", {
            filePath: e,
            fail: function (e) {
              t = e.errMsg
            }
          }), t) throw new Error(t)
      }, t.stat = function (e) {
        void 0 === e && (e = {}), Fo("stat", e, {
          path: ""
        }) && Po("stat", e, {
          beforeSuccess: function (e) {
            e.stats = e.stat = new us(e.stat)
          }
        })
      }, t.statSync = function (e) {
        if (!e || "string" != typeof e) throw new TypeError("path must be a string");
        var t, n;
        if (Po("statSync", {
            path: e,
            success: function (e) {
              n = new us(e.stat)
            },
            fail: function (e) {
              t = e.errMsg
            }
          }), t) throw new Error(t);
        return n
      }, t.rename = function (e) {
        void 0 === e && (e = {}), Fo("rename", e, {
          oldPath: "",
          newPath: ""
        }) && Po("rename", e)
      }, t.renameSync = function (e, t) {
        if (!e || "string" != typeof e) throw new TypeError("oldPath must be a string");
        if (!t || "string" != typeof t) throw new TypeError("newPath must be a string");
        var n;
        if (Po("renameSync", {
            oldPath: e,
            newPath: t,
            fail: function (e) {
              n = e.errMsg
            }
          }), n) throw new Error(n)
      }, t.copyFile = function (e) {
        void 0 === e && (e = {}), Fo("copyFile", e, {
          srcPath: "",
          destPath: ""
        }) && Po("copyFile", e)
      }, t.copyFileSync = function (e, t) {
        if (!e || "string" != typeof e) throw new TypeError("srcPath must be a string");
        if (!t || "string" != typeof t) throw new TypeError("destPath must be a string");
        var n;
        if (Po("copyFileSync", {
            srcPath: e,
            destPath: t,
            fail: function (e) {
              n = e.errMsg
            }
          }), n) throw new Error(n)
      }, t.unzip = function (e) {
        void 0 === e && (e = {}), Fo("unzip", e, {
          zipFilePath: "",
          targetPath: ""
        }) && Po("unzip", e, {})
      }, e
    }());

  function fs() {
    return ls
  }
  var ds = ls.saveFile;
  ls.getFileInfo;
  var ps, hs = ls.getSavedFileList,
    vs = ls.removeSavedFile;
  ! function (e) {
    e[e.Cancelled = 1000001] = "Cancelled", e[e.InvalidParam = 104] = "InvalidParam", e[e
      .InternalError = 102] = "InternalError"
  }(ps || (ps = {}));
  var gs = function () {
    function e(t, n) {
      var i = this;
      if (void 0 === n && (n = !1), this.taskId = Oo(), this.bizInvokeTime = (new Date)
        .valueOf(), this.illegal = !1, this.abort = function () {
          i.illegal || Po("downloadFileAbort", {
            payload: JSON.stringify({
              downloadTaskId: i.taskId
            })
          })
        }, this.onProgressUpdate = function (t) {
          i.illegal || "function" != typeof t || e.eventNexus.on(i.taskId, (function (e) {
            Eo(t, "at downloadTask.onProgressUpdate callback function")(e)
          }))
        }, n) this.illegal = !0;
      else {
        var r = Object.assign({}, t, {
          taskId: this.taskId
        });
        Po("downloadFile", {
          payload: JSON.stringify(Object.assign({}, t, {
            downloadTaskId: this.taskId
          })),
          fail: e.callBackWrapper(r, this.bizInvokeTime, "fail"),
          success: e.callBackWrapper(r, this.bizInvokeTime, "success")
        })
      }
    }
    return e.reportResult = function (e) {
      var t = e.errCode === ps.Cancelled || e.errMsg.toLocaleLowerCase().startsWith(
          "downloadFile:fail canceled"),
        n = t ? $r.abort_download : $r.download_result,
        i = t ? "cancel" : e.resultType,
        r = Date.now() - e.bizInvokeTime,
        o = {
          request_version: "v2",
          request_trace: e.taskId,
          request_url: "string" == typeof e.url ? e.url.split("?")[0] : qi(e.url),
          request_biz_invoke_time: e.bizInvokeTime,
          error_msg: e.errMsg || "",
          duration: r,
          result_type: i,
          api_name: "downloadFile"
        };
      no(eo.network, n).setPlatform("TeaSlardar").addMap(o).flush()
    }, e.callBackWrapper = function (t, n, i) {
      return function (r) {
        var o = t.taskId,
          a = t.success,
          s = t.fail,
          c = t.complete,
          u = r.payload,
          l = r.errCode,
          f = null,
          d = r.errMsg;
        if ("string" == typeof u) try {
          f = JSON.parse(u)
        } catch (e) {
          d = "downloadFile:fail sdk parse payload error", Gi.error(
            "[js-sdk-downloadFile] biz callback payload json parse error")
        }
        var p = f ? Object.assign({}, f, {
          trace: o,
          errMsg: d
        }) : {
          errMsg: d,
          trace: o
        };
        if ("success" === i && f && "function" == typeof a) a(p);
        else if ("function" == typeof s) {
          s(void 0 === l ? p : Object.assign({}, p, {
            errCode: l
          }))
        }
        "function" == typeof c && c(p), e.eventNexus.off(t.taskId), e.reportResult({
          resultType: i,
          errMsg: d,
          bizInvokeTime: n,
          errCode: l,
          taskId: t.taskId,
          url: t.url
        })
      }
    }, e
  }();

  function ms(e) {
    void 0 === e && (e = {});
    var t = {
      resultType: "fail",
      errMsg: "downloadFile:fail",
      bizInvokeTime: Date.now(),
      taskId: "",
      url: e.url
    };
    if (!Fo("downloadFile", e, {
        url: ""
      })) return t.errMsg = "downloadFile:fail invalid param.url type", void gs.reportResult(t);
    var n, i, r = "";
    if (n = e.url, void 0 === i && (i = "http"), ("http" === i ? /^(http|https):\/\/.*/i.test(
        n) : "websocket" === i ? /^(ws|wss):\/\/.*/i.test(n) : void 0) || (r = 'invalid url "' +
        e.url + '"'), !r && ("string" != typeof e.method && e.method || 0 === e.method) && (r =
        "param.method should pass string, not " + typeof e.method), r) return t.errMsg =
      "downloadFile:fail " + r, gs.reportResult(t), void Ro("downloadFile", e, r);
    var o = !1,
      a = e.filePath;
    if (null == a || "" === a ? delete e.filePath : "string" != typeof a && (t.errMsg =
        "downloadFile:fail invalid param.filePath type", gs.reportResult(t), Ro("downloadFile",
          e, "filePath could only be string or null/undefined"), o = !0), o) return new gs(e, !
      0);
    var s, c = Object.assign({}, e);
    if ("object" == typeof c.header && null !== c.header || (Gi.warn(
        "downloadFile: header must be an object"), c.header = {}), c.header = (s = c.header,
        Object.keys(s).reduce((function (e, t) {
          return "string" == typeof s[t] ? e[t] = s[t] : "number" == typeof s[t] ||
            "boolean" == typeof s[t] ? e[t] = "" + s[t] : e[t] = Object.prototype.toString
            .apply(s[t]), e
        }), {})), ["success", "fail", "complete"].forEach((function (e) {
        var t = c[e];
        "function" == typeof t && (c[e] = Eo(t, "at api downloadFile " + e +
          " callback function"))
      })), c.method || (c.method = "GET"), "string" == typeof c.method && (c.method = c.method
        .toUpperCase()), "GET" === c.method) c.url = function (e, t) {
      if ("string" == typeof e && "object" == typeof t && null !== t && Object.keys(t)
        .length > 0) {
        var n = function (e) {
            if ("string" == typeof e) {
              var t = e.split("?");
              return {
                urlQueryObj: (t[1] || "").split("&").reduce((function (e, t) {
                  if ("string" == typeof t && t.length > 0) {
                    var n = t.split("=");
                    e[n[0]] = n[1]
                  }
                  return e
                }), {}),
                prefix: t[0]
              }
            }
            return {}
          }(e),
          i = n.urlQueryObj,
          r = void 0 === i ? {} : i,
          o = n.prefix;
        return (void 0 === o ? "" : o) + "?" + bo(ko(r, Object.keys(t).reduce((function (e,
        n) {
          return "object" == typeof t[n] ? e[encodeURIComponent(n)] =
            encodeURIComponent(JSON.stringify(t[n])) : e[encodeURIComponent(n)] =
            encodeURIComponent(t[n]), e
        }), {})))
      }
      return e
    }(c.url, c.data), c.data && delete c.data;
    else {
      var u, l, f = void 0;
      void 0 === c.data || (f = "string" == typeof c.data || c.data instanceof ArrayBuffer ? c
        .data : null != (u = c.header["content-type"]) && u.includes(
          "application/x-www-form-urlencoded") ? bo(c.data, !0) : null != (l = c.header[
          "content-type"]) && l.includes("application/json") || "object" == typeof c.data ?
        JSON.stringify(c.data) : c.data.toString()), c.data = f
    } ["success", "fail", "complete"].forEach((function (e) {
      var t = c[e];
      "function" == typeof t && (c[e] = Eo(t, "at api downloadFile " + e +
        " callback function"))
    }));
    var d = rr.pack(c);
    try {
      return new gs(d)
    } catch (n) {
      var p;
      t.errMsg = "downloadFile:fail create downloadFile instant catch error", gs.reportResult(
        t), Ro("downloadFile", e, null != (p = null == n ? void 0 : n.message) ? p :
          "create downloadFile instant catch error")
    }
  }
  gs.eventNexus = new Qo, gs.progressCacheMap = new Map, Do("onDownloadFileProgress", (
    function (e) {
      var t;
      void 0 === e && (e = {});
      var n = e,
        i = n.progress,
        r = n.downloadTaskId,
        o = n.totalBytesExpectedToWrite,
        a = n.totalBytesWritten;
      r && !isNaN(i) && (Number(i) < (null != (t = gs.progressCacheMap.get(r)) ? t : 0) || (
        gs.progressCacheMap.set(r, Number(i)), gs.eventNexus.emit(r, {
          progress: i,
          totalBytesWritten: a,
          totalBytesExpectedToWrite: o
        })))
    }));
  var _s = "waiting",
    ys = "sending",
    ws = "done",
    bs = "aborted",
    ks = "created",
    Ss = "in_queue",
    Cs = 0,
    Es = 0;

  function zs() {
    var e = Ls.get(this);
    As.set(this, ys), Bs.set(this, Date.now());
    var t, n = this,
      i = (new Date).valueOf() % 1e6;
    Es === i ? Cs++ : (Es = i, Cs = 0);
    var r = +("" + i + (Cs < 10 ? "0" + Cs : Cs));
    Cs >= 99 && (Cs = 0), xs.set(n, r || i), Po("createDownloadTask", {
      url: e.url,
      header: e.header,
      data: e.data,
      method: e.method,
      filePath: e.filePath,
      taskId: r,
      success: function (e) {
        Ps.set(n, e.trace), xs.set(n, e.downloadTaskId), Rs += 1, Us[e.downloadTaskId] = n
      },
      fail: function (e) {
        Ps.set(n, e.trace), t = e.errMsg, qs(n, "fail", t)
      },
      complete: function () {
        As.set(n, ws)
      }
    }), t ? setTimeout((function () {
      var n = {
        errMsg: t.replace("createDownloadTask", "downloadFile")
      };
      "function" == typeof e.fail && e.fail(n), "function" == typeof e.complete && e
        .complete(n)
    }), 0) : (Gi.log("taskID", this, xs.get(this)), Ws.on(xs.get(this) + "success", (
      function (t) {
        t.errMsg = "downloadFile:ok", t.statusCode = parseInt(t.statusCode, 10), -1 === [
            200, 304
          ].indexOf(t.statusCode) && delete t.tempPath, delete t.timeInterval,
          "function" == typeof e.success && e.success(t), "function" == typeof e
          .complete && e.complete(t)
      })), Ws.on(xs.get(this) + "fail", (function (t) {
      t.errMsg = "downloadFile:fail " + t.errMsg, "function" == typeof e.fail && e.fail(
        t), "function" == typeof e.complete && e.complete(t)
    })))
  }
  var Ts = 0,
    Is = new WeakMap,
    Os = new WeakMap,
    xs = new WeakMap,
    As = new WeakMap,
    Ls = new WeakMap,
    js = new WeakMap,
    Ps = new WeakMap,
    Ds = new WeakMap,
    Ns = new WeakMap,
    Ms = new WeakMap,
    Bs = new WeakMap,
    Fs = new WeakMap,
    Rs = 0,
    Vs = [],
    Us = {},
    Ws = new Yi.EventEmitter2;

  function qs(e, t, n) {
    var i = Fs.get(e),
      r = As.get(e) === bs,
      o = r ? $r.abort_download : $r.download_result,
      a = r ? "cancel" : t,
      s = Ls.get(e),
      c = js.get(e),
      u = Date.now() - c,
      l = {
        request_version: "v1",
        request_trace: Ps.get(e) || "",
        request_url: s.url.split("?")[0],
        request_method: s.method || "GET",
        request_biz_invoke_time: js.get(e),
        request_push_queue_time: Ds.get(e) || 0,
        request_shift_queue_time: Ms.get(e) || 0,
        request_queue_length: Ns.get(e) || 0,
        request_abort_type: i || "",
        error_msg: n || "",
        duration: u,
        result_type: a,
        api_name: "downloadFile"
      };
    no(eo.network, o).setPlatform("TeaSlardar").addMap(l).flush()
  }
  Do("onDownloadTaskStateChange", (function (e) {
    void 0 === e && (e = {}), "string" == typeof e && (e = JSON.parse(e));
    var t = e.state,
      n = e.downloadTaskId;
    if (delete e.state, delete e.downloadTaskId, Ws.emit("" + n + t, e), "success" ===
      t || "fail" === t) {
      if (Rs = Math.max(Rs - 1, 0), Vs.length > 0) {
        var i = Vs.shift();
        Ms.set(i.item, Date.now()), zs.call(i.item)
      }
      var r = Us[n];
      if (r) {
        try {
          qs(r, t, e.errMsg)
        } catch (e) {}
        delete Us[n], Ws.removeAllListeners(n + "success"), Ws.removeAllListeners(n +
          "fail"), Ws.removeAllListeners(n + "progressUpdate")
      }
    }
  }));
  var Js, Hs = function (e) {
    var t = this;
    this.abort = function () {
      var e = As.get(t),
        n = Ls.get(t);
      if (As.set(t, bs), e === _s) {
        var i = Vs.findIndex((function (e) {
          return e.id === Is.get(t)
        }));
        i > -1 && Vs.splice(i, 1), Fs.set(t, Ss), setTimeout((function () {
          var e = "downloadFile:fail abort";
          "function" == typeof n.fail && n.fail({
            errMsg: e
          }), qs(t, "cancel", e), "function" == typeof n.complete && n.complete({
            errMsg: e
          })
        }), 0)
      } else e !== bs && (Fs.set(t, ks), Po("operateDownloadTask", {
        downloadTaskId: xs.get(t),
        operationType: "abort"
      }))
    }, this.onProgressUpdate = function (e) {
      Ws.on(xs.get(t) + "progressUpdate", (function (t) {
        "function" == typeof e && Eo(e,
          "at DownloadTask.onProgressUpdate callback function")(t)
      }))
    }, js.set(this, Date.now());
    var n, i, r, o = Ts++;
    Os.set(this, o), Is.set(this, o), As.set(this, _s), ["success", "fail", "complete"]
      .forEach((function (t) {
        var n = e[t];
        "function" == typeof n && (e[t] = Eo(n, "at api downloadFile " + t +
          " callback function"))
      })), Ls.set(this, e), Rs >= 5 ? (Ds.set(this, Date.now()), Ns.set(this, Vs.length), Vs
        .push({
          id: o,
          item: this
        }), n = this, i = Ls.get(n), r = {
          request_trace: Ps.get(n) || "",
          request_url: i.url.split("?")[0],
          request_method: i.method || "GET",
          request_queue_length: 5 + Vs.length,
          request_type: "downloadFile"
        }, no(eo.network, $r.request_waiting).setPlatform("TeaSlardar").addMap(r).flush()) :
      zs.call(this)
  };

  function Ks(e, t, n) {
    var i = $r.request_result,
      r = {
        request_url: "string" == typeof e ? e.split("?")[0] : qi(e),
        request_method: "string" == typeof t ? t : qi(t),
        error_msg: n,
        result_type: "fail",
        request_version: "v1",
        api_name: "downloadFile"
      };
    no(eo.network, i).setPlatform("TeaSlardar").addMap(r).flush()
  }

  function Qs(e) {
    if (void 0 === e && (e = {}), ((null == void 0 ? void 0 : undefined.agent) || {})
      .useNewDownloadAPI) return ms(e);
    if (Fo("downloadFile", e, {
        url: ""
      })) try {
      return new Hs(e)
    } catch (t) {
      Ks(e.url, e.method, "downloadFile:fail create downloadFile instant catch error "), Ro(
        "downloadFile", e, t.message)
    } else Ks(e.url, e.method, "downloadFile:fail invalid param.url type")
  }

  function Gs(e) {
    void 0 === e && (e = {}), Po("getBlockActionSourceDetail", e)
  }
  var $s = {
      NDEF: "NDEF",
      A: "NFC-A",
      B: "NFC-B",
      F: "NFC-F",
      V: "NFC-V",
      MIFARE_CLASSIC: "MIFARE-Classic",
      MIFARE_ULTRALIGHT: "MIFARE-Ultralight",
      ISO_DEP: "ISO-DEP"
    },
    Xs = new Yi,
    Ys = new WeakMap,
    Zs = "discovered";
  Do("nfcFoundDevice", (function (e) {
    Xs.emit(Zs, e)
  }));
  var ec = function () {
      function e(e) {
        this.tech = e
      }
      var t = e.prototype;
      return t.connect = function (e) {
        Po("nfcConnect", Object.assign({
          tech: this.tech
        }, e))
      }, t.close = function (e) {
        Po("nfcClose", Object.assign({
          tech: this.tech
        }, e))
      }, t.setTimeout = function (e) {
        Po("nfcSetTimeout", Object.assign({
          tech: this.tech
        }, e))
      }, t.transceive = function (e) {
        Po("nfcTransceive", Object.assign({
          tech: this.tech
        }, e))
      }, t.getMaxTransceiveLength = function (e) {
        Po("nfcMaxTransceiveLength", Object.assign({
          tech: this.tech
        }, e))
      }, e
    }(),
    tc = function (e) {
      function t() {
        return e.call(this, $s.A) || this
      }
      S(t, e);
      var n = t.prototype;
      return n.getAtqa = function (e) {
        Po("nfcGetAtqa", e)
      }, n.getSak = function (e) {
        Po("nfcGetSak", e)
      }, t
    }(ec),
    nc = function (e) {
      function t() {
        return e.call(this, $s.MIFARE_CLASSIC) || this
      }
      return S(t, e), t
    }(ec),
    ic = ((Js = {})[$s.A] = tc, Js[$s.MIFARE_CLASSIC] = nc, Js),
    rc = {};

  function oc(e) {
    return function () {
      return function (e) {
        return rc[e] || (rc[e] = new ic[e]), rc[e]
      }(e)
    }
  }
  var ac = {
    tech: $s,
    getNfcA: oc($s.A),
    getMifareClassic: oc($s.MIFARE_CLASSIC),
    onDiscovered: function (e) {
      var t = Ys.get(e) || Eo(e, "NFCAdapter.Discovered");
      Ys.set(e, t), Xs.on(Zs, t)
    },
    offDiscovered: function (e) {
      var t = Ys.get(e);
      t && Xs.off(Zs, t)
    },
    startDiscovery: function (e) {
      Po("nfcStartDiscovery", e)
    },
    stopDiscovery: function (e) {
      Po("nfcStopDiscovery", e)
    }
  };

  function sc() {
    return ac
  }
  var cc = !1,
    uc = [];

  function lc(e) {
    void 0 === e && (e = {}), cc = !0, Po("enableAccelerometer", Object.assign({}, e, {
      enable: cc
    }), {
      beforeAll: function (e) {
        e.errMsg = e.errMsg.replace("enableAccelerometer", "startAccelerometer")
      }
    })
  }

  function fc(e) {
    void 0 === e && (e = {}), cc = !1, Po("enableAccelerometer", Object.assign({}, e, {
      enable: cc
    }), Object.assign({}, e, {
      beforeAll: function (e) {
        e.errMsg = e.errMsg.replace("enableAccelerometer", "stopAccelerometer")
      }
    }))
  }

  function dc(e) {
    "function" == typeof e && (cc || Po("enableAccelerometer", {
      enable: cc = !0
    }), uc.push(Eo(e, "onAccelerometerChange")))
  }
  Do("onAccelerometerChange", (function (e) {
    void 0 === e && (e = {});
    for (var t = 0; t < uc.length; t++) {
      var n = uc[t];
      _o(n) && n(e)
    }
  }));
  var pc = [],
    hc = !1;

  function vc(e) {
    hc || (Po("startCompass", {}), hc = !0), "function" == typeof e && pc.push(Eo(e,
      "onCompassChange"))
  }

  function gc(e) {
    void 0 === e && (e = {}), Po("startCompass", e), hc = !0
  }

  function mc(e) {
    void 0 === e && (e = {}), Po("stopCompass", e), hc = !1
  }

  function _c(e) {
    Fo("makePhoneCall", e, {
      phoneNumber: ""
    }) && Po("makePhoneCall", e)
  }
  Do("onCompassChange", (function (e) {
    void 0 === e && (e = {}), pc.forEach((function (t) {
      "function" == typeof t && t(e)
    }))
  }));
  var yc = Ho("vibrateShort"),
    wc = Ho("vibrateLong");

  function bc(e) {
    void 0 === e && (e = {}), Po("getWifiStatus", e)
  }
  var kc = new Qo({
    wrapper: function (e) {
      return go(e, {
        context: null,
        errMsg: "at tt.onNetworkStatusChange"
      })
    }
  });
  Do("onNetworkStatusChange", (function (e) {
    kc.emit("networkStatusChange", e)
  }));
  var Sc = Ho("getNetworkType"),
    Cc = new Qo;
  Do("onGetWifiList", (function (e) {
    Cc.emit("getWifiList", e)
  }));
  var Ec = Ho("getConnectedWifi"),
    zc = Ho("getWifiList");

  function Tc(e) {
    "function" == typeof e && (Cc.on("getWifiList", e), 1 === Cc.size("getWifiList") && Po(
      "onGetWifiList", {}))
  }

  function Ic(e) {
    if ("function" == typeof e) {
      var t = Cc.size("getWifiList");
      Cc.off("getWifiList", e), 1 === t && 0 === Cc.size("getWifiList") && Po(
      "offGetWifiList", {})
    }
  }
  var Oc = new Qo,
    xc = Ho("getNetworkQualityType"),
    Ac = Ho("onNetworkQualityChange"),
    Lc = Ho("offNetworkQualityChange");

  function jc(e) {
    var t = Oc.size("networkQualityChange");
    Oc.on("networkQualityChange", e);
    var n = Oc.size("networkQualityChange");
    0 === t && 1 === n && Ac()
  }

  function Pc(e) {
    var t = Oc.size("networkQualityChange");
    Oc.off("networkQualityChange", e);
    var n = Oc.size("networkQualityChange");
    1 === t && 0 === n && Lc()
  }
  Do("onNetworkQualityChange", (function (e) {
    Oc.emit("networkQualityChange", e)
  }));
  var Dc = new Yi;

  function Nc(e) {
    Fo("onChatBadgeChange", e, {
      openChatId: "",
      onChange: function () {}
    }) && (Dc.listeners(e.openChatId) && Dc.listeners(e.openChatId).includes(e.onChange) ? Vo(
      "onChatBadgeChange") : 0 === Dc.listeners(e.openChatId).length ? Po(
      "onChatBadgeChange", e, {
        beforeSuccess: function (t) {
          Dc.on(e.openChatId, e.onChange)
        }
      }) : Dc.on(e.openChatId, e.onChange))
  }

  function Mc(e) {
    if (Fo("offChatBadgeChange", e, {
        openChatId: ""
      })) {
      var t = e.openChatId,
        n = e.onChange;
      n ? (Dc.off(t, n), Dc.listeners(t).length ? Vo("offChatBadgeChange") : Po(
        "offChatBadgeChange", e, (function (e) {}))) : Dc.removeAllListeners(t) && Po(
        "offChatBadgeChange", e, (function (e) {}))
    }
  }

  function Bc(e) {
    if (Fo("getChatInfo", e, {
        openChatId: "",
        chatType: 0
      })) {
      var t = e.chatType;
      [0, 1].includes(t) ? 0 !== t || [0, 1].includes(e.userType) ? Po("getChatInfo", e) : Ro(
        "getChatInfo", e, "must set userType when chatType is 0") : Ro("getChatInfo", e,
        "chatType can only be 0 or 1")
    }
  }

  function Fc(e) {
    void 0 === e && (e = {}), Po("getTriggerContext", e)
  }

  function Rc(e) {
    void 0 === e && (e = {}), Po("sendMessageCard", e)
  }

  function Vc(e) {
    void 0 === e && (e = {}), Po("chooseImage", ko({
      count: 9,
      sizeType: ["original", "compressed"],
      sourceType: ["album", "camera"]
    }, e), {
      beforeSuccess: function (e) {
        e.tempFileSizes && (e.tempFiles = e.tempFilePaths.map((function (t, n) {
          return {
            path: t,
            size: e.tempFileSizes[n]
          }
        }))), delete e.tempFileSizes
      }
    })
  }

  function Uc(e) {
    void 0 === e && (e = {}), Fo("previewImage", e, {}) && (e.urls && (e.urls = e.urls.map((
      function (e) {
        return Hc.get(e) || e
      }))), Po("previewImage", e))
  }

  function Wc(e) {
    if (Fo("getImageInfo", e, {
        src: ""
      }))
      if (/^(http|https):\/\//.test(e.src)) Qs({
        url: e.src,
        success: function (t) {
          e.src = t.tempFilePath, Po("getImageInfo", e, {
            beforeSuccess: function (t) {
              t.path = e.src
            }
          })
        },
        fail: function (t) {
          Ro("getImageInfo", e, "download image fail")
        }
      });
      else if (/^(tt)?file:\/\//.test(e.src)) Po("getImageInfo", e, {
      beforeSuccess: function (t) {
        t.path = e.src
      }
    });
    else {
      e.src = Ji("", e.src), Po("getImageInfo", e, {
        beforeSuccess: function (t) {
          t.path = e.src
        }
      })
    }
  }

  function qc(e) {
    void 0 === e && (e = {}), e.filePath = Hc.get(e.filePath) || e.filePath, Po(
      "saveImageToPhotosAlbum", e, {})
  }

  function Jc(e) {
    if (void 0 === e && (e = {}), Fo("compressImage", e, {
        src: ""
      }))
      if (/^(tt)?file:\/\//.test(e.src) || /^(http|https):\/\//.test(e.src)) Po("compressImage",
        e);
      else {
        e.src = Ji("", e.src), Po("compressImage", e)
      }
  }
  Do("chatBadgeChangeObserved", (function (e) {
    void 0 === e && (e = {}), e.openChatId && Dc.emit(e.openChatId, {
      badge: e.badge
    })
  }));
  var Hc = {
    map: {},
    set: function (e) {
      var t = "xxxxxxxx".replace(/x/g, (function () {
        return (16 * Math.random() | 0).toString(16)
      }));
      return this.map[t] = e, t
    },
    get: function (e) {
      return this.map[e]
    }
  };

  function Kc(e) {
    void 0 === e && (e = {}), e.sourceType = e.sourceType || ["album", "camera"], e.camera = e
      .camera || ["front", "back"], "boolean" != typeof e.compressed && (e.compressed = !0), Po(
        "chooseVideo", e)
  }

  function Qc(e) {
    void 0 === e && (e = {}), Po("saveVideoToPhotosAlbum", e, {})
  }
  var Gc = Ho("getSetting"),
    $c = Ho("openSetting");

  function Xc(e) {
    Po("getExtConfig", e, {})
  }
  var Yc, Zc = function () {
    function e() {}
    var t = e.prototype;
    return t._reportUndefinedDefaultCode = function () {
      var e = {
        domain: "js.open_platform.gadget",
        code: 1e4,
        level: M.warn,
        message: "undefined_default_code"
      };
      return new de({
        name: "undefined_default_code",
        code: e
      })
    }, t._buildParams = function (e, t) {
      var n;
      return void 0 === t && (t = {}), (n = {}).biz_event_name = e, n.biz_data = t, n
        .common_data = {}, n
    }, t.report = function (e, t, n, i) {
      void 0 === n && (n = {}), e || this._reportUndefinedDefaultCode();
      var r = new de({
        name: "op_biz_event",
        code: new fe(t)
      });
      i && i.traceId && r.tracing(i.traceId);
      var o = this._buildParams(e, n);
      return r.addMap(o).flush()
    }, e
  }();

  function eu() {
    return Yc || (Yc = new Zc), Yc
  }

  function tu(e) {
    var t = e.value,
      n = e.success,
      i = e.fail;
    return new Promise((function (e, r) {
      Po("setScreenBrightness", {
        value: t
      }, {
        afterSuccess: function (t) {
          e(n ? n(t) : t)
        },
        afterCancel: function (e) {
          r(i ? i(e) : e)
        },
        afterFail: function (e) {
          r(i ? i(e) : e)
        }
      })
    }))
  }

  function nu(e) {
    var t = e.success,
      n = e.fail;
    return new Promise((function (e, i) {
      Po("getScreenBrightness", {}, {
        afterSuccess: function (n) {
          e(t ? t(n) : n)
        },
        afterCancel: function (e) {
          i(n ? n(e) : e)
        },
        afterFail: function (e) {
          i(n ? n(e) : e)
        }
      })
    }))
  }

  function iu(e) {
    void 0 === e && (e = {}), void 0 === e.keepScreenOn && (e.keepScreenOn = !0), Fo(
      "setKeepScreenOn", e, {
        keepScreenOn: !0
      }) && Po("setKeepScreenOn", e)
  }
  var ru = new Yi,
    ou = "bluetoothAdapterStateChange",
    au = "bluetoothDeviceFound";

  function su(e) {
    void 0 === e && (e = {}), Po("openBluetoothAdapter", e)
  }

  function cu(e) {
    void 0 === e && (e = {}), Po("closeBluetoothAdapter", e)
  }

  function uu(e) {
    void 0 === e && (e = {}), Po("getBluetoothAdapterState", e)
  }

  function lu(e) {
    void 0 === e && (e = {});
    var t = yo(e, {
      services: {
        type: "Array",
        required: !1
      },
      allowDuplicatesKey: {
        type: "Boolean",
        required: !1
      },
      interval: {
        type: "Number",
        required: !1
      }
    });
    t ? Ro("startBluetoothDevicesDiscovery", e, t) : Po("startBluetoothDevicesDiscovery", e)
  }

  function fu(e) {
    void 0 === e && (e = {}), Po("stopBluetoothDevicesDiscovery", e)
  }
  Do(ou, (function (e) {
    ru.emit(ou, e)
  })), Do(au, (function (e) {
    ru.emit(au, e)
  }));
  var du = zo(ru, ou, (function (e) {
      return Eo(e)
    })),
    pu = du.on,
    hu = du.off;

  function vu(e) {
    return (e.devices || []).forEach((function (e) {
      var t = e.advertisData;
      t ? e.advertisData = Wi(t) : delete e.advertisData;
      var n = e.serviceData,
        i = void 0 === n ? {} : n;
      for (var r in i)
        if (i.hasOwnProperty(r)) {
          var o = i[r];
          o ? i[r] = Wi(o) : delete i[r]
        }
    })), e
  }
  var gu = new WeakMap,
    mu = zo(ru, au, (function (e) {
      var t = Eo(e);
      return function (e) {
        var n = gu.get(e) || vu(e);
        gu.set(e, n), t(n)
      }
    })),
    _u = mu.on,
    yu = mu.off;

  function wu(e) {
    void 0 === e && (e = {});
    var t = yo(e, {
      services: {
        type: "Array",
        required: !0
      }
    });
    t ? Ro("getConnectedBluetoothDevices", e, t) : Po("getConnectedBluetoothDevices", e)
  }

  function bu(e) {
    void 0 === e && (e = {});
    var t = e.success;
    t && (e.success = function (e) {
      var n = vu(e);
      t(n)
    }), Po("getBluetoothDevices", e)
  }
  var ku = new Yi,
    Su = "BLECharacteristicValueChange",
    Cu = "BLEConnectionStateChange";

  function Eu(e) {
    void 0 === e && (e = {});
    var t = yo(e, {
      deviceId: {
        type: "String",
        required: !0
      }
    });
    t ? Ro("connectBLEDevice", e, t) : Po("connectBLEDevice", e)
  }

  function zu(e) {
    void 0 === e && (e = {});
    var t = yo(e, {
      deviceId: {
        type: "String",
        required: !0
      }
    });
    t ? Ro("disconnectBLEDevice", e, t) : Po("disconnectBLEDevice", e)
  }

  function Tu(e) {
    void 0 === e && (e = {});
    var t = yo(e, {
      deviceId: {
        type: "String",
        required: !0
      },
      serviceId: {
        type: "String",
        required: !0
      }
    });
    t ? Ro("getBLEDeviceCharacteristics", e, t) : Po("getBLEDeviceCharacteristics", e)
  }

  function Iu(e) {
    void 0 === e && (e = {});
    var t = yo(e, {
      deviceId: {
        type: "String",
        required: !0
      }
    });
    t ? Ro("getBLEDeviceServices", e, t) : Po("getBLEDeviceServices", e)
  }

  function Ou(e) {
    void 0 === e && (e = {});
    var t = yo(e, {
      deviceId: {
        type: "String",
        required: !0
      },
      serviceId: {
        type: "String",
        required: !0
      },
      characteristicId: {
        type: "String",
        required: !0
      },
      descriptorId: {
        type: "String",
        required: !1
      },
      state: {
        type: "Boolean",
        required: !1
      }
    });
    t ? Ro("notifyBLECharacteristicValueChange", e, t) : Po(
      "notifyBLECharacteristicValueChange", e)
  }
  Do(Su, (function (e) {
    ku.emit(Su, e)
  })), Do(Cu, (function (e) {
    ku.emit(Cu, e)
  }));
  var xu = zo(ku, Su, (function (e) {
      return Eo(e)
    })),
    Au = xu.on,
    Lu = xu.off,
    ju = zo(ku, Cu, (function (e) {
      return Eo(e)
    })),
    Pu = ju.on,
    Du = ju.off;

  function Nu(e) {
    void 0 === e && (e = {});
    var t = yo(e, {
      deviceId: {
        type: "String",
        required: !0
      },
      serviceId: {
        type: "String",
        required: !0
      },
      characteristicId: {
        type: "String",
        required: !0
      }
    });
    t ? Ro("readBLECharacteristicValue", e, t) : Po("readBLECharacteristicValue", e)
  }

  function Mu(e) {
    void 0 === e && (e = {});
    var t = yo(e, {
      deviceId: {
        type: "String",
        required: !0
      },
      serviceId: {
        type: "String",
        required: !0
      },
      characteristicId: {
        type: "String",
        required: !0
      },
      value: {
        type: "String",
        required: !0
      }
    });
    t ? Ro("writeBLECharacteristicValue", e, t) : Po("writeBLECharacteristicValue", e)
  }

  function Bu(e) {
    if ("ios" !== wo()) {
      var t = yo(e, {
        deviceId: {
          type: "String",
          required: !0
        },
        mtu: {
          type: "Number",
          required: !0
        }
      });
      t ? Ro("setBLEMTU", e, t) : Po("setBLEMTU", e)
    } else Ro("setBLEMTU", e, "iOS does not support")
  }

  function Fu(e) {
    void 0 === e && (e = {}), Po("getDeviceID", e)
  }

  function Ru(e) {
    void 0 === e && (e = {}), Po("getEnvVariable", e)
  }

  function Vu(e) {
    void 0 === e && (e = {}), Po("getUserInfoEx", e)
  }

  function Uu(e) {
    void 0 === e && (e = {}), Fo("scanCode", e, {}) && Po("scanCode", e, {
      beforeSuccess: function (e) {
        "string" == typeof e.path && (e.path = e.path.replace(/\.html$/, ""), e.path = e
          .path.replace(/\.html\?/, "?"))
      }
    })
  }
  var Wu = Ho("getTenantAppScopes"),
    qu = Ho("applyTenantAppScope"),
    Ju = function (e) {
      Po("checkLocalFaceVerify", e)
    },
    Hu = function (e) {
      var t = yo(e, {
        timeout: {
          type: "Number",
          required: !0
        }
      });
      t ? Ro("prepareLocalFaceVerify", e, t) : Po("prepareLocalFaceVerify", e)
    },
    Ku = function (e) {
      var t = yo(e, {
        path: {
          type: "String",
          required: !0
        }
      });
      t ? Ro("startLocalFaceVerify", e, t) : Po("startLocalFaceVerify", e)
    };

  function Qu(e) {
    Po("share", e)
  }
  var Gu = new Yi,
    $u = {
      8e3: [16e3, 48e3],
      11025: [16e3, 48e3],
      12e3: [24e3, 64e3],
      16e3: [24e3, 96e3],
      22050: [32e3, 128e3],
      24e3: [32e3, 128e3],
      32e3: [48e3, 192e3],
      44100: [64e3, 32e4],
      48e3: [64e3, 32e4]
    },
    Xu = Object.keys($u).map((function (e) {
      return parseInt(e)
    }));

  function Yu(e) {
    Po("operateRecorder", Object.assign({}, e))
  }
  Do("onRecorderStateChange", (function (e) {
    void 0 === e && (e = {});
    var t = e.state;
    delete e.state, Gu.emit("onRecorderStateChange_" + t, e)
  }));
  var Zu = {
    start: function (e) {
      void 0 === e && (e = {});
      var t = Object.assign({
        format: "aac",
        sampleRate: 8e3,
        encodeBitRate: 48e3,
        numberOfChannels: 2,
        duration: 6e4
      }, e, {
        operationType: "start",
        fail: function (e) {
          Gu.emit("onRecorderStateChange_error", e)
        }
      });
      if (-1 === Xu.indexOf(t.sampleRate)) throw new Error('invalid sampleRate "' + t
        .sampleRate + '", sampleRate should be one of ' + JSON.stringify(Object.keys(
          $u)));
      if (t.encodeBitRate > $u[t.sampleRate][1] || t.encodeBitRate < $u[t.sampleRate][0])
        throw new Error('invalid encodeBitRate "' + t.encodeBitRate +
          '", encodeBitRate should be greater than ' + $u[t.sampleRate][0] +
          " and less than " + $u[t.sampleRate][1]);
      (t.duration > 6e5 || t.duration < 0) && (t.duration = 6e4), Yu(t)
    },
    pause: function () {
      Yu({
        operationType: "pause",
        fail: function (e) {
          Gu.emit("onRecorderStateChange_error", e)
        }
      })
    },
    resume: function () {
      Yu({
        operationType: "resume",
        fail: function (e) {
          Gu.emit("onRecorderStateChange_error", e)
        }
      })
    },
    stop: function () {
      Yu({
        operationType: "stop",
        fail: function (e) {
          Gu.emit("onRecorderStateChange_error", e)
        }
      })
    },
    onStart: function (e) {
      Gu.removeAllListeners("onRecorderStateChange_start"), Gu.on(
        "onRecorderStateChange_start", (function (t) {
          "function" == typeof e && Eo(e,
            "at recorderManager.onStart callback function")(t)
        }))
    },
    onResume: function (e) {
      Gu.removeAllListeners("onRecorderStateChange_resume"), Gu.on(
        "onRecorderStateChange_resume", (function (t) {
          "function" == typeof e && Eo(e,
            "at recorderManager.onResume callback function")(t)
        }))
    },
    onPause: function (e) {
      Gu.removeAllListeners("onRecorderStateChange_pause"), Gu.on(
        "onRecorderStateChange_pause", (function (t) {
          "function" == typeof e && Eo(e,
            "at recorderManager.onPause callback function")(t)
        }))
    },
    onStop: function (e) {
      Gu.removeAllListeners("onRecorderStateChange_stop"), Gu.on(
        "onRecorderStateChange_stop", (function (t) {
          "function" == typeof e && Eo(e, "at recorderManager.onStop callback function")
            (t)
        }))
    },
    onFrameRecorded: function (e) {
      Gu.removeAllListeners("onRecorderStateChange_frameRecorded"), Gu.on(
        "onRecorderStateChange_frameRecorded", (function (t) {
          if ("function" == typeof e) {
            var n = t.frameBuffer;
            "string" == typeof n && (t.frameBuffer = Wi(n)), Eo(e,
              "at recorderManager.onFrameRecorded callback function")(t)
          }
        }))
    },
    onError: function (e) {
      Gu.removeAllListeners("onRecorderStateChange_error"), Gu.on(
        "onRecorderStateChange_error", (function (t) {
          "function" == typeof e && Eo(e,
            "at recorderManager.onError callback function")(t)
        }))
    }
  };

  function el() {
    return Zu
  }
  var tl = new Yi.EventEmitter2,
    nl = Symbol("beacon_service_change"),
    il = Symbol("beacon_update");
  Do("beaconServiceChange", (function () {
    for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++) t[n] = arguments[
      n];
    tl.emit.apply(tl, [nl].concat(t))
  })), Do("beaconUpdate", (function () {
    for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++) t[n] = arguments[
      n];
    tl.emit.apply(tl, [il].concat(t))
  }));
  var rl = Ho("getBeacons"),
    ol = Ho("startBeaconDiscovery"),
    al = Ho("stopBeaconDiscovery");

  function sl(e) {
    tl.on(il, e)
  }

  function cl(e) {
    var t;
    if (null != (t = tl.listeners(il)) && t.length) return e ? tl.off(il, e) : void tl
      .listeners(il).forEach((function (e) {
        tl.off(il, e)
      }))
  }

  function ul(e) {
    tl.on(nl, e)
  }

  function ll(e) {
    var t;
    if (null != (t = tl.listeners(nl)) && t.length) return e ? tl.off(nl, e) : void tl
      .listeners(nl).forEach((function (e) {
        tl.off(nl, e)
      }))
  }
  var fl = Ho("getStepCount"),
    dl = Ho("chooseMedia"),
    pl = Ho("reportBadge"),
    hl = Ho("updateBadge"),
    vl = new Qo;
  Do("serverBadgePushObserved", (function (e) {
    var t = "serverBadgePush-" + ((null == e ? void 0 : e.appId) || "");
    vl.emit(t, e)
  }));
  var gl = Ho("requestAuthCode"),
    ml = [ea, ta, Zo, na, ua, la, da, pa, ma, ga, wa, ka, Ca, Ea, za, Ia, Oa, xa],
    _l = Object.freeze({
      __proto__: null,
      apis: ml,
      onUserCaptureScreen: ja,
      offUserCaptureScreen: Pa,
      getClipboardData: Da,
      setClipboardData: Ma,
      startDeviceCredential: Ba,
      mailto: Fa,
      checkWatermark: Ra,
      onWatermarkChange: Wa,
      offWatermarkChange: qa,
      setAuthenticationInfo: Ja,
      startFaceIdentify: Ha,
      startFaceVerify: Ka,
      getLocation: Xa,
      openLocation: Ya,
      chooseLocation: Za,
      getLocationStatus: es,
      startLocationUpdate: is,
      stopLocationUpdate: rs,
      onLocationChange: as,
      offLocationChange: ss,
      saveFile: ds,
      removeSavedFile: vs,
      getSavedFileList: hs,
      downloadFile: Qs,
      getBlockActionSourceDetail: Gs,
      getNFCAdapter: sc,
      startAccelerometer: lc,
      stopAccelerometer: fc,
      onAccelerometerChange: dc,
      startCompass: gc,
      stopCompass: mc,
      onCompassChange: vc,
      makePhoneCall: _c,
      vibrateLong: wc,
      vibrateShort: yc,
      getWifiStatus: bc,
      getNetworkType: Sc,
      getConnectedWifi: Ec,
      getWifiList: zc,
      onGetWifiList: Tc,
      offGetWifiList: Ic,
      getNetworkQualityType: xc,
      onNetworkQualityChange: jc,
      offNetworkQualityChange: Pc,
      getChatInfo: Bc,
      onChatBadgeChange: Nc,
      offChatBadgeChange: Mc,
      getTriggerContext: Fc,
      sendMessageCard: Rc,
      chooseImage: Vc,
      compressImage: Jc,
      previewImage: Uc,
      saveImageToPhotosAlbum: qc,
      getImageInfo: Wc,
      chooseVideo: Kc,
      saveVideoToPhotosAlbum: Qc,
      getSetting: Gc,
      openSetting: $c,
      getExtConfig: Xc,
      setKeepScreenOn: iu,
      getDeviceID: Fu,
      getEnvVariable: Ru,
      getUserInfoEx: Vu,
      scanCode: Uu,
      getTenantAppScopes: Wu,
      applyTenantAppScope: qu,
      checkLocalFaceVerify: Ju,
      prepareLocalFaceVerify: Hu,
      startLocalFaceVerify: Ku,
      share: Qu,
      getRecorderManager: el,
      startBeaconDiscovery: ol,
      stopBeaconDiscovery: al,
      onBeaconServiceChange: ul,
      onBeaconUpdate: sl,
      offBeaconServiceChange: ll,
      offBeaconUpdate: cl,
      getBeacons: rl,
      getStepCount: fl,
      chooseMedia: dl,
      reportBadge: pl,
      updateBadge: hl,
      requestAuthCode: gl,
      getFileSystemManager: fs,
      opmonitor: eu,
      getScreenBrightness: nu,
      setScreenBrightness: tu,
      openBluetoothAdapter: su,
      closeBluetoothAdapter: cu,
      getBluetoothAdapterState: uu,
      startBluetoothDevicesDiscovery: lu,
      stopBluetoothDevicesDiscovery: fu,
      onBluetoothAdapterStateChange: pu,
      offBluetoothAdapterStateChange: hu,
      onBluetoothDeviceFound: _u,
      offBluetoothDeviceFound: yu,
      getConnectedBluetoothDevices: wu,
      getBluetoothDevices: bu,
      connectBLEDevice: Eu,
      disconnectBLEDevice: zu,
      getBLEDeviceCharacteristics: Tu,
      getBLEDeviceServices: Iu,
      notifyBLECharacteristicValueChange: Ou,
      onBLECharacteristicValueChange: Au,
      offBLECharacteristicValueChange: Lu,
      onBLEConnectionStateChange: Pu,
      offBLEConnectionStateChange: Du,
      readBLECharacteristicValue: Nu,
      writeBLECharacteristicValue: Mu,
      setBLEMTU: Bu
    }),
    yl = Ni(null, (function (e, t) {
      return {
        F: function (t) {
          function n() {
            for (var n, i = arguments.length, r = new Array(i), o = 0; o < i; o++) r[o] =
              arguments[o];
            return n = t.call.apply(t, [this].concat(r)) || this, e(Ii(n)), n
          }
          return S(n, t), n
        }(t),
        d: [{
          kind: "field",
          decorators: [Go()],
          key: "print",
          value: function () {
            return this.createInvokeMethodApi("print", {
              checkParams: {
                url: ""
              }
            })
          }
        }]
      }
    }), $o),
    wl = [].concat(ml, [ya, Sa, yl, Ta]),
    bl = Object.freeze({
      __proto__: null,
      apis: wl,
      onUserCaptureScreen: ja,
      offUserCaptureScreen: Pa,
      getClipboardData: Da,
      setClipboardData: Ma,
      startDeviceCredential: Ba,
      mailto: Fa,
      checkWatermark: Ra,
      onWatermarkChange: Wa,
      offWatermarkChange: qa,
      setAuthenticationInfo: Ja,
      startFaceIdentify: Ha,
      startFaceVerify: Ka,
      getLocation: Xa,
      openLocation: Ya,
      chooseLocation: Za,
      getLocationStatus: es,
      startLocationUpdate: is,
      stopLocationUpdate: rs,
      onLocationChange: as,
      offLocationChange: ss,
      saveFile: ds,
      removeSavedFile: vs,
      getSavedFileList: hs,
      downloadFile: Qs,
      getBlockActionSourceDetail: Gs,
      getNFCAdapter: sc,
      startAccelerometer: lc,
      stopAccelerometer: fc,
      onAccelerometerChange: dc,
      startCompass: gc,
      stopCompass: mc,
      onCompassChange: vc,
      makePhoneCall: _c,
      vibrateLong: wc,
      vibrateShort: yc,
      getWifiStatus: bc,
      getNetworkType: Sc,
      getConnectedWifi: Ec,
      getWifiList: zc,
      onGetWifiList: Tc,
      offGetWifiList: Ic,
      getNetworkQualityType: xc,
      onNetworkQualityChange: jc,
      offNetworkQualityChange: Pc,
      getChatInfo: Bc,
      onChatBadgeChange: Nc,
      offChatBadgeChange: Mc,
      getTriggerContext: Fc,
      sendMessageCard: Rc,
      chooseImage: Vc,
      compressImage: Jc,
      previewImage: Uc,
      saveImageToPhotosAlbum: qc,
      getImageInfo: Wc,
      chooseVideo: Kc,
      saveVideoToPhotosAlbum: Qc,
      getSetting: Gc,
      openSetting: $c,
      getExtConfig: Xc,
      setKeepScreenOn: iu,
      getDeviceID: Fu,
      getEnvVariable: Ru,
      getUserInfoEx: Vu,
      scanCode: Uu,
      getTenantAppScopes: Wu,
      applyTenantAppScope: qu,
      checkLocalFaceVerify: Ju,
      prepareLocalFaceVerify: Hu,
      startLocalFaceVerify: Ku,
      share: Qu,
      getRecorderManager: el,
      startBeaconDiscovery: ol,
      stopBeaconDiscovery: al,
      onBeaconServiceChange: ul,
      onBeaconUpdate: sl,
      offBeaconServiceChange: ll,
      offBeaconUpdate: cl,
      getBeacons: rl,
      getStepCount: fl,
      chooseMedia: dl,
      reportBadge: pl,
      updateBadge: hl,
      requestAuthCode: gl,
      getFileSystemManager: fs,
      opmonitor: eu,
      getScreenBrightness: nu,
      setScreenBrightness: tu,
      openBluetoothAdapter: su,
      closeBluetoothAdapter: cu,
      getBluetoothAdapterState: uu,
      startBluetoothDevicesDiscovery: lu,
      stopBluetoothDevicesDiscovery: fu,
      onBluetoothAdapterStateChange: pu,
      offBluetoothAdapterStateChange: hu,
      onBluetoothDeviceFound: _u,
      offBluetoothDeviceFound: yu,
      getConnectedBluetoothDevices: wu,
      getBluetoothDevices: bu,
      connectBLEDevice: Eu,
      disconnectBLEDevice: zu,
      getBLEDeviceCharacteristics: Tu,
      getBLEDeviceServices: Iu,
      notifyBLECharacteristicValueChange: Ou,
      onBLECharacteristicValueChange: Au,
      offBLECharacteristicValueChange: Lu,
      onBLEConnectionStateChange: Pu,
      offBLEConnectionStateChange: Du,
      readBLECharacteristicValue: Nu,
      writeBLECharacteristicValue: Mu,
      setBLEMTU: Bu
    }),
    kl = /(Android|iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) ? _l : bl,
    Sl = {};
  for (var Cl in kl)
    if ("apis" === Cl) {
      var El = new Yo({
        bridge: Wo
      }, kl[Cl]);
      ar(El), Object.assign(Sl, El.exports)
    } else Sl[Cl] = kl[Cl];
  ! function () {
    var e = [],
      t = null,
      n = function () {
        si.invoke("monitorReport", {
          monitorEvents: e
        }), e = [], clearTimeout(t), t = null
      },
      i = new se({
        report: function (i) {
          var r = i.name,
            o = i.metrics,
            a = void 0 === o ? {} : o,
            s = i.categories,
            c = void 0 === s ? {} : s,
            u = c.monitor_level;
          e.push({
            name: r,
            metrics: a,
            categories: c
          }), e.length >= 20 || u >= M.error ? n() : t || (t = setTimeout((function () {
            n()
          }), 4e3))
        },
        log: function (e) {}
      });
    ce.setup(i)
  }();
  var zl = function (e) {
    var t, n, i, r;
    Rn("h5jssdk_enter", {
      url: null == (t = window) || null == (n = t.location) ? void 0 : n.href,
      host: null == (i = window) || null == (r = i.location) ? void 0 : r.host,
      eventType: e,
      client: b(p),
      larkVersion: p.versions.larkVersion,
      platform: navigator.platform,
      browser: navigator.userAgent,
      time: +new Date,
      app_id: w()
    })
  };
  zl(Ln.START_LOAD_SDK), window.dispatchEvent(new CustomEvent("WebViewJSBridgeReady"));
  var Tl = {
    biz: di,
    device: mi,
    appCenter: yi
  };
  Ti(Tl, zi);
  var Il, Ol, xl = null,
    Al = [];
  if (window.ttJSBridge = null, p.versions.mobileFeishu || p.versions.PCFeishu) {
    var Ll = {
      setConfig: function (e) {
        Object.assign(Dn, e)
      },
      config: function (e) {
        var t = {
          appId: e.appId,
          timestamp: e.timestamp,
          nonceStr: e.nonceStr,
          signature: e.signature,
          jsApiList: e.jsApiList
        };
        return function (e) {
          y = e
        }(e.appId), xl = new Promise(function () {
          var n = a(s.mark((function n(i, r) {
            return s.wrap((function (n) {
              for (;;) switch (n.prev = n.next) {
                case 0:
                  "string" == typeof p.versions.larkVersion && m(p
                    .versions.larkVersion, "3.9.0") >= 0 ? ai.invoke(
                    "config", t, {
                      onSuccess: function (t) {
                        i(t), "function" == typeof e.onSuccess && e
                          .onSuccess(t)
                      },
                      onFail: function (t) {
                        r(t), "function" == typeof e.onFail && Al
                          .push(e.onFail), Al.forEach((function (
                          e) {
                            "function" == typeof e && e(t)
                          })), Rn(
                          "h5jssdk_authentication_failure", {
                            error: JSON.stringify(t)
                          }), ye(ge, ve[ue.authenticationFailure])
                          .setError(_(t)).flush()
                      }
                    }) : i();
                case 1:
                case "end":
                  return n.stop()
              }
            }), n)
          })));
          return function (e, t) {
            return n.apply(this, arguments)
          }
        }())
      },
      ready: (Ol = a(s.mark((function e(t) {
        return s.wrap((function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return Fn(), e.prev = 1, e.next = 4, Ci({
                api: Tl,
                browser: p,
                build: "cdn",
                SDK: Ll
              });
            case 4:
              e.next = 9;
              break;
            case 6:
              e.prev = 6, e.t0 = e.catch(1), g(null == e.t0 ? void 0 : e.t0
                .message, !0);
            case 9:
              if (!xl) {
                e.next = 19;
                break
              }
              return e.prev = 10, e.next = 13, xl;
            case 13:
              e.next = 17;
              break;
            case 15:
              e.prev = 15, e.t1 = e.catch(10);
            case 17:
              e.next = 20;
              break;
            case 19:
              g("please invoke h5sdk.config before invoke h5sdk.ready锛宱therwise the interface call may fail",
                !0);
            case 20:
              zl(Ln.SDK_READY), t();
            case 22:
            case "end":
              return e.stop()
          }
        }), e, null, [
          [1, 6],
          [10, 15]
        ])
      }))), function (e) {
        return Ol.apply(this, arguments)
      }),
      error: (Il = a(s.mark((function e(t) {
        return s.wrap((function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              Al.push(t);
            case 1:
            case "end":
              return e.stop()
          }
        }), e)
      }))), function (e) {
        return Il.apply(this, arguments)
      })
    };
    Ci({
        api: Tl,
        browser: p,
        build: "cdn",
        SDK: Ll
      }), window.lark ? Object.assign(window.lark, Ll) : window.lark = Ll, window.h5sdk ? Object
      .assign(window.h5sdk, Ll) : window.h5sdk = Ll, window.tt ? Object.assign(window.tt, Sl) :
      window.tt = Sl, window.ttJSBridge = si
  }
}));